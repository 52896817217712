import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import CustomTable from '../../Component/Table/CustomTable'
import PageTitle from '../../Component/Common/Title/PageTitle'
import { HitApi } from '../../Store/Action/CommonApi/ApiAction'
import { CompileRoadData } from './CompileRoadDataPromis'
import { GetRoads } from '../../Constant/Api/Constant'
import { setRoads, setSigleRoad } from '../../Store/Action/Roads/RoadAction'
import { setPagination } from '../../Store/Action/Pagination/Pagination'
import { TH } from './TH'
import { GetDistrictConditionData } from '../../Utils/Utils'

export default function Roads() {
    const dispatch = useDispatch()
    const history = useHistory()
    const reduxRoads = useSelector(state => state.RoadReducer)
    const reduxUser = useSelector(state => state.AuthReducer)
    const reduxPagination = useSelector(state => state.PaginationReducer)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!reduxRoads?.doc) {
            loadRoads('init')
        }
        
    }, [reduxRoads])

    const loadRoads = (type) => {
        var json = reduxRoads?.json
        if (type === 'init') {
            var query = GetDistrictConditionData(reduxUser?.doc, 'districtName','Package_no')
            Object.assign(json?.search, query, {
                StateName: reduxUser?.doc?.stateName,
                // Package_no : 'JK053012'
                // Package_no : 'JK143007'
                // "devices.1": { $exists: true }
            })
        }
        Object.assign(json, { page: reduxPagination?.pagination?.page, limit: reduxPagination?.pagination?.limit })
        setLoading(true)

        HitApi(json, GetRoads).then((res) => {
            if (res?.doc?.docs) {
                CompileRoadData(res?.doc, reduxPagination?.pagination).then((CompledData) => {
                    if (CompledData) {
                        var tp = reduxPagination?.pagination
                        tp.totalRecords = CompledData?.totalDocs
                        tp.totalPages = CompledData?.totalPages
                        dispatch(setPagination(tp))
                        dispatch(setRoads(CompledData))
                        setLoading(false)
                    }
                })
            }
        })
    }

    const handleClick = (element) => {
        window.location.href = '/roads/details/' + element?._id
    }

    return (
        <div>
            <div><PageTitle text={'List of Roads'} /></div>
            <div className={'mt-5'}>
                <CustomTable
                    header={true}
                    loading={loading}
                    TH={TH}
                    data={reduxRoads?.doc?.docs}
                    pagination={reduxPagination?.pagination}
                    paginationType={'server'}
                    apiHit={loadRoads}
                    handleClick={handleClick}
                    json={reduxRoads?.json}
                    screen={'road'}
                    fileName={('roads')}
                    dateFilter={true}
                    defaultDropdown={true}
                />
            </div>
        </div>
    )
}
