import { SET_COMPLETE_ANALYTICS, SET_SELECTED_DEVICE, SET_AFTER_CALCULATION_DOC, SET_COMPLETE_JSON_FOR_ANALYTICS, SET_DAILY_ANALYTICS, SET_DAILY_JSON_FOR_ANALYTICS, SET_TRACK_DATA, SET_VIEW, SET_WORK, SET_DATA_FOR_DELETE } from "../../Action/Analytics/AnalyticsAction";

const initialState = {
    complete: null,
    completeJson: { page: 1, limit: 10, search: {}, "lite": true, "select": "", "populate": [{ "path": "imeiData", "select": "" }, { "path": "mapData", "select": "" }, { "path": "roadId", "select": "" }, { "path": "roadId", 'populate': "ommas devices mapData", "select": "" }], },
    daily: null,
    dailyJson: { page: 1, limit: 10, search: {}, "lite": true, "select": "", "populate": [ { "path": "roadId", 'populate': {"path":"ommas devices"}}, {"path":"imeiData"}], },
    workJson: { page: 1, limit: 1, search: {}, "lite": true, "select": "", "populate": [{ "path": "imeiData", "select": "" }, { "path": "roadId", 'populate': {"path":"ommas devices"}}, { "path": "mapData", "select": "" },], },
    TrackData: null,
    workSet: null,
    view: 'map',
    doc: null,
    dataToBeDelete: [],
    selectedDevice: null,
    timestamp: Date.now()
}

const AnalyticsReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_COMPLETE_ANALYTICS:
            return ({ ...state, complete: action.value, timestamp: Date.now() })
        case SET_COMPLETE_JSON_FOR_ANALYTICS:
            return ({ ...state, completeJson: action.value, timestamp: Date.now() })
        case SET_DAILY_ANALYTICS:
            return ({ ...state, daily: action.value, timestamp: Date.now() })
        case SET_DAILY_JSON_FOR_ANALYTICS:
            return ({ ...state, dailyJson: action.value, timestamp: Date.now() })
        case SET_TRACK_DATA:
            return ({ ...state, TrackData: action.value, timestamp: Date.now() })
        case SET_VIEW:
            return ({ ...state, view: action.value, timestamp: Date.now() })
        case SET_WORK:
            return ({ ...state, workSet: action.value, timestamp: Date.now() })
        case SET_AFTER_CALCULATION_DOC:
            return ({ ...state, doc: action.value, timestamp: Date.now() })
        case SET_DATA_FOR_DELETE:
            return ({ ...state, dataToBeDelete: action.value, timestamp: Date.now() })
        case SET_SELECTED_DEVICE:
            return ({ ...state, selectedDevice: action.value, timestamp: Date.now() })

        default:
            return state;
    }
}

export default AnalyticsReducer;