import { GetFullYear, GetPageCount } from "../../../Utils/Utils";

export const CompileUserData = (data, pagination) => {
    const MyPromiss = new Promise((resolve, reject) => {

        var tp = data
        tp?.docs?.map((ele, index) => Object.assign(ele, {
            index: GetPageCount(pagination?.limit, pagination?.page, index),
            created_at: GetFullYear(ele?.created_at),
            Action: <label className="cursor-pointer hover:text-success">
                <button className="btn bg-primary/10 text-primary hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25 ">Edit</button>
            </label>
        }))

        if (tp?.pages) {
            Object.assign(tp, {
                totalPages: tp?.pages,
                totalDocs: tp?.total
            })
        }

        resolve(tp)
    })
    return MyPromiss;
}