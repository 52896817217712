import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from '../../Select/Select'
import { CompileDefaultData, CompileDropdownData } from '../Promiss/CustomHeaderPromiss'
import { setFilter } from '../../../Store/Action/Filter/FilterAction'
import { HitApi } from '../../../Store/Action/CommonApi/ApiAction'
import { GetRoads } from '../../../Constant/Api/Constant'
import { ReCompileDataForMap } from '../../../WebView/MapView/MapViewPromiss'
import { setMapData, setMarker } from '../../../Store/Action/Map/MapAction'
import SearchableSelect from '../../Select/SearchableSelect'
import Download from '../Download/Download'
import { setCommonDistrictData, setCommonPackageData } from '../../../Store/Action/CommonApi/DefaultHeaderDropdownDataAction.js'

export default function LocalFilter({ defaultDropdown, TH, json, apiHit, screen, data, fileName, dateFilter, districtKey, packageKey }) {
    const dispatch = useDispatch()
    const reduxMap = useSelector(state => state.MapReducer)
    const reduxUser = useSelector(state => state.AuthReducer)
    const reduxFilter = useSelector(state => state.FilterReducer)
    const reduxPagination = useSelector(state => state.PaginationReducer)
    const reduxCommonData = useSelector(state => state.DefaultHeaderDropdownDataReducer)

    const [districtData, setDistrictData] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {

        if (reduxUser?.doc) {
            loadDefault()
        }

    }, [])

    const loadDefault = () => {
        CompileDefaultData(reduxUser?.doc).then((res) => {
            if (res) {
                dispatch(setCommonDistrictData(res))
            }
        })
    }

    const handleChange = (e, serverKey, searchBy, load) => {
        var value = e?.value || ''
        if (value !== 'All' && value !== '') {
            Object.assign(json.search, { [serverKey]: value })
        } else {
            delete json.search[serverKey]
        }

        ReCompileDataForMap(json, reduxMap?.doc?.MainData, reduxPagination?.pagination).then((DistrictFilterData) => {

            if (DistrictFilterData) {
                var t_reduxMapDoc = reduxMap?.doc
                Object.assign(t_reduxMapDoc.MainData, { paginate: DistrictFilterData?.paginate })

                dispatch(setMapData(t_reduxMapDoc))
                // var t_reduxMapDoc = reduxMap?.marker
                // Object.assign(t_reduxMapDoc, { data: DistrictFilterData })
                // dispatch(setMarker(t_reduxMapDoc))
                load && handleDataLoad(value, 'districtName', searchBy)
            }
        })
    }

    const handleDataLoad = (value, serverKey, searchBy) => {
        var json = {
            page: 1,
            limit: 5000,
            search: {
                [serverKey]: value
            },
            select: 'Package_no'
        }
        setLoading(true)
        HitApi(json, GetRoads).then((res) => {
            if (res?.doc?.docs) {
                CompileDropdownData(res?.doc?.docs, searchBy).then((result) => {
                    if (result) {
                        var json = reduxFilter?.doc
                        Object.assign(json, { [searchBy]: result })
                        dispatch(setFilter(json))
                        dispatch(setCommonPackageData(result))
                    }
                    setLoading(false)
                })
            }
        })
    }

    const status =[
        {label:'OFF', value:'OFF'},
        {label:'IDLE', value:'IDLE'},
        {label:'STOP', value:'STOP'},
        {label:'MOTION', value:'MOTION'},
    ]




    return (
        <div>
            <div className='p-5'>
                <div className='grid grid-cols-4'>
                    <div className='col-span-3'>
                        <div className='grid grid-cols-5 gap-4'>
                            {/* <div><Select title={'District Name'} defaultText={'Select District'} option={districtData} onChange={(e) => handleChange(e, districtKey ? districtKey : 'accname', 'Package_no')} /></div>
                            <div><Select loading={loading} show={true} title={'Package No'} defaultText={loading ? 'Loading Packages...' : 'Select Package No'} option={reduxFilter?.doc?.Package_no} serverKey={'districtName'} json={json} onChange={(e) => handleChange(e, packageKey ? packageKey : 'vehnum', 'Road_Code')} /></div> */}
                            <div><SearchableSelect title={'District'} defaultText={'Select District...'} data={reduxCommonData?.districtData} onChange={(e) => handleChange(e, districtKey ? districtKey : 'accname', 'Package_no', true)} /></div>
                            <div><SearchableSelect title={'Package No.'} defaultText={'Select Package...'} data={reduxCommonData?.packageData} onChange={(e) => handleChange(e, packageKey ? packageKey : 'vehnum', 'Road_Code')} /></div>
                            <div><SearchableSelect title={'Status'} defaultText={'Select Status...'} data={status} onChange={(e) => handleChange(e,'status',null)} /></div>
                        </div>
                    </div>
                    <div className='col-span-1'>
                        <Download TH={TH} data={reduxMap?.doc?.MainData?.data} screen={screen} fileName={fileName} />
                    </div>
                </div>

            </div>

        </div>
    )
}
