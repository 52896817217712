import { Marker, MarkerClusterer } from '@react-google-maps/api'
import React from 'react'
import { useSelector } from 'react-redux'
import CustomMarker from './CustomMarker'

// import { MarkerWithLabel } from "react-google-maps/lib/components/addons/MarkerWithLabel"

export default function MarkerCluster() {
    const reduxMap = useSelector(state => state.MapReducer)


    var markers = reduxMap?.marker

    return (
        <div>
            <MarkerClusterer>
                {clusterer =>
                    markers?.map((location, i) => (
                        <Marker key={i} position={location} clusterer={clusterer}
                            
                        />
                    ))
                }
            </MarkerClusterer>
            {/* {markers?.map((location, i) => (
                <Marker key={i} position={location} />
            ))} */}
        </div>
    )
}
