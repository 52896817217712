import React from 'react'
import CustomTable from '../Table/CustomTable'
import ChildTableTH from '../../WebView/Analytics/Complete/Details_old/ChildTableTH'
import { useSelector } from 'react-redux'
import TH from './TH'

export default function DeleteModal({ show, setShow, data }) {
  const reduxData = useSelector(state => state.AnalyticsReducer)
  const handleFinalDelete = () =>{
    var r = window.confirm('Are you sure to delete these Reports? This action cannot be reversed')
    if(r){
      alert('Selected Reports Deleted Succesfully')
    }
  }
  
  return (
    <div id="modal6" className={`${show} modal modal-scale fixed inset-0 z-[100] flex flex-col items-center justify-center overflow-hidden px-4 py-6 sm:px-5`} role="dialog">
      <div className="modal-overlay absolute inset-0 bg-slate-900/60"></div>
      <div className="modal-content relative flex min-w-full min-h-max max-w-2xl origin-bottom flex-col overflow-hidden rounded-lg bg-white pb-4 dark:bg-navy-700">
        <div className="flex justify-between rounded-t-lg bg-red-700 px-4 py-3 dark:bg-navy-800 sm:px-5">
          <h3 className="text-base font-medium text-white dark:text-navy-100">Review Selected Reports (If deleted, this action cannot be reversed)</h3>
        </div>
        <div className="scrollbar-sm min-w-full overflow-auto">
          <CustomTable
            TH={TH}
            data={reduxData?.dataToBeDelete}
          />
        </div>
        <div className="text-right">
          <button onClick={()=>handleFinalDelete()} className="mx-2 btn border border-error font-medium text-error hover:bg-error hover:text-white focus:bg-error focus:text-white active:bg-error/90">
            Confirm Delete
          </button>
          <button onClick={() => setShow(null)} className="mx-2 btn border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90">
            Cancel
          </button>
        </div>
      </div>
    </div>
  )
}
