import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { HitApi } from '../../../../Store/Action/CommonApi/ApiAction'
import { ZoneRemove, getUsers, getZone } from '../../../../Constant/Api/Constant'
import { CompileDpiuData, CompilePiuData, CompileZoneData } from '../ZonePromiss'
import { setPagination } from '../../../../Store/Action/Pagination/Pagination'
import { addZoneDpuiuPiuJson, dpiu, piu, zone } from '../../../../Store/Action/SuperAdmin/Zones/ZoneAction'
import CustomTable from '../../../../Component/Table/CustomTable'
import { TH as ZoneTH } from '../Zone/TH'
import { TH } from './TH'
import Piu from '../Piu/Piu'
import CustomModal from '../../../../Component/Modal/CustomModal'
import AddDpiu from './AddDpiu'
import CustomTh from '../CustomTH'
import LoadingTableData from '../../../../Component/Common/Loading/LoadingTableData'
import { CompileUserData } from '../../Users/UserPromiss'
import { setAddUserJson, setUserData } from '../../../../Store/Action/SuperAdmin/Users/UserAction'
import ItemCount from '../../../../Component/Common/Counts/ItemCount'
import RemoveButton from '../../../../Component/Common/ActionButton/RemoveButton'
import AddButton from '../../../../Component/Common/ActionButton/AddButton'
import AddPiu from '../Piu/AddPiu'
import { CompilePIUData } from '../Piu/PiuPromiss'
import { AddUserIcon, AddButtonIcon } from '../../../../Constant/Icon/Icon'
import AddUser from '../../Users/Add/AddUser'
import { Imz_Login } from '../../../../Constant/Common/IMZLogin'

export default function Dpiu() {
    const dispatch = useDispatch()
    const reduxZone = useSelector(state => state.ZoneReducer)
    const reduxUser = useSelector(state => state.UserReducer)
    const reduxAuth = useSelector(state => state.AuthReducer)
    const reduxPagination = useSelector(state => state.PaginationReducer)
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState('hide')
    const [DpiuModal, setDpiuModal] = useState('hide')
    const [PiuModal, setPiuModal] = useState('hide')

    useEffect(() => {
        var _id = window.location.href.split('/').pop();
        if (reduxZone?.zone === null) {

            loadZoneData('init', _id)
        }
        if (reduxZone?.zone !== null && reduxZone?.dpiu === null) {


            loadDpiuData('init', _id)
        }




    }, [reduxZone])

    const loadZoneData = (type, _id) => {
        var json = reduxZone?.json
        if (type === 'init') {
            Object.assign(json, { page: 1, limit: 1 })
            Object.assign(json.search, { userType: 'zone', _id: _id })
        }
        setLoading(true)



        HitApi(json, getZone).then((res) => {
            if (res?.doc?.docs) {
                CompileZoneData(res?.doc, reduxPagination?.pagination, null).then((CompileData) => {
                    if (CompileData) {
                        dispatch(zone(CompileData))
                        setLoading(false)
                    }
                })
            }
        })
    }

    const loadDpiuData = (type, _id) => {
        var json = reduxZone?.json
        if (type === 'init') {
            delete json.search._id
            Object.assign(json, { page: 1, limit: reduxPagination?.pagination?.limit })
            Object.assign(json.search, { userType: 'dpiu', parent: _id })
        }
        setLoading(true)

        HitApi(json, getZone).then((res) => {

            if (res?.doc?.docs) {
                CompileDpiuData(res?.doc, reduxZone?.zone?.docs?.[0], reduxPagination?.pagination, 'PIU').then((CompileData) => {
                    if (CompileData) {
                        var tp = reduxPagination?.pagination
                        tp.totalRecords = CompileData?.totalDocs
                        tp.totalPages = CompileData?.totalPages
                        dispatch(setPagination(tp))
                        dispatch(dpiu(CompileData))
                        setLoading(false)
                    }
                })
            }
        })
    }

    const handlePIUList = (ele, type) => {
        var json = reduxZone?.json
        Object.assign(json, { page: reduxPagination?.pagination?.page, limit: 1000 })
        Object.assign(json.search, { userType: 'piu', parent: ele?._id })
        Object.assign(json, { populate: [{ "path": "packages", "select": "districtName Package_no StateName _id" }] })
        HitApi(json, getZone).then((res) => {

            if (res?.doc?.docs) {
                CompilePiuData(res?.doc, reduxZone?.zone?.docs?.[0], ele, reduxPagination?.pagination, 'Users').then((CompileData) => {
                    if (CompileData) {
                        var tp = reduxPagination?.pagination
                        tp.totalRecords = CompileData?.totalDocs
                        tp.totalPages = CompileData?.totalPages
                        dispatch(setPagination(tp))
                        dispatch(piu(CompileData))
                        setLoading(false)
                    }
                })
            }
        })
    }

    const handleCreateDpiu = () => {
        var zoneData = reduxZone?.zone?.docs?.[0]
        var json = reduxZone?.addJson
        setDpiuModal('show')
        Object.assign(json, {
            stateName: zoneData?.state,
            stateCode: zoneData?.stateCode,
            zoneName: zoneData?.name,
            assignDistrict: zoneData?.assignDistrict,
            zoneId: zoneData?._id,
            userType: 'dpiu'
        })
        dispatch(addZoneDpuiuPiuJson(json))
    }

    const handleAddPiu = (ele) => {
        var dpiuData = ele
        var json = reduxZone?.addJson


        setPiuModal('show')
        Object.assign(json, {
            stateName: dpiuData?.state,
            stateCode: dpiuData?.stateCode,
            zoneName: dpiuData?.name,
            assignDistrict: dpiuData?.assignDistrict,
            dpiuId: dpiuData?._id,
            userType: 'dpiu'
        })
        dispatch(addZoneDpuiuPiuJson(json))
    }

    const handleAddUser = (ele) => {

        var json = reduxUser?.addJson
        setShow('show')

        Object.assign(json, {
            userType: 'dpiu',
            stateName: ele?.state,
            zoneId: ele?.zoneId,
            zoneName: ele?.zoneName,
            dpiuId: ele?._id,
            dpiuName: ele?.name,
            department_type: 'PWD',
            imz_login: Imz_Login?.find((Obj)=>Obj?.state === ele?.state),
            status: 'Active',
            created_by: reduxAuth?.doc?._id,
            districtName: ele?.value?.map((ele)=>{return {title:ele, value:ele, label:ele, status : true}}),
            // assignPackages: ele?.packages
        })





        dispatch(setAddUserJson(json))
    }

    let item;

    if (reduxZone?.dpiu !== null) {
        item = reduxZone?.dpiu?.docs?.map((element, i) => {
            return <tr key={i} className='cursor-pointer'>
                <td className={`text-center py-4`}>{element?.index}</td>
                <td className={` py-4 text-center`}>{element?.zoneId} || </td>
                <td className={` py-4 text-center`}>{element?._id} || </td>
                <td className={` py-4 text-center`}>{element?.state}</td>
                <td className={` py-4 text-center`}>{element?.zoneName}</td>
                <td className={` py-4 text-center`}>{element?.name}</td>
                <td className={` py-4 text-center`}>{element?.userType}</td>
                <td className={` py-4 text-center group`}>
                    <div className='flex items-center justify-center'>
                    <ItemCount userType={'dpiu'} _id={element?._id} onClick={() => handlePIUList(element)} apiUrl={getZone} json={{ page: 1, limit: 0, search: { userType: 'piu', parent: element?._id } }} />
                        <div className='ml-3'><AddButton Icon={AddUserIcon} text={'Add PIU'} onClick={() => handleAddPiu(element)} /></div>
                    </div>
                    </td>
                <td className={` py-4  group`}>
                    <div className='flex items-center justify-center'>
                        <ItemCount userType={'dpiu'} _id={element?._id} onClick={() => handleUserList(element)} apiUrl={getUsers} json={{ page: 1, limit: 0, search: { userType: 'dpiu', dpiu: element?._id } }} />
                        <div className='ml-3'><AddButton Icon={AddUserIcon} text={'Assign User'} onClick={() => handleAddUser(element)} /></div>
                    </div>
                </td>
                <td className={` py-4 text-center group flex gap-1`}>
                    <RemoveButton _id={element?._id} apiUrl={ZoneRemove} apiHit={loadDpiuData} />
                </td>
            </tr>
        })
    }

    const handleUserList = (ele) => {

        var json = { page: 1, limit: 100, search: { userType: 'dpiu', dpiu: ele?._id }, }
        HitApi(json, getUsers).then((res) => {

            if (res?.doc?.docs) {
                CompileUserData(res?.doc, reduxPagination?.pagination).then((CompileData) => {
                    if (CompileData) {
                        var tp = reduxPagination?.pagination
                        tp.totalRecords = CompileData?.totalDocs
                        tp.totalPages = CompileData?.totalPages
                        dispatch(setPagination(tp))
                        dispatch(setUserData(CompileData))
                        setLoading(false)
                    }
                })
            }
        })
    }


    return (
        <div>
            <div className='my-4'>
                <h2 class="rizzui-title-h2 font-semibold mb-2 text-[22px] lg:text-2xl 4xl:text-[26px] text-gray-900 custom-font">Zone Details</h2>
                <div className='my-2 card'>
                    <CustomTable
                        TH={ZoneTH}
                        data={reduxZone?.zone?.docs}
                    />
                </div>
            </div>
            {
                reduxZone?.dpiu !== null ? <div className='my-4'>
                    <div className='flex justify-between'>
                        <h2 class="rizzui-title-h2 font-semibold mb-2 text-[22px] lg:text-2xl 4xl:text-[26px] text-gray-900 custom-font">DPIU List of <span className='font-bold text-teal-600'>{reduxZone?.zone?.docs?.[0]?.name}</span></h2>
                        <label className="cursor-pointer hover:text-success">
                            <button className="btn bg-primary/10 text-primary hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25 " onClick={() => handleCreateDpiu()}>Create DPIU</button>
                        </label>
                    </div>
                    <div className='my-2 card'>
                        <table className={`is-hoverable w-full `}>
                            <thead><CustomTh TH={TH} /></thead>
                            <tbody style={{ maxHeight: 550 }} className=''>
                                {loading ? <tr><td colSpan={TH?.length < 12 ? TH?.length : 12}><LoadingTableData /></td></tr> : item}
                            </tbody>
                        </table>
                    </div>
                </div> : null
            }
            <Piu />
            <CustomModal title={'Create DPIU'} show={DpiuModal} setShow={setDpiuModal} maxHeight={700} data={
                <div className='p-5'>
                    <AddDpiu setShow={setDpiuModal} apiHit={loadDpiuData} />
                </div>
            } />
            <CustomModal title={'Create PIU'} show={PiuModal} setShow={setPiuModal} maxHeight={700} data={
                <div className='p-5'>
                    <AddPiu setShow={setPiuModal} apiHit={loadDpiuData} />
                </div>
            } />
            <CustomModal title={'Assign User'} show={show} setShow={setShow} maxHeight={700} data={
                <div className='p-5'>
                    <AddUser setShow={setShow} />
                </div>
            } />
        </div>
    )
}
