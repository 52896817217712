import { GetPageCount } from "../../../../Utils/Utils"

export const CompilePackageData = (data) =>{
    return new Promise((resolve, reject)=>{
        var td=[{ title: 'All', status: true, value: 'All', label: 'All', serverKey: 'All',  }]
        data?.map((ele)=>td.push({label:ele?.Package_no, title:ele?.Package_no, value:ele?.Package_no,_id :ele?._id, status : true}))
        resolve(td)
    })
}

export const CompilePIUData = (data, pagination) =>{
    return new Promise((resolve, reject)=>{
        var td = data
        td?.docs?.map((ele, index) => Object.assign(ele, {
            index: GetPageCount(pagination?.limit, pagination?.page, index),
           
        }))
        if (td?.pages) {
            Object.assign(td, {
                totalPages: td?.pages,
                totalDocs: td?.total
            })
        }
        resolve(td)
    })
}