
import React, { useEffect, useRef, useState } from 'react'
import { GetToken, MMIToken } from '../../Constant/Api/Constant'
import { mappls } from 'mappls-web-maps';
import { CompileTrackDate, GetCenter, GetMMIToken } from './MapPromiss';
import { useDispatch, useSelector } from 'react-redux';
import Polyline from './MapComponent/Polyline';
import { setMap, setMapToken, setPolyline, setRoute, setWorkTrack } from '../../Store/Action/Map/MapAction';
import LoadingTableData from '../Common/Loading/LoadingTableData';
import Marker from './MapComponent/Marker';
import { HitApi } from '../../Store/Action/CommonApi/ApiAction';
import StateList from '../../Constant/Common/StateList';

var mapplsClassObject = new mappls();
let animate = {
    speed: 10,
    icon_width: 25, // or as “25”
    icon_height: 50, // or as “50”,
    icon_url:
        "http://www.mapmyindia.com/api/advanced-maps/doc/sample/map_sdk/car.png", //Place your icon url
    repeat: false, //false,
}

const PolylineComponent = ({ map, data, LineWidth, startTime, polylineRef, EnaableTrack }) => {
    useEffect(() => {
        if (polylineRef.current) {
            mapplsClassObject.removeLayer({ map: map, layer: polylineRef.current });
        }
        polylineRef.current = mapplsClassObject.Polyline({
            map: map,
            path: data,
            fitbounds: true,
            strokeWeight: LineWidth ? LineWidth : 12,
            strokeOpacity: 0.5,
            startDate: startTime,
            animate: EnaableTrack ? animate : {},
        });
    });
};

export default function MapMyIndiaLocalToken({ height, WithoutDoc, EnableTrack, from }) {
    const map = useRef(null);
    const polylineRef = useRef(null);
    const dispatch = useDispatch()
    const reduxMap = useSelector(state => state.MapReducer)
    const reduxUser = useSelector(state => state.AuthReducer)
    const [center, setCenter] = useState(null)
    const [isMapLoaded, setIsMapLoaded] = useState(false);
    const [localMapData, setLocalMapData] = useState(null)

    useEffect(() => {
        if (reduxMap?.token === null) {
            GetTokenLocal()
        } 
        else {
            if (center === null && reduxMap?.doc && reduxMap?.token) {
                GetCenter(reduxMap?.doc, from).then((res) => {
                    if (res?.status) {
                        setCenter(res?.tc)
                        dispatch(setRoute(res?.route))
                        InitMap(res?.center, 6)
                    }
                })
            }

            if (reduxMap?.token && center === null && reduxMap?.doc && WithoutDoc) {
                setCenter([26.7084656, 84.0197906])
                InitMap(null, 5)
            }

        }
    }, [center, reduxMap]);

    const GetTokenLocal = () => {
        var stateCode = 15
        var json = { stateCode: stateCode }
        if (json?.stateCode) {
            HitApi(json, GetToken).then((res) => {

                if (res?.doc?.access_token) {
                    dispatch(setMapToken(res?.doc?.access_token))
                } else {
                    dispatch(setMapToken(MMIToken))
                }
            })
        } else {
            dispatch(setMapToken(MMIToken))
        }
        // dispatch(setMapToken(MMIToken)) 
    }

    const InitMap = (center, zoom) => {

        mapplsClassObject.initialize(reduxMap?.token, { map: true }, () => {
            if (map.current) { map.current.remove(); }
            map.current = mapplsClassObject.Map({
                id: "map",
                properties: {
                    center: center?.[0] ? center : [26.7084656, 84.0197906],
                    zoom: zoom ? zoom : 4,
                },
            });

            map.current.on("load", () => {
                setIsMapLoaded(true);
                dispatch(setMap({ mapObj: map, mapplsClassObject: mapplsClassObject }))
            });

        });
    }
    return (
        <div>
            {
                reduxMap?.doc ? <div id="map" style={{ width: '100%', height: height ? height : '50vh', marginTop: '10px', display: 'inline-block' }}></div> : <div className="col-span-3 border border-slate-300"><LoadingTableData msg={'We are processing map data...'} /></div>
            }
            {/* Map Components */}
            {isMapLoaded && reduxMap?.route ? <Polyline LineColor={'Blue'} data={reduxMap?.route} map={map.current} mapplsClassObject={mapplsClassObject} /> : null}
            {isMapLoaded && reduxMap?.geoJson ? <PolylineComponent EnableTrack={EnableTrack} polylineRef={polylineRef} LineColor={'Red'} data={reduxMap?.geoJson} map={map.current} mapplsClassObject={mapplsClassObject} /> : null}
            {/* Devices Details */}
            {isMapLoaded && reduxMap?.location ? reduxMap?.location?.location?.map((ele) => <Marker cluster={false} map={map.current} data={ele} mapplsClassObject={mapplsClassObject} />) : null}
            {/* Marker Details */}
            {isMapLoaded && reduxMap?.marker ? <Marker cluster={true} map={map.current} data={reduxMap?.marker} mapplsClassObject={mapplsClassObject} /> : null}
        </div>
    )
}
