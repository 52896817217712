import React, { useState } from 'react'
import { CrossButtonIcon, GearIcon } from '../../../Constant/Icon/Icon'
import { useDispatch, useSelector } from 'react-redux'
import { setView } from '../../../Store/Action/Analytics/AnalyticsAction'

export default function FloatingButton({ show, setShow }) {
    const dispatch = useDispatch()
    const reduxData = useSelector(state => state.AnalyticsReducer)
    const [hide, setHide] = useState(false)

    const handleShow = (value) => {
        dispatch(setView(value))
    }
    
// * {${hide ? 'animate-spin' : ''} */}
    return (
        <div >
            {
                reduxData?.daily ? <div className='p-4 w-max card z-50'>
                    <label className='flex text-center justify-end'><span className={`cursor-pointer ${!hide?'text-primary':'text-error'}`} onClick={() => setHide(!hide)}>{!hide?GearIcon:CrossButtonIcon}</span></label>
                    {hide ? <div className='flex flex-col p-2'>
                        <label>Switch View</label>
                        <div className='flex flex-col'>
                            <label className="inline-flex items-center space-x-2 py-2">
                                <input
                                    className="form-radio is-basic h-5 w-5 rounded-full border-slate-400/70 checked:border-primary checked:bg-primary hover:border-primary focus:border-primary dark:border-navy-400 dark:checked:border-accent dark:checked:bg-accent dark:hover:border-accent dark:focus:border-accent"
                                    name="basic"
                                    type="radio"
                                    onClick={() => handleShow('map')}
                                    checked={reduxData?.view === 'map'} />
                                <p>Map View</p>
                            </label>
                            <label className="inline-flex items-center space-x-2 py-2">
                                <input
                                    className="form-radio is-basic h-5 w-5 rounded-full border-slate-400/70 checked:border-primary checked:bg-primary hover:border-primary focus:border-primary dark:border-navy-400 dark:checked:border-accent dark:checked:bg-accent dark:hover:border-accent dark:focus:border-accent"
                                    name="basic"
                                    type="radio"
                                    onClick={() => handleShow('chart')}
                                    checked={reduxData?.view === 'chart'} />
                                <p>Chart View</p>
                            </label>
                        </div>
                    </div> : null}
                </div> : null
            }
        </div>
    )
}
