import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setPolyline } from '../../../Store/Action/Map/MapAction';

export default function Polyline({ data, map, mapplsClassObject, LineColor, LineWidth,  }) {
  const polylineRef = useRef(null);

  useEffect(() => {
    if (polylineRef.current) {
      mapplsClassObject.removeLayer({ map: map, layer: polylineRef.current });
    }
    polylineRef.current = mapplsClassObject.Polyline({
      editable:true,
      map: map,
      path: data,
      fitbounds: true,
      strokeColor: LineColor ? LineColor : 'blue',
      strokeWeight: LineWidth ? LineWidth : 5,
      strokeOpacity:1,
      
    });
    Object.assign(polylineRef?.current,{startDate:'this is demo'})



  },[polylineRef])

  return (
    <div></div>
  )
}
