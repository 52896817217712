import React from 'react'

export default function CustomModal({ show, setShow, data, title, maxHeight, min, FilterSwitch }) {

    const handleCloseFun = () => {
        setShow('')
    }


    return (
        <div id="modal6" className={`${show} modal modal-scale fixed inset-0 z-[100] flex flex-col items-center justify-center overflow-hidden px-4 py-6 sm:px-5`} role="dialog">
            <div className="modal-overlay absolute inset-0 bg-slate-900/60"></div>
            <div className={`modal-content ${min ? 'min-w-full' : 'min-w-full'} relative flex w-full max-w-2xl origin-bottom flex-col overflow-x-scroll rounded-lg bg-white pb-4 dark:bg-navy-700 `} style={{ height: maxHeight ? maxHeight : '' }}>
                <div className={`flex justify-between rounded-t-lg ${FilterSwitch?'':'bg-slate-200'} px-4 py-3 dark:bg-navy-800 sm:px-5`}>
                    <div className='flex items-center'>
                        {
                            FilterSwitch?<><h3 className="text-base font-medium text-slate-700 mr-4 dark:text-navy-100"> Filters </h3>
                            {FilterSwitch}</> : <h3 className="text-base font-medium text-slate-700 mr-4 dark:text-navy-100"> {title} </h3>
                        }
                    </div>
                    <button data-close-modal className="btn -mr-1.5 h-7 w-7 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25" onClick={() => handleCloseFun()} > <svg xmlns="http://www.w3.org/2000/svg" className="h-4.5 w-4.5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    </button>
                </div>
                <div className="scrollbar-sm overflow-auto">
                    {data}
                </div>
            </div>
        </div>
    )
}
