export const SET_DAILY_REPORTS = 'SET_DAILY_REPORTS'
export const SET_DAILY_JSON_FOR_REPORTS = 'SET_DAILY_JSON_FOR_REPORTS'
export const SET_COMPLETE_REPORTS = 'SET_COMPLETE_REPORTS'
export const SET_COMPLETE_JSON_FOR_REPORTS = 'SET_COMPLETE_JSON_FOR_REPORTS'

export const setDailyReports = (data) => ({ type: SET_DAILY_REPORTS, value: data });

export const setDailyJsonReports = (data) => ({ type: SET_DAILY_JSON_FOR_REPORTS, value: data });

export const setCompleteReports = (data) => ({ type: SET_COMPLETE_REPORTS, value: data });

export const setCompleteJsonReports = (data) => ({ type: SET_COMPLETE_JSON_FOR_REPORTS, value: data });

