import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Login from './WebView/Account/Login/Login'
import '@fontsource-variable/lexend-deca';
import './app.css'
import { buildVersion } from './Constant/Api/Constant'
import { GetBuildVersion } from './Storage/Storage'
import Main from './Navigation/Main/Main'
import { setUserData } from './Store/Action/AuthAction'

import '@fontsource/hind-vadodara/300.css';
import '@fontsource/hind-vadodara/400.css';
import '@fontsource/hind-vadodara/500.css';
import '@fontsource/hind-vadodara/600.css';
import '@fontsource/hind-vadodara/700.css';
import { ChakraProvider } from '@chakra-ui/react';
import { ConfirmDialogProvider } from './Component/Dialog/ConfirmDialog';

export default function App() {
  const dispatch = useDispatch()
  const reduxUser = useSelector(state => state.AuthReducer)

  useEffect(() => {
    

    const tempUserData = localStorage.getItem('ud');
    if (reduxUser?.doc === null && tempUserData) {
      dispatch(setUserData(JSON.parse(tempUserData)));
    }

  }, [])


  const Check = () => {
    const version = GetBuildVersion();
    if (version !== null && version !== undefined && buildVersion === version) {
      return true;
    }
    clearLocalStorage();
  };

  const clearLocalStorage = () => {

  }

  return (
    <div>
      {/* <ChakraProvider> */}
        {/* <ConfirmDialogProvider> */}
          {localStorage.getItem('ud') ? Check() ? <Main /> : <Login /> : <Login />}
        {/* </ConfirmDialogProvider> */}
      {/* </ChakraProvider> */}

    </div>
  )
}
