export default[
    {
        _id : 0,
        screen : 'road',
        filterType : [
            {
                _id:0,
                text:'Device Status',
                title : 'devices.0',
                value : [
                    {text:'ALL', label:'ALL', value:'ALL', active:'bg-navy-700 text-white dark:bg-navy-900', inactive:'border border-info text-info', isActive : true}, 
                    {text:'Device Installed',label:'Device Installed', value:{ $exists : true }, active:'bg-success text-white', inactive:'border border-success text-success', isActive : false},
                    {text:'Device Not Installed',label:'Device Not Installed', value:{ $exists : false }, active:'bg-error text-white', inactive:'border border-error text-error', isActive : false}
                ],
                type : 'dropdown',
                status : true
            },{
                _id:0,
                text:'Map Data Status',
                title : 'mapData.0',
                value : [
                    {text:'ALL',label:'ALL', value:'ALL', active:'bg-navy-700 text-white dark:bg-navy-900', inactive:'border border-info text-info', isActive : true}, 
                    {text:'KML Data Found',label:'KML Data Found', value:{ $exists : true }, active:'bg-success text-white', inactive:'border border-success text-success', isActive : false},
                    {text:'KML Data Not Found',label:'KML Data Not Found', value:{ $exists : false }, active:'bg-error text-white', inactive:'border border-error text-error', isActive : false}
                ],
                type : 'dropdown',
                status : true
            }
        ],
        json : {}
    },{
        _id : 1,
        screen : 'superuser',
        filterType : [
            {
                _id:0,
                text:'User Type',
                title : 'userType',
                value : [
                    {text:'ALL', value:'ALL', active:'bg-navy-700 text-white dark:bg-navy-900', inactive:'border border-info text-info', isActive : true}, 
                    {text:'SuperAdmin', value:{ $exists : true }, active:'bg-success text-white', inactive:'border border-success text-success', isActive : false},
                    {text:'Zone', value:'zone', active:'bg-error text-white', inactive:'border border-error text-error', isActive : false},
                    {text:'DPIU', value:'dpiu', active:'bg-error text-white', inactive:'border border-error text-error', isActive : false},
                    {text:'PIU', value:'piu', active:'bg-error text-white', inactive:'border border-error text-error', isActive : false}
                ],
                type : 'dropdown',
                status : true
            },{
                _id:0,
                text:'Map Data Status',
                title : 'mapData.0',
                value : [
                    {text:'ALL', value:'ALL', active:'bg-navy-700 text-white dark:bg-navy-900', inactive:'border border-info text-info', isActive : true}, 
                    {text:'KML Data Found', value:{ $exists : true }, active:'bg-success text-white', inactive:'border border-success text-success', isActive : false},
                    {text:'KML Data Not Found', value:{ $exists : false }, active:'bg-error text-white', inactive:'border border-error text-error', isActive : false}
                ],
                type : 'dropdown',
                status : true
            }
        ],
        json : {}
    }
]