import React, { useState } from 'react'
import { MapviewIcon, RoadIcon, RulerIcon, TimeIcon, TrophyIcon, TruckIcon, ChevronRight } from '../../Constant/Icon/Icon'
import { useDispatch, useSelector } from 'react-redux'


export default function WorkDetails() {
    const dispatch = useDispatch()
    const reduxData = useSelector(state => state.AnalyticsReducer)
    const reduxUser = useSelector(state => state.AuthReducer)
    const reduxPagination = useSelector(state => state.PaginationReducer)

    let complete = reduxData?.complete?.docs?.[0]
    let daily = reduxData?.daily?.docs?.[0]


    const [selected, setSelected] = useState('All')

    let WorkType = [
        { _id: 0, title: 'Earthwork', serverKey: 'ew', status: true },
        { _id: 1, title: 'Granular Sub Base', serverKey: 'gsb', status: true },
        { _id: 2, title: 'Surface Course (OGPC/MSS/CC)', serverKey: 'sc', status: true },
        { _id: 3, title: 'WBM Grading 2', serverKey: 'wbm2', status: true },
        { _id: 4, title: 'WBM Grading 3', serverKey: 'wbm3', status: true },
        { _id: 5, title: 'Bituminous Macadam', serverKey: 'mb', status: true },
        { _id: 6, title: 'Subgrade', serverKey: 'sg', status: true },
        { _id: 7, title: 'Wet Mix Macadam', serverKey: 'sg', status: true },
    ]

    let key = WorkType?.find((ele) => ele?.title === daily?.activityName)?.serverKey

    let TD = [
        { _id: 0, action: true, iconName: MapviewIcon, className: 'badge', title: 'Compaction', serverKey: 'Moderate', parent: daily, status: true },
        { _id: 0, action: true, iconName: TrophyIcon, className: 'badge', title: 'Milestone Achived', serverKey: 'Moderate', parent: daily, status: true },
        { _id: 0, action: false, iconName: TimeIcon, className: '', title: 'Activity Start Date (OMMAS)', serverKey: 'activityStartDate', parent: daily, status: true },
        { _id: 0, action: false, iconName: TimeIcon, className: '', title: 'Activity Start Date (GPS)', serverKey: 'created_at', parent: daily, status: true },
        { _id: 0, action: false, iconName: TimeIcon, className: '', title: 'Activity Completion Date (OMMAS)', serverKey: 'activityCompletionDate', parent: daily, status: true },
        { _id: 0, action: false, iconName: TimeIcon, className: '', title: 'Activity Completion Date (GPS)', serverKey: 'created_at', parent: daily, status: true },
        { _id: 0, action: false, iconName: RoadIcon, className: '', title: 'Sanctioned Length (in KM)', serverKey: 'sanctionedLength', parent: daily, status: true },
    ]

    const Detaildiv = ({ element }) => {
        const { iconName, title, serverKey, parent } = element;
        let value;
        if (serverKey === 'activityStartDate') {
            value = parent?.ommas?.find(Obj => Obj?.ACTIVITY_NAME === parent?.activityName)?.ACTIVITY_START_DATE;
            if (!value) value = '---';
        } else if (serverKey === 'activityCompletionDate') {
            value = parent?.ommas?.find(Obj => Obj?.ACTIVITY_NAME === parent?.activityName)?.ACTIVITY_COMPLETION_DATE;
            if (!value) value = '---';
        } else {
            value = parent[serverKey];
            // if (!value) value = '---';
        }

        return (
            <div className='bg-white border-b h-full'>
                <div className='flex align-middle h-full items-center px-4'>
                    <span>{iconName}</span>
                    <label className='ml-2 w-full'>{title}</label>
                    <div className='badge w-full text-right'>{value}</div>
                </div>
            </div>
        );
    }


    
    return (
        <div className='bg-white px-3 pb-3'>
            <div className='flex items-center py-4 justify-between'>
                <label style={{ fontSize: 20 }}>{daily?.activityName}</label>
                <label className='mx-3'> <div className="badge rounded-full border border-success text-success font-bold">  In Progress</div> </label>
            </div>
            <div className=' mt-2 flex flex-col justify-between ' style={{ height: '43vh' }}>
                {TD?.map((element) => <Detaildiv element={element} />)}
                <div className='bg-white border-b h-full '>
                    <div className={`flex align-middle h-full items-center px-4`}>
                        <span>{RoadIcon}</span>
                        <label className='ml-2 w-full'>Executed Quantity (in KM)</label>
                        <div className={`badge w-full text-right`}>{reduxData?.daily?.movement/1000}</div>
                    </div>
                </div>=
                <div className='bg-white border-b h-full '>
                    <div className={`flex align-middle h-full items-center px-4`}>
                        <span>{TruckIcon}</span>
                        <label className='ml-2 w-full'>Total Working Time (in Hours)</label>
                        <div className={`badge w-full text-right`}>{Math.round(reduxData?.daily?.workingTime/60)}</div>
                    </div>
                </div>
                <div className='bg-white border-b h-full '>
                    <div className={`flex align-middle h-full items-center px-4`}>
                        <span>{TruckIcon}</span>
                        <label className='ml-2 w-full'>Rounds</label>
                        <div className={`badge w-full text-right`}>{daily?.roundData}</div>
                    </div>
                </div>

            </div>
        </div>
    )
}
