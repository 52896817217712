export const CompileTrackPlayData = (data) => {
    return new Promise((resolve, reject) => {

        var finalData = Object.values(data.reduce((acc, entry) => {
            const { lat, lng, ts } = entry;
            const key = `${lat},${lng}`;
            if (!acc[key] || new Date(ts) < new Date(acc[key].ts)) {
                acc[key] = entry;
            }

            return acc;
        }, {}));

        if (finalData?.[0]) {
            var coordinates = []
            finalData?.map((ele) =>{
                var tj = ele;
                Object.assign(tj, { lat: parseFloat(ele?.lat) || 0, lng: parseFloat(ele?.lng) || 0})
                coordinates.push(tj)
            })
            resolve(coordinates)
        }else{
            resolve([])
        }
    })
}


