import { GetPageCount } from "../../../Utils/Utils"

export const CompileUserData = (data, pagination) =>{
    return new Promise((resolve, reject)=>{
        var td = data
        td?.docs?.map((ele, index) => Object.assign(ele, {
            index: GetPageCount(pagination?.limit, pagination?.page, index),
           
            // Action: type!==null ?<label className="cursor-pointer hover:text-success">
            //     <button className="btn bg-primary/10 text-primary hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25 ">Show {type}</button>
            // </label> : null
        }))
        if (td?.pages) {
            Object.assign(td, {
                totalPages: td?.pages,
                totalDocs: td?.total
            })
        }
        resolve(td)
    })
}