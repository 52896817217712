export const SET_USERS = 'SET_USERS'
export const SET_ADD_USER_JSON = 'SET_ADD_USER_JSON'

export const setUserData = (data) => ({
    type: SET_USERS,
    value: data
});

export const setAddUserJson = (data) => ({
    type: SET_ADD_USER_JSON,
    value: data
});

