export default [
    { id: 0, title: '', serverKey: null, className: '', isPdf: false, status: true },
    { id: 0, title: 'S.NO', serverKey: 'index', className: '', isPdf: true, status: true },
    { id: 1, title: 'Package-Number', serverKey: 'packageNo', className: '', isPdf: true, status: true },
    { id: 1, title: 'Vehicle-Number', serverKey: 'vehicleNo', className: '', isPdf: true, status: false },
    { id: 1, title: 'IMEI', serverKey: 'imei', className: '', isPdf: true, status: true },
    { id: 1, title: 'Award-Date', serverKey: 'awardDate', className: '', isPdf: true, status: true },
    { id: 1, title: 'Start-Time', serverKey: 'startTime', className: '', isPdf: true, status: true },
    { id: 1, title: 'End-Time', serverKey: 'endTime', className: '', isPdf: true, status: true },
    { id: 1, title: 'Sanctioned-Length (in KM)', serverKey: 'excutedQty', className: '', isPdf: true, status: true },
    { id: 1, title: 'Actual Activity-Complation Date (OMMAS)', serverKey: 'actiualActivityCompletionDatetName', className: '', isPdf: true, status: true },
    { id: 1, title: 'Activity-Name', serverKey: 'activityName', className: '', isPdf: true, status: true },
    { id: 1, title: 'Start-Point', serverKey: 'startPoint', className: '', isPdf: true, status: true },
    { id: 1, title: 'End-Point', serverKey: 'endPoint', className: '', isPdf: true, status: true },
    { id: 1, title: 'Patch (in m)', serverKey: 'largest', className: '', isPdf: true, status: true },
    { id: 1, title: 'Movement-Distance (in m)', serverKey: 'movementDistance', className: '', isPdf: true, status: true },
    { id: 1, title: 'Passes', serverKey: 'Passes', className: '', isPdf: true, status: true },
    { id: 1, title: 'Quality', serverKey: 'ColorClass', className: '', isPdf: true, status: false },
    { id: 1, title: 'Vehicle-Type', serverKey: 'vehType', className: '', isPdf: true, status: true },
    { id: 1, title: 'Device-Name', serverKey: 'deviceName', className: '', isPdf: true, status: false },
    { id: 1, title: 'Device-Installation Date', serverKey: 'deviceInstallData', className: '', isPdf: true, status: false },
    { id: 1, title: 'Device-Uninstallation Date', serverKey: 'deviceUninstallDate', className: '', isPdf: true, status: false },
    
]