import React from 'react'

export default function PageTitle({ text, className, fontSize, subTitle }) {
    return (
        <div>
            <label className={[className + ' font-bold']} style={{fontSize : fontSize || 24, }}>{text}</label>
            {subTitle ? <label className={[className, ' font-bold']} style={{fontSize : 24, }}>{text}</label> : null}
        </div>
    )
}
