import { SET_DISTRICT_DATA, SET_PACKAGE_DATA } from "../../Action/CommonApi/DefaultHeaderDropdownDataAction.js";

const initialState = {
    districtData: null,
    packageData: null,
    json: { page: 1, limit: 5000, search: {} },
    error: {},
    timestamp: Date.now()
}

const DefaultHeaderDropdownDataReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_DISTRICT_DATA:
            return ({ ...state, districtData: action.value, timestamp: Date.now() })
        case SET_PACKAGE_DATA:
            return ({ ...state, packageData: action.value, timestamp: Date.now() })
        default:
            return state;
    }
}

export default DefaultHeaderDropdownDataReducer;