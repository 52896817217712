import React, { useEffect, useState } from 'react'
import { HitApi } from '../../Store/Action/CommonApi/ApiAction'
import { useDispatch, useSelector } from 'react-redux'
import Flatpickr from "react-flatpickr";
import { ModifyDate, getIMZDateFormat, getIMZDateFormat2, timeDiffDay } from '../../Utils/Utils';
import { CalenderIcon } from '../../Constant/Icon/Icon';
import { IMZBaseURL } from '../../Constant/Api/Constant';
import { CompileTrackPlayData } from './TrackAndPlayPromiss';
import { setTrackAndPlayControl, setTrackAndPlayData } from '../../Store/Action/TrackAndPlay/TrackAndPlayAction';


export default function TrackAndPlayFilter({ imei }) {
  const dispatch = useDispatch()
  const reduxTrackData = useSelector(state => state.TrackAndPlayDatePickerReducer)
  const reduxUser = useSelector(state => state.AuthReducer)

  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [loading, setLoading] = useState(false)
  const [msg, setMsg] = useState(null)
  const [maxDate, setMaxDate] = useState(Date.now())

  useEffect(() => {



  }, [reduxTrackData])

  const handleStart = (date) => {
    setStartDate(date)
    var d = ModifyDate(date, 6, 'add')
    setMaxDate(d)
    setEndDate(d)
  }
  const handleEnd = (date) => {
    var dayDiff = timeDiffDay(startDate, date)
    if (dayDiff <= 0) {
      setEndDate(date)
    } else {
      var d = ModifyDate(date, 6, 'minus')
      setStartDate(d)
      setMaxDate(date)
    }
  }

  const handleFetchData = () => {
    setMsg(null)

    var json = {
      requesttype: "TRKPLYREPORT",
      vendorcode: "IMZ",
      request: {
        username: reduxUser?.doc?.imz_login?.[0]?.username,
        pin: reduxUser?.doc?.imz_login?.[0]?.pin,
        transid: "",
        ipaddress: "124.123.18.38",
        type: "web",
        startdate: getIMZDateFormat2(startDate, 'start'),
        enddate: getIMZDateFormat2(endDate),
        clienttype: "web",
        accid: reduxUser?.doc?.imz_login?.[0]?.accid,
        imei: imei,
      },
    }
    setLoading(true)




    HitApi(json, IMZBaseURL).then((res) => {

      if (res?.response?.report?.[0]) {
        CompileTrackPlayData(res?.response?.report).then((Result) => {
          if (Result?.length>0) {

            dispatch(setTrackAndPlayData(Result))
            setLoading(false)
          }
        })
      }
      else{
        setMsg('No data found for track and play, kindly change the date range')
        setLoading(false)
      }
    })

  }

  setTimeout(() => {
    if(loading && reduxTrackData?.doc === null){
      setLoading(false)
    }
  }, 30000);

  const handleClearData = () =>{
    dispatch(setTrackAndPlayData(null))
    dispatch(setTrackAndPlayControl(false))
  }


  return (
    <div className='card p-5 mt-5'>
      <div className='mt-5'>
        <div>{msg !== null ? <label className='badge text-error text-lg ml-0 pl-0'>{msg}</label> : null}</div>
        <div className='grid grid-cols-3 gap-6'>
          <div>
            <label>Start Date</label>
            <label className="relative flex">
              <Flatpickr options={{ maxDate: Date.now() }} value={startDate} onChange={([date]) => { handleStart(date, 'start') }} className="form-input peer w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 pl-9 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent" />
              <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent"> {CalenderIcon} </span>
            </label>
          </div>
          <div>
            <label>End Date</label>
            <label className="relative flex">
              <Flatpickr options={{ maxDate: maxDate }} value={endDate} onChange={([date]) => { handleEnd(date, 'end') }} className="form-input peer w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 pl-9 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent" />
              <span className="pointer-events-none absolute flex h-full w-10 items-center justify-center text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent"> {CalenderIcon} </span>
            </label>
          </div>
          <div>
            <div className='mt-5'>
              {
                !loading ? <div>
                  <button className='mx-1 btn border border-primary text-primary bg-primary/10' onClick={() => handleFetchData()}>Get Track Data</button>
                  <button className='mx-1 btn border border-error text-error bg-error/10' onClick={() => handleClearData()}>Clear History Data</button>
                </div> : <button className='btn border border-primary text-primary bg-primary/10'>Loading...</button>
              }
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}
