import { Marker } from '@react-google-maps/api'
import React from 'react'

export default function CustomMarker({ ele }) {
    var iconPin = {
        path: 'M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z',
        fillColor: '#64be67',
        fillOpacity: 1,
        scale: 0.05, //to reduce the size of icons
       };


    return (
        <Marker
            key={ele?.index}
            position={ele}
            icon={{url : 'http://www.mapmyindia.com/api/advanced-maps/doc/sample/map_sdk/car.png', scaledSize:{width : 15, height:20}}}
        />
    )
}
