import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setDashboardJson, setDashboardTh } from '../../../../Store/Action/Dashboard/DashboardAction';
import { FilterOptions } from './FilterSwithData';

export default function FilterSwitch({ selected, setSelected }) {
  const dispatch = useDispatch()
  const reduxDashboard = useSelector(state => state.DashboardReducer)



  const handleSelected = (ele) => {
    Object.assign(reduxDashboard?.json, { selected: ele?._id })
    var FindOne = FilterOptions?.find((Obj) => Obj?._id === ele?._id )
    if (FindOne) {
      var th = reduxDashboard?.TH
      th = th.map(item => ({ ...item, status: FindOne?.serverKey.includes(item.serverKey) }));
      dispatch(setDashboardTh(th))
    }
    dispatch(setDashboardJson(reduxDashboard?.json))
  }

  return (
    <div className='flex '>
      {FilterOptions?.map((ele, index) => <div key={index} className={`cursor-pointer text-center border-2 py-2 px-6 mr-4 card ${reduxDashboard?.json?.selected === ele?._id ? 'bg-primary/10 text-primary border-primary' : ''}`} onClick={() => handleSelected(ele)}> <label>{ele?.title}</label> </div>)}
    </div>
  )
}
