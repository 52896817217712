
export const DecompileGeoJson = (data, from) => {
    return new Promise((resolve, reject) => {
        const td = data?.features?.flatMap(ele =>
            ele?.geometry?.coordinates?.map(innerEle => ({
                lat: parseFloat(innerEle?.[0]),
                lng: parseFloat(innerEle?.[1])
            }))
        ) || [];
        resolve(td)
    })
}