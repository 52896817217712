import React, { useEffect, useState } from 'react'
import { HitApi } from '../../../Store/Action/CommonApi/ApiAction'
import { getUsers } from '../../../Constant/Api/Constant'

export default function ItemCount({ onClick, json, apiUrl }) {
    const [data, setData] = useState(null)

    useEffect(() => {
        if (data === null) {
            loadData()
        }
    }, [])

    const loadData = () => {
        if (json && apiUrl) {
            HitApi(json, apiUrl).then((res) => {
                if (res?.status === 200) {
                    setData(res?.doc?.total)
                }
            })
        }
    }
    return (
        <div onClick={()=>onClick?onClick():{}}>
            <div class="p-2 px-3 badge bg-primary/10 text-primary dark:bg-accent-light/15 dark:text-accent-light" >
                {data > 0 ? data : 0}
            </div>
        </div>
    )
}
