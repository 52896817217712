import moment from "moment";
import { CreatePagination } from "../../Store/Action/Pagination/Pagination";
import { ConvertDataUsingMoment, GetFullYear, GetPageCount, LastActiveFunction, sortByTimestamp, timeAgo, toISOString } from "../../Utils/Utils";

import _, { difference } from 'lodash'

export const CompileMapInstalledData = (InstalledDevicesData, LiveLocationData, pagination) => {
    return new Promise((resolve, reject) => {
        var features = [];
        var combinedArray = InstalledDevicesData.map((item1, index) => {
            const matchedItem = LiveLocationData.find(item2 => item2?.vehnum === item1?.vehnum && item2?.imei === item1?.imei);
            const tsObject = {
                "type": "Feature",
                "properties": {
                    "htmlPopup": `
                        <div style="display:flex; flex-direction : column;font-weight : 800">
                            <label style="width : 100%; margin-bottom : 10px">Address : ${matchedItem?.address}</label>
                            <label style="width : 100%">Last Active Time : ${item1?.updatedts}</label>
                            <label style="width : 100%">Time Ago : ${timeAgo(matchedItem?.orgts)}</label>
                        </div>
                    `
                },
                "geometry": {
                    "type": "Point",
                    "coordinates": [matchedItem?.lat || 0, matchedItem?.lng || 0]
                }
            };
            features.push(tsObject);
            return {
                ...item1, ...matchedItem,
                department: matchedItem?.name?.split('-')?.[1],
                orgt: ConvertDataUsingMoment(matchedItem?.orgts || item1?.updatedts),
                sortBy: new Date(matchedItem?.orgts)?.getDate(),
                lastActive: timeAgo(matchedItem?.orgts),
                differenceInTime: timeDiffDay(ConvertDataUsingMoment(matchedItem?.orgts || item1?.updatedts), Date.now()),
                currentStatus: <div className={`badge ${matchedItem?.status === 'IDLE' || matchedItem?.status === 'STOP' || matchedItem?.status === 'MOTION' ? 'bg-success/10 text-success' : 'bg-error/10 text-error'} m-0 p-1`} >{matchedItem?.status}</div>,
                Action: <label className="cursor-pointer hover:text-success">
                    <button className="btn bg-primary/10 text-primary hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25 ">Live Status</button>
                </label>
            };
        });

        // var sortedData = _.orderBy(combinedArray, 'orgt', 'desc')
        // sortedData?.map((ele, index) => Object.assign(ele, { index: index + 1 }))


        var onTime = [];
        var offTime = [];

        combinedArray?.map((ele) => {
            if (ele?.differenceInTime <= 10) {
                onTime.push(ele)
            } else {
                offTime.push(ele)
            }
        })

        var temp_ontime = _.orderBy(onTime, 'orgt', 'desc')

        var finalData = [...onTime, ...offTime]
        finalData?.map((ele, index) => Object.assign(ele, { index: index + 1 }))
        var coordinates = []
        finalData?.map((ele)=>coordinates.push({lat:ele?.lat || 0, lng:ele?.lng || 0}))

        var obj = [{geometry : {coordinates : coordinates}}]

        // --------- //
        var MainData = CreatePagination(finalData, 10)
        pagination.totalPages = MainData?.paginate?.length

        var finalJson = { "type": "FeatureCollection", "features": features, MainData: MainData, data : obj }
        // var finalJson = MainData

        resolve(finalJson)

    })
}


export const timeDiffDay = (date1, date2) => {
    if (date1 !== '' && date2 !== '') {
        var date1 = new Date(date1)
        var date2 = new Date(date2)
        var difference = date1.getTime() - date2.getTime();

        var daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
        difference -= daysDifference * 1000 * 60 * 60 * 24

        var hoursDifference = Math.floor(difference / 1000 / 60 / 60);
        difference -= hoursDifference * 1000 * 60 * 60

        var minutesDifference = Math.floor(difference / 1000 / 60);
        difference -= minutesDifference * 1000 * 60

        var secondsDifference = Math.floor(difference / 1000);

        return Math.abs(daysDifference);
    } else {
        return 'data error'
    }
}

export const ReCompileDataForMap = (json, data, pagination) => {
    return new Promise((resolve, reject) => {

        const finalData = data?.data?.filter(item => {
            // Check if all filter criteria match for the current item
            return Object.entries(json?.search).every(([key, value]) => {
                return item[key] === value;
            });
        });

        finalData?.map((ele, index) => Object.assign(ele, { index: index + 1 }))

        var MainData = CreatePagination(finalData, 10, data?.data)
        pagination.totalPages = MainData?.paginate?.length
        resolve(MainData)
    })
}
