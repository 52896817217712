import { SET_ERROR } from "../../Action/Error/ErrorAction";

const initialState = {
    doc: null,
    json: {},
    timestamp: Date.now()
}

const ErrorReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_ERROR:
            return ({ ...state, doc: action.value, timestamp: Date.now() })
        default:
            return state;
    }
}

export default ErrorReducer;