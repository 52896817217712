export const SET_TRACK_AND_PLAY_DATA = 'SET_TRACK_AND_PLAY_DATA'
export const SET_TRACK_AND_PLAY_DATA_CONTROL = 'SET_TRACK_AND_PLAY_DATA_CONTROL'
export const SET_SELECTED_DEVICE_FOR_TRACK_AND_PLAY = 'SET_SELECTED_DEVICE_FOR_TRACK_AND_PLAY'

export const SET_TRACK_AND_PLAY_CURRENT_TRACK = 'SET_TRACK_AND_PLAY_CURRENT_TRACK'


export const setTrackAndPlayData = (data) => ({
    type: SET_TRACK_AND_PLAY_DATA,
    value: data
});

export const setDeviceForTrackPlay = (data) => ({
    type: SET_SELECTED_DEVICE_FOR_TRACK_AND_PLAY,
    value: data
});


export const setTrackAndPlayControl = (data) => ({
    type: SET_TRACK_AND_PLAY_DATA_CONTROL,
    value: data
});


export const setTrackAndPlayCurrentTrack = (data) => ({
    type: SET_TRACK_AND_PLAY_CURRENT_TRACK,
    value: data
});