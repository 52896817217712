import React, { useEffect, useState } from 'react'
import Slider from '@mui/material/Slider';
import { useDispatch, useSelector } from 'react-redux';
import { CompileSliderData } from './CustomPromiss';
import { setDashboardDateArr } from '../../../../Store/Action/Dashboard/DashboardAction';
import { GenerateQueryForAnalytics } from '../../../../Utils/Utils';

function CustomSlider() {

    const dispatch = useDispatch()
    const reduxDashboard = useSelector(state => state.DashboardReducer)
    const [localData, setLocalData] = useState([])

    useEffect(() => {
        if (reduxDashboard?.DateArr === null) {
            loadData()
        }
    }, [])

    const loadData = () => {

        CompileSliderData(reduxDashboard?.json).then((res) => {
            if (res) {
                dispatch(setDashboardDateArr(res))
            }
        })
    }
    const ShowData = (value) => {
        var find = reduxDashboard?.DateArr?.find(ele => ele.value === value)
        return find?.label;
    }

    const handleChange = (e, value) => {
        var json = reduxDashboard?.json
        GenerateQueryForAnalytics(reduxDashboard?.DateArr, json, value)
        Object.assign(json,{apiCall : 'call'})
    }


    return (
        <div>
            
            {
                reduxDashboard?.json?.type && reduxDashboard?.DateArr ?
                <Slider defaultValue={[reduxDashboard?.DateArr?.[reduxDashboard?.DateArr?.length - 1]?.value]} getAriaValueText={ShowData} valueLabelDisplay="auto"
                valueLabelFormat={ShowData} step={null} marks={reduxDashboard?.DateArr} max={reduxDashboard?.DateArr?.[reduxDashboard?.DateArr?.length - 1]?.value} onChange={(e, val) => handleChange(e, val)} />
                    :<><Slider defaultValue={[0, 300]} getAriaValueText={ShowData} valueLabelDisplay="auto" valueLabelFormat={ShowData} step={null} marks={reduxDashboard?.DateArr} max={reduxDashboard?.DateArr?.[reduxDashboard?.DateArr?.length - 1]?.value} onChange={(e, val) => handleChange(e, val)} /></>
            }

            {reduxDashboard?.json?.type ? <></> : <>{null}</>}


            {/* <div className='flex justify-between'>
                {localData?.map((ele, index) => <label key={index} style={{ width: 50 }}>{ele?.label}</label>)}
            </div> */}
        </div>
    )
}

export default CustomSlider