import axios from "axios";

export const SET_ERROR = 'SET_ERROR'
export const SET_API_JSON= 'SET_API_JSON'
export const SET_API_RESULT = 'SET_API_RESULT'

export const setError = (data) => ({
    type: SET_ERROR,
    value: data
});


export const setApiJson = (data) => ({
    type: SET_API_JSON,
    value: data
});


export const setApiResult = (data) => ({
    type: SET_API_RESULT,
    value: data
});


export const HitApi = (json, api) => {
    const MyPromiss = new Promise((resolve, reject) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'x-auth': 'token' },
            body: JSON.stringify(json)
        };



        fetch(api, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {

                    if (result) {
                        resolve(result)
                    }
                },
                (error) => {
                    resolve(error)
                }
            )
    })

    return MyPromiss;
}

export const HitApiGet = (json, api) => {
    const MyPromiss = new Promise((resolve, reject) => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'x-auth': 'token' },
            body: JSON.stringify(json)
        };
        fetch(api, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {

                    if (result) {
                        resolve(result)
                    }
                },
                (error) => {
                    resolve(error)
                }
            )
    })

    return MyPromiss;
}


export const AxiosHitApi = (json, api) => {
    const MyPromiss = new Promise((resolve, reject) => {
        axios.get(api, { params: json?.params})
            .then((res) => {resolve(res)})
            .catch((err) => console.error(err));

    })

    return MyPromiss;
}

