export const SET_DISTRICT_DATA = 'SET_DISTRICT_DATA'
export const SET_PACKAGE_DATA = 'SET_PACKAGE_DATA'

export const setCommonDistrictData = (data) => ({
    type: SET_DISTRICT_DATA,
    value: data
});


export const setCommonPackageData = (data) => ({
    type: SET_PACKAGE_DATA,
    value: data
});
