import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CustomGoogleMap from '../../../Component/GoogleMap/CustomGoogleMap'
import { getFormattedDate, getIMZDateFormat } from '../../../Utils/Utils'
import { HitApi } from '../../../Store/Action/CommonApi/ApiAction'
import { IMZBaseURL } from '../../../Constant/Api/Constant'
import { setLiveViewData } from '../../../Store/Action/Map/LiveView/LiveViewAction'
import { CompileLiveTrackData, GetDetailUsingImei } from './LiveViewPromiss'
import { useSetInterval } from './useSetInterval'
import Details from './Details'
import TrackAndPlayFilter from '../../../Component/CommonFilters/TrackAndPlayFilter'
import PageTitle from '../../../Component/Common/Title/PageTitle'
import { setTrackAndPlayControl } from '../../../Store/Action/TrackAndPlay/TrackAndPlayAction'
import MarkerDetails from './MarkerDetails'

export default function LiveView() {
  let startDate = getFormattedDate(Date.now(), ['month', 'date', 'year'])
  const dispatch = useDispatch()
  const reduxUser = useSelector(state => state.AuthReducer)
  const reduxMap = useSelector(state => state.MapReducer)
  const reduxTrackData = useSelector(state => state.TrackAndPlayDatePickerReducer)

  const [imei, setImei] = useState(null)
  const [data, setData] = useState(null)

  useEffect(() => {

    if (reduxUser?.doc && reduxMap?.LiveTrack === null) {
      var imei = window.location.pathname.split('/')?.[3]
      setImei(imei)
      loadData(imei)
    }


  }, [reduxMap])

  const loadData = (imei) => {
    var json = {
      "requesttype": "DEVICEVIEW", "vendorcode": "IMZ", "request": {
        username: reduxUser?.doc?.imz_login[0]?.username,
        pin: reduxUser?.doc?.imz_login[0]?.pin, "ipaddress": "196.195.156.107", "clienttype": "web"
      }
    }

    HitApi(json, IMZBaseURL).then((res) => {
      if (res?.response?.data?.[0]) {
        GetDetailUsingImei(res?.response?.data, imei).then((Result) => {
          if (Result) {
            loadLiveData(Result)
          }
        })
      }
    })

  }


  const loadLiveData = (ele) => {
    var json = {
      requesttype: "TRKPLYREPORT",
      vendorcode: "IMZ",
      request: {
        username: reduxUser?.doc?.imz_login[0]?.username,
        pin: reduxUser?.doc?.imz_login[0]?.pin,
        transid: "",
        ipaddress: "124.123.18.38",
        type: "web",
        startdate: getIMZDateFormat(startDate, 'start'),
        enddate: getIMZDateFormat(startDate),
        clienttype: "web",
        accid: reduxUser?.doc?.imz_login[0]?.accid,
        imei: ele?.imei,
      },
    };




    HitApi(json, IMZBaseURL).then((res) => {



      if (res?.response?.report?.[0]) {
        CompileLiveTrackData(res?.response?.report, ele).then((Result) => {
          if (Result) {
            setData(Result?.ele)
            dispatch(setLiveViewData(Result?.td))
          }
        })
      } else {
        setData([])
        dispatch(setLiveViewData([]))
      }
    })
    // 358250330614719 
  }

  const handleAction = (type) => {
    if (type === 'start') {
      dispatch(setTrackAndPlayControl(true))
    } else {
      dispatch(setTrackAndPlayControl(false))
    }
  }


  return (

    <div>
      <div className='my-4'><Details Data={data} /></div>
      {
        reduxMap?.LiveTrack ? <div>
          <div className='my-4'><PageTitle text={'History Track and Play Control (Red Color Line on the MAP)'} /></div>
          <div className='my-4'> <TrackAndPlayFilter imei={imei} /> </div>
        </div> : null
      }
      
      <div className='my-4 flex'>
        <PageTitle text={'Live Data (Blue Color Line on the MAP)'} />
        <div className='ml-10'>
          {
            reduxTrackData?.doc ? reduxTrackData?.start ? <button className='mx-1 btn border border-error text-error bg-error/10' onClick={() => handleAction('stop')}>STOP</button> : <button className='mx-1 btn border border-primary text-primary bg-primary/10' onClick={() => handleAction('start')}>START</button> : null
          }
        </div>
      </div>
      <div className='my-4'><MarkerDetails Data={reduxTrackData?.currentTrack} start={reduxTrackData?.start} /></div>
      <div className='my-4 border'><CustomGoogleMap zoom={17} imei={imei} from='liveview' /></div>

    </div>
  )
}


// AIzaSyBq3vDY_h8v85geg1bltO7O6YcSyf07STU // Google Map Keys