import { SET_MMI_DEVICES } from "../../Action/CommonApi/ServerDevicesAciton";

const initialState = {
    doc: null,
    json: {},
    error:{},
    timestamp: Date.now()
}

const ServerDevicesReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_MMI_DEVICES:
            return ({ ...state, doc: action.value, timestamp: Date.now() })
        default:
            return state;
    }
}

export default ServerDevicesReducer;