import { SET_LIVE_ALERTS } from "../../Action/LiveAlerts/LiveAlertsAction";


const initialState = {
    doc : null, 
    json : {page:1, limit : 10, search :{} },
    timestamp: Date.now()
}

const LiveAlertReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_LIVE_ALERTS:
            return ({ ...state, doc: action.value, timestamp: Date.now() })
        default:
            return state;
    }
}

export default LiveAlertReducer;