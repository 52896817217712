export const TH = [
    { id: 0, title: 'Sr. No', serverKey: 'index', className: '', isPdf: true, status: true },
    { id: 0, title: 'zone_id', serverKey: 'zoneId', className: '', isPdf: true, status: true },
    { id: 0, title: 'dpiu_id', serverKey: 'dpiuId', className: '', isPdf: true, status: true },
    { id: 0, title: 'piu_id', serverKey: '_id', className: '', isPdf: true, status: true },
    { id: 0, title: 'PIU Name', serverKey: 'name', className: '', isPdf: true, status: true },
    { id: 0, title: 'User Type', serverKey: 'userType', className: '', isPdf: true, status: true },
    { id: 0, title: 'List of Users', serverKey: 'userType', className: '', isPdf: true, status: true },
    { id: 0, title: 'Action', serverKey: 'Action', className: '', isPdf: true, status: true },
]
