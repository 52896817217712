import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { HitApi } from '../../../Store/Action/CommonApi/ApiAction'
import { CompileDetailsAnalytics } from '../Complete/Details_old/DetailsPromiss'
import { getAnalyticsDaily } from '../../../Constant/Api/Constant'
import { setPagination } from '../../../Store/Action/Pagination/Pagination'
import { setDailyAnalytics, setSeelctedDevice, setWork } from '../../../Store/Action/Analytics/AnalyticsAction'
import { CompileDailyAnalyticsData } from '../Complete/Details/DetailsPromiss'

export default function DeviceItem({ ele, selected, setSelected, setLoading }) {
  const dispatch = useDispatch()
  const reduxData = useSelector(state => state.AnalyticsReducer)
  const reduxPagination = useSelector(state => state.PaginationReducer)

  const handleClick = () => {
    dispatch(setSeelctedDevice({ imei: ele?.imei, type: ele?.vehtype }))
    var json = reduxData?.dailyJson
    Object.assign(json.search, {
      "$or": [{ vehType: ele?.vehname }]
    })

    if (ele?.vehtype === 'Static Roller' || ele?.vehtype === 'Roller') {
      Object.assign(json.search, {
        "totalKm": { '$gt': 0 },
        "trips.0": { $exists: true },
        "trips.largest": { '$gte': 50 },
        "trips.largest": { '$lte': 1800 },
        "$or": [{ vehType: "Static Roller" }],
        // workName: "WBM Grading 3"
      })
    } else {
      delete json?.search?.["totalKm"]
      delete json?.search?.["trips.0"]
      delete json?.search?.["trips.largest"]
      delete json?.search?.["trips.largest"]
      if(json.search.workName === undefined || json.search.workName === null){
        Object.assign(json.search,{workName: "Earthwork"})
      }
      
    }

    CallApi(json)

  }

  const CallApi = (json) => {

    HitApi(json, getAnalyticsDaily).then((res) => {

      setLoading(true)

      if (res?.doc?.docs) {
        CompileDetailsAnalytics(res?.doc, reduxPagination?.pagination).then((CompledData) => {
          console.log('CompledData', CompledData?.tp);
          if (CompledData?.tp) {
            var tp = reduxPagination?.pagination
            tp.totalRecords = CompledData?.tp?.totalDocs
            tp.totalPages = CompledData?.tp?.totalPages

            // Adding Total Work time and Total Work
            var t_compliedData = CompledData?.tp
            Object.assign(t_compliedData, {
              workingTime: res?.roadCover,
              movement: res?.movement
            })
            dispatch(setPagination(tp))
            dispatch(setDailyAnalytics(t_compliedData))
            // dispatch(setWork(CompledData?.workSet))
            setLoading(false)
          }
        })
      }
    })
  }

  return (
    <div className={`border px-4 py-2 rounded-lg ${reduxData?.selectedDevice?.imei === ele?.imei ? ' bg-primary/10 text-primary font-bold border-primary' : ''}`} onClick={() => handleClick()}>
      <div>{ele?.vehname}</div>
      <div>{ele?.imei}</div>
    </div>
  )
}
