import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CompileDefaultData, CompileDropdownData } from '../Table/Promiss/CustomHeaderPromiss'
import { HitApi } from '../../Store/Action/CommonApi/ApiAction'
import { GetRoads } from '../../Constant/Api/Constant'
import { setPagination } from '../../Store/Action/Pagination/Pagination'
import { setFilter } from '../../Store/Action/Filter/FilterAction'
import Select from '../Select/Select'
import { setCommonDistrictData, setCommonPackageData } from '../../Store/Action/CommonApi/DefaultHeaderDropdownDataAction.js'
import SearchableSelect from '../Select/SearchableSelect.js'

export default function DistrictAndPackageFilter({ districtKey, packageKey, json, apiHit }) {
    const dispatch = useDispatch()
    const reduxUser = useSelector(state => state.AuthReducer)
    const [loading, setLoading] = useState(false)
    const reduxFilter = useSelector(state => state.FilterReducer)
    const reduxPagination = useSelector(state => state.PaginationReducer)
    const reduxCommonData = useSelector(state => state.DefaultHeaderDropdownDataReducer)

    const [districtData, setDistrictData] = useState([])

    useEffect(() => {
        if (reduxUser?.doc) {
            loadDefault()
        }

    }, [])

    const loadDefault = () => {
        CompileDefaultData(reduxUser?.doc).then((res) => {
            if (res) {
                dispatch(setCommonDistrictData(res))
            }
        })
    }

    const handleChange = (e, serverKey, searchBy, load) => {
        var value = e?.value
        if (value !== 'All' && value !== '') {
            Object.assign(json.search, { [serverKey]: value })
        } else {
            delete json.search[serverKey]
        }
        if (load) {
            handleDataLoad(value, 'districtName', searchBy)
        }
    }

    const handleDataLoad = (value, serverKey, searchBy) => {
        var json = {
            page: 1,
            limit: 5000,
            search: {
                [serverKey]: value
            },
            select: 'Package_no'
        }
        setLoading(true)
        HitApi(json, GetRoads).then((res) => {



            if (res?.doc?.docs) {
                CompileDropdownData(res?.doc?.docs, searchBy).then((result) => {
                    if (result) {
                        var json = reduxFilter?.doc
                        Object.assign(json, { [searchBy]: result })
                        dispatch(setFilter(json))
                        dispatch(setCommonPackageData(result))
                    }
                    setLoading(false)
                })
            }
        })
    }

    return (
        <div className='grid grid-cols-4 gap-4'>
            {/* <div><Select title={'District Name'} defaultText={'Select District'} option={districtData} onChange={(e) => handleChange(e, districtKey ? districtKey : 'districtName', 'Package_no')} /></div>
            <div><Select loading={loading} show={true} title={'Package No'} defaultText={loading ? 'Loading Packages...' : 'Select Package No'} option={reduxFilter?.doc?.Package_no} serverKey={'districtName'} json={json} onChange={(e) => handleChange(e, packageKey ? packageKey : 'Package_no', 'Road_Code')} /></div> */}
            <div><SearchableSelect title={'District'} defaultText={'Select District...'} data={reduxCommonData?.districtData} onChange={(e) => handleChange(e, districtKey ? districtKey : 'districtName', 'Package_no', true)} /></div>
            <div><SearchableSelect title={'Package No.'} defaultText={'Select Package...'} data={reduxCommonData?.packageData} onChange={(e) => handleChange(e, packageKey ? packageKey : 'Package_no', 'Road_Code')} /></div>
        </div>
    )
}
