import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { LogOutIcon, UserIcon } from '../../Constant/Icon/Icon'

export default function UserPanel() {
    const reduxUser = useSelector(state => state.AuthReducer)


    useEffect(()=>{

    },[reduxUser])

    const handleLogout = () => {
        localStorage.removeItem("ud")
        window.location.href = '/login'
    }

  return (
    <div>
            <div id="notification-wrapper" className="flex">
                <button id="notification-ref" className="btn space-x-2 font-medium hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                    <span>Hi, {reduxUser?.doc!==null?reduxUser?.doc?.name:null}</span>
                    {UserIcon}
                </button>
                <div id="notification-box" className="popper-root z-50" style={{ position: 'absolute', inset: '0px 0px auto auto', margin: 0, transform: 'translate(-58px, 58px)' }} data-popper-placement="bottom-end">
                    <div className="popper-box mx-4 mt-1 flex max-h-[calc(100vh-6rem)] w-[calc(100vw-2rem)] flex-col rounded-lg border border-slate-150 bg-white shadow-soft dark:border-navy-800 dark:bg-navy-700 dark:shadow-soft-dark sm:m-0 sm:w-40">
                        <div className="rounded-t-lg bg-slate-100 text-slate-600 dark:bg-navy-800 dark:text-navy-200">
                            <div className="tabs is-scrollbar-hidden flex shrink-0 overflow-x-auto">
                                <div className='flex flex-col w-full '>
                                    <label>
                                        <a href='/account' className='p-2 flex items-center cursor-pointer hover:bg-primary/10 hover:text-primary'>
                                            <span className='m-1'>{UserIcon}</span>
                                            <span className='m-1'>Profile</span>
                                        </a>
                                    </label>
                                    <label className='p-2  w-full flex items-center cursor-pointer hover:bg-primary/10 hover:text-primary' onClick={() => handleLogout()}>
                                        <span className='m-1'>{LogOutIcon}</span>
                                        <span className='m-1'>Logout</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
  )
}
