import React from 'react'
import Select from 'react-select'

export default function SearchableSelect({title, defaultText, data, onChange, loading, show, errors, field, important}) {



  return (
    <div className='mb-3'>
      <label className="block">
        <h2 className="font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100 lg:text-base">{title}{important ? <span className='text-error'>&nbsp;*</span> : ''}</h2>
        <Select options={data||[]} placeholder={defaultText}  onChange={onChange} isClearable={true} />
      </label>
      {loading && show ? <div className="progress h-1 bg-slate-150 dark:bg-navy-500"> <div className="is-indeterminate relative w-4/12 rounded-full bg-slate-500 dark:bg-navy-400" ></div> </div> : null}
      <span className="text-small tracking-wide text-error dark:text-error">{errors !== undefined ? errors[field]?.message : null}</span>
    </div>
  )
}
