import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CustomChart from '../CustomChart/CustomChart'
import CustomPieChart from '../CustomChart/CustomPieChart'
import CustomModal from '../../../../Component/Modal/CustomModal'
import FilterSwitch from '../List/FilterSwitch'
import CustomTable from '../../../../Component/Table/CustomTable'

export default function TwoColumn() {
  const dispatch = useDispatch()
  const reduxDashboard = useSelector(state => state.DashboardReducer)
  const reduxPagination = useSelector(state => state.PaginationReducer)
  const [show, setShow] = useState('')
  const [selected, setSelected] = useState(null)
  return (
    <div>
      <div className={`grid grid-cols-2 gap-4`}>
        <div><CustomChart title={'Rounds And Total Running (in KM) '} serverKey={'MainData'}  show={show} setShow={setShow} showMoreBtn={true} /></div>
        <div><CustomChart title={'Active Vehicles (Per Month) '} serverKey={'ActiveVechileData'}  show={show} setShow={setShow} showMoreBtn={true} /></div>
        <div><CustomChart title={'Efficiency in %'} serverKey={'Data1'} show={show} setShow={setShow} showMoreBtn={true} /></div>
        <div><CustomPieChart title={`Work Distribution by ${reduxDashboard?.json?.search?.vehType} (in  %)`} serverKey={'PieChartData'} /></div>
        <div><CustomPieChart title={`Running vs Stoppage of ${reduxDashboard?.json?.search?.vehType} (in  %)`} serverKey={'RunningVsStoppage'} /></div>
      </div>
      <CustomModal
        show={show} setShow={setShow} maxHeight={650}
        title={reduxDashboard?.json?.search?.vehType}
        FilterSwitch={<FilterSwitch selected={selected} setSelected={setSelected} />}
        data={
          <CustomTable
            TH={reduxDashboard?.TH}
            data={reduxDashboard?.list}
            paginationType={'local'}
            pagination={reduxPagination?.pagination}
          />}
      />
    </div>
  )
}
