import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PageTitle from '../../../Component/Common/Title/PageTitle'
import DeviceItem from './DeviceItem'

export default function DeviceAnalytics({selected, setSelected, setLoading}) {
    const reduxAnalyticsData = useSelector(state => state.AnalyticsReducer)
    
    useEffect(() => {

    }, [reduxAnalyticsData])

    return (
        <div>
            <div className='my-4'><PageTitle text={'Installed Device List'}/></div>
            {/* <div className='flex space-x-5'>{reduxAnalyticsData?.complete?.[0]?.roadId?.devices?.map((ele, index)=><div index={index} ><DeviceItem  ele={ele} selected={selected} setSelected={setSelected} setLoading={setLoading} /></div>)}</div> */}
            <div className='flex space-x-5'>{reduxAnalyticsData?.complete?.docs?.[0]?.devicesList?.map((ele, index)=><div index={index} ><DeviceItem  ele={ele} selected={selected} setSelected={setSelected} setLoading={setLoading} /></div>)}</div>
        </div>
    )
}
