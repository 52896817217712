export const SET_DAILY_ANALYTICS = 'SET_DAILY_ANALYTICS'
export const SET_DAILY_JSON_FOR_ANALYTICS = 'SET_DAILY_JSON_FOR_ANALYTICS'
export const SET_COMPLETE_ANALYTICS = 'SET_COMPLETE_ANALYTICS'
export const SET_COMPLETE_JSON_FOR_ANALYTICS = 'SET_COMPLETE_JSON_FOR_ANALYTICS'
export const SET_TRACK_DATA = 'SET_TRACK_DATA'
export const SET_VIEW = 'SET_VIEW'
export const SET_WORK = 'SET_WORK'

export const SET_AFTER_CALCULATION_DOC = 'SET_AFTER_CALCULATION_DOC'
export const SET_DATA_FOR_DELETE = 'SET_DATA_FOR_DELETE'
export const SET_SELECTED_DEVICE = 'SET_SELECTED_DEVICE'



export const setDailyAnalytics = (data) => ({ type: SET_DAILY_ANALYTICS, value: data });

export const setDailyJsonAnalytics = (data) => ({ type: SET_DAILY_JSON_FOR_ANALYTICS, value: data });

export const setCompleteAnalytics = (data) => ({ type: SET_COMPLETE_ANALYTICS, value: data });

export const setCompleteJsonAnalytics = (data) => ({ type: SET_COMPLETE_JSON_FOR_ANALYTICS, value: data });

export const setTrackData = (data) => ({ type: SET_TRACK_DATA, value: data });

export const setView = (data) => ({ type: SET_VIEW, value: data });
export const setWork = (data) => ({ type: SET_WORK, value: data });
export const setAfterCalculationDoc = (data) => ({ type: SET_AFTER_CALCULATION_DOC, value: data });
export const setDataForDelete = (data) => ({ type: SET_DATA_FOR_DELETE, value: data });
export const setSeelctedDevice = (data) => ({ type: SET_SELECTED_DEVICE, value: data });


