import React, { useState } from 'react'
import { CompileTrackData } from '../../WebView/Analytics/Complete/Details_old/DetailsPromiss';
import { HitApi } from '../../Store/Action/CommonApi/ApiAction';
import { GetTracks } from '../../Constant/Api/Constant';
import { setTrackData } from '../../Store/Action/Analytics/AnalyticsAction';
import { useDispatch, useSelector } from 'react-redux';
import { setGeoJson, setMyMapGeoJson, setSelectedDate, setWorkTrack } from '../../Store/Action/Map/MapAction';
import _ from 'underscore';
import { DecompileGeoJson } from './GeoJsonPromiss';

export default function Item({ selected, setSelected, index, ele }) {
    const dispatch = useDispatch()
    const reduxMap = useSelector(state => state.MapReducer)
    const [loading, setLoading] = useState(false)
    const [item, setItem] = useState([])


    const handleClick = (index, ele) => {
        var current = reduxMap?.SelectedDate
        dispatch(setGeoJson(null))
        var isDatePresent = reduxMap?.SelectedDate?.findIndex((Obj) => Obj?.startTime === ele?.startTime)

        if(isDatePresent!==-1){
            current.splice(isDatePresent, 1)
            dispatch(setSelectedDate(current))
        }else{
            current.push(ele)
            dispatch(setSelectedDate(current))
        }

        if(reduxMap?.SelectedDate?.length>0){
            var _ids = reduxMap?.SelectedDate?.map((ele)=>ele?.report_id)
            handleTrckData(_ids)
        }else{
            // dispatch(setGeoJson({ "type": "FeatureCollection", features: [] }))
            dispatch(setGeoJson({ "type": "FeatureCollection", features: [] }))
        }

    }

    const handleTrckData = (ids) => {
        
        var trackJson = {
            page: 1, limit: 2, search: {
                "$or": ids?.map((ele)=>Object.assign({_id : ele}))
            }, populate: [], "select": ""
        }

        
        HitApi(trackJson, GetTracks).then((res) => {

            setLoading(false)
            if (res) {
                DecompileGeoJson(res).then((Result)=>{

                    if(Result){
                        dispatch(setGeoJson(Result))
                    }
                })
            }
        })
    }


    const Check = (ti) => {
        return reduxMap?.SelectedDate?.find((Obj) => Obj?.startTime === ele?.startTime)
    }

    return (
        <div className={`${Check(index) ? 'border-2 border-primary' : ''} card py-2 px-4 mx-2`} onClick={() => handleClick(index, ele)}>
            <label className={`font-bold  ${selected === index ? 'text-primary' : ''}`}>{ele?.startTime}
                <div className={`badge bg-success/10 text-success flex flex-col mt-1`}>
                    <span className=''>Rounds</span>
                    <span className=''>{ele?.Passes}</span>
                </div>
            </label>
        </div>
    )
}
