import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PageTitle from '../../../Component/Common/Title/PageTitle'
import Title from '../../../Component/Common/Text/Title'
import { InputClassDisabled } from '../../../Constant/Style/StyleConstant'
import Notice from '../../../Component/Common/Title/Notice'
import Item from './Item'

export default function Profile() {
    const dispatch = useDispatch()
    const reduxUser = useSelector(state => state.AuthReducer)
    let userType = reduxUser?.doc?.userType
    useEffect(() => {

    }, [])

    return (
        <div className=''>
            {
                reduxUser?.doc !== null ?
                    <div >
                        <div className='card my-3 p-5'>
                            <div>
                                <PageTitle text={'Profile'} />
                                <Notice text={'Contact admin for any changes'} />
                            </div>
                            <div className='grid grid-cols-4 gap-4 p-5'>
                                <div>
                                    <div><Title text={'Username'} important={false} /></div>
                                    <div><input className={InputClassDisabled} type='text' value={reduxUser?.doc.username} disabled /></div>
                                </div>
                                <div>
                                    <div><Title text={'Name'} important={false} /></div>
                                    <div><input className={InputClassDisabled} type='text' value={reduxUser?.doc.name} disabled /></div>
                                </div>
                                <div>
                                    <div><Title text={'Email'} important={false} /></div>
                                    <div><input className={InputClassDisabled} type='text' value={reduxUser?.doc.email} disabled /></div>
                                </div>
                                <div>
                                    <div><Title text={'Contact'} important={false} /></div>
                                    <div><input className={InputClassDisabled} type='text' value={reduxUser?.doc.contact} disabled /></div>
                                </div>
                                <div>
                                    <div><Title text={'User Type'} important={false} /></div>
                                    <div><input className={InputClassDisabled} type='text' value={reduxUser?.doc.userType} disabled /></div>
                                </div>
                                <div>
                                    <div><Title text={'Department'} important={false} /></div>
                                    <div><input className={InputClassDisabled} type='text' value={reduxUser?.doc.department_type} disabled /></div>
                                </div>
                                <div>
                                    <div><Title text={'State'} important={false} /></div>
                                    <div><input className={InputClassDisabled} type='text' value={reduxUser?.doc.stateName} disabled /></div>
                                </div>
                                <div></div>
                                {
                                    reduxUser?.doc?.zone ? <div>
                                        <div><Title text={'Zone Name'} important={false} /></div>
                                        <div><input className={InputClassDisabled} type='text' value={reduxUser?.doc.zone.name} disabled /></div>
                                    </div> : null
                                }
                                {
                                    reduxUser?.doc?.dpiu ? <div>
                                        <div><Title text={'DPIU Name'} important={false} /></div>
                                        <div><input className={InputClassDisabled} type='text' value={reduxUser?.doc.dpiu.name} disabled /></div>
                                    </div> : null
                                }
                                {
                                    reduxUser?.doc?.piu ? <div>
                                        <div><Title text={'PIU Name'} important={false} /></div>
                                        <div><input className={InputClassDisabled} type='text' value={reduxUser?.doc.piu.name} disabled /></div>
                                    </div> : null
                                }
                            </div>
                        </div>
                        <div className='card my-3 p-5'>
                            <div><PageTitle text={'District List'} /><Notice text={'Contact admin for any changes'} /></div>
                            <div className='grid grid-cols-1 gap-4 p-5'>
                                <div>
                                    <div className='flex flex-wrap'>{reduxUser?.doc[userType] ? reduxUser?.doc[userType].value.map((element, index) =><div key={index} className='card'> <Item  element={element}/></div>) : null}</div>
                                </div>
                            </div>
                        </div>
                        {
                            userType === 'piu' ? <div className='card my-3 p-5'>
                                <div><PageTitle text={'Assigned Package'} /><Notice text={'Contact admin for any changes'} /></div>
                                <div className='grid grid-cols-1 gap-4 p-5'>
                                    <div>
                                        <div className='flex flex-wrap'>{reduxUser?.doc?.[userType] ? reduxUser?.doc?.[userType]?.packages.map((element, index) => <div key={index} className='card'> <Item element={element} serverKey={'Package_no'}/></div>) : null}</div>
                                    </div>
                                </div>
                            </div> : null
                        }
                    </div> : null
            }
        </div>
    )
}
