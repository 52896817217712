import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Item from './Item'

export default function Sidebar({ loading, setLoading, apiHit }) {
    const dispatch = useDispatch()
    const reduxAnalyticsData = useSelector(state => state.AnalyticsReducer)
    const reduxData = useSelector(state => state.AnalyticsReducer)
    const [selected, setSelected] = useState(null)
    const [count, setCount] = useState([])
    const [data, setData] = useState(null)
    var tempArr = []
    var hitCount = []

    // var count = 0
    useEffect(() => {
        if (data === null && reduxData?.complete?.docs?.[0]?.wt) {
            var allWorks = [...new Set(reduxData?.complete?.docs?.[0]?.ommas?.map(x => x.ACTIVITY_NAME))]
        }
    }, [count, selected, reduxAnalyticsData])

    var execute = 0

    const FindSelected = (tempArr) => {
        reduxData?.complete?.docs?.[0]?.wt?.forEach(element => {
            var index = tempArr.indexOf(element?.title)
            if (selected === null && index !== -1 && execute === 0) {
                setSelected(tempArr[index])
                execute = 1
                var json = reduxData?.dailyJson
                Object.assign(json.search, { workName: tempArr[index] })
                if (apiHit) { apiHit() }
            }
        });
    }

    return (
        <div className='mt-2 flex flex-col justify-between' style={{ height: '50vh' }}>
            {/* {reduxData?.complete?.[0]?.WorkStage?.map((ele, index) => <Item length={reduxData?.complete?.[0]?.WorkStage?.length} key={index} count={count} FindSelected={FindSelected} hitCount={hitCount} setCount={setCount} ele={ele} tempArr={tempArr} data={reduxData?.complete?.[0]} apiHit={apiHit} selected={selected} setSelected={setSelected} />)} */}
            {reduxData?.complete?.docs?.[0]?.wt?.map((ele, index) => <Item length={reduxData?.complete?.docs?.[0]?.wt?.length} key={index} count={count} FindSelected={FindSelected} hitCount={hitCount} setCount={setCount} ele={ele} tempArr={tempArr} data={reduxData?.complete?.[0]} apiHit={apiHit} selected={selected} setSelected={setSelected} />)}
        </div>
    )
}
