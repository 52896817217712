import React from 'react'
import PageTitle from '../../Component/Common/Title/PageTitle'
import Analytics from './Analytics/Analytics'

export default function Dashbaord() {
  return (
    <div className="">
      <div className='my-4'>
        <div className='my-4'><PageTitle text={'Dashboard'} /></div>
        <Analytics/>
      </div>
    </div>
  )
}
