import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { HitApi } from '../../../../Store/Action/CommonApi/ApiAction'
import { getZone } from '../../../../Constant/Api/Constant'
import CustomTable from '../../../../Component/Table/CustomTable'
import { CompileZoneData } from '../ZonePromiss'
import { zone } from '../../../../Store/Action/SuperAdmin/Zones/ZoneAction'
import LoadingTableData from '../../../../Component/Common/Loading/LoadingTableData'
import CustomTh from '../CustomTH'
import { setPagination } from '../../../../Store/Action/Pagination/Pagination'
import { TH } from './TH'
import { ChakraProvider } from '@chakra-ui/react'
import { ConfirmDialogProvider } from '../../../../Component/Dialog/ConfirmDialog'

export default function Zone() {
    const dispatch = useDispatch()
    const reduxZone = useSelector(state => state.ZoneReducer)
    const reduxPagination = useSelector(state => state.PaginationReducer)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (reduxZone?.zone === null) {
            loadData('init')
        }
    }, [])

    const loadData = (type) => {
        var json = reduxZone?.json
        if (type === 'init') {
            Object.assign(json, { page: 1, limit: reduxPagination?.pagination?.limit })
            Object.assign(json.search, { userType: 'zone' } )
            
        }
        Object.assign(json, { page: reduxPagination?.pagination?.page, limit: reduxPagination?.pagination?.limit })
        setLoading(true)
        HitApi(json, getZone).then((res) => {
            if (res?.doc?.docs) {
                CompileZoneData(res?.doc, reduxPagination?.pagination, 'DPIU').then((CompileData) => {
                    if (CompileData) {
                        var tp = reduxPagination?.pagination
                        tp.totalRecords = CompileData?.totalDocs
                        tp.totalPages = CompileData?.totalPages
                        dispatch(setPagination(tp))
                        dispatch(zone(CompileData))
                        setLoading(false)
                    }
                })
            }
        })
    }

    const handleClick = (ele) => {

        window.location.href = `/sup/zone/${ele?._id}`
    }

    return (
        <div>
            <CustomTable
                TH={TH}
                data={reduxZone?.zone?.docs}
                header={false}
                loading={loading}
                pagination={reduxPagination?.pagination}
                paginationType={'server'}
                apiHit={loadData}
                handleClick={handleClick}
                json={reduxZone?.json}
                fileName={('roads')}
            />

        </div>
    )
}
