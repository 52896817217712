import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { HitApi } from '../../../Store/Action/CommonApi/ApiAction'
import { getCompleteRrdaReportsLite } from '../../../Constant/Api/Constant'
import { CompileCompleteAnalytics } from './CompleteAnalyticsPromiss'
import { setCompleteAnalytics } from '../../../Store/Action/Analytics/AnalyticsAction'
import { setPagination } from '../../../Store/Action/Pagination/Pagination'
import PageTitle from '../../../Component/Common/Title/PageTitle'
import CustomTable from '../../../Component/Table/CustomTable'
import TableTH from './TableTH'
import { useHistory } from "react-router-dom";

import { GenerateFileName } from '../../../Utils/Utils'

export default function CompleteAnalytics() {
  let history = useHistory();
  const dispatch = useDispatch()
  const reduxData = useSelector(state => state.AnalyticsReducer)
  const reduxUser = useSelector(state => state.AuthReducer)
  const reduxPagination = useSelector(state => state.PaginationReducer)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (reduxUser?.doc && reduxData?.complete === null) {
      loadData('init')
    }
  }, [])

  const loadData = (type) => {
    var json = reduxData?.completeJson
    if (type === 'init') {
      Object.assign(json.search, {
        state: reduxUser?.doc?.stateName,
        // packageId : 'AS18077'
        // packageId : 'PB18105'
        // packageId : 'JK053007'
        // packageId : 'TN15205'
        // packageId : 'TN3402'
        // packageId : 'UP01114'
      })
    }
    Object.assign(json, {
      page: reduxPagination?.pagination?.page,
      limit: reduxPagination?.pagination?.limit,
    })
    setLoading(true)



    HitApi(json, getCompleteRrdaReportsLite).then((res) => {
      if (res?.doc?.docs) {
        CompileCompleteAnalytics(res?.doc, reduxPagination?.pagination).then((CompledData) => {
          if (CompledData) {
            var tp = reduxPagination?.pagination
            tp.totalRecords = CompledData?.totalDocs
            tp.totalPages = CompledData?.totalPages
            dispatch(setPagination(tp))
            dispatch(setCompleteAnalytics(CompledData))
            setLoading(false)
          }
        })
      }
    })
  }

  const handleClick = (element) => {
    window.location.href = '/analytics/complete/' + element?.packageNo
    // history.push('/analytics/complete/' + element?.packageNo)
  }

  return (
    <div>
      <div><PageTitle text={'Compaction Report'} /></div>
      <div className={'mt-5'}>
        <CustomTable
          header={true}
          loading={loading}
          TH={TableTH}
          data={reduxData?.complete?.docs}
          pagination={reduxPagination?.pagination}
          paginationType={'server'}
          apiHit={loadData}
          handleClick={handleClick}
          fileName={('Complete Analytics')}
          json={reduxData?.completeJson}
          districtKey={'district'}
          packageKey={'packageId'}
          defaultDropdown={true}
        />
      </div>
    </div>
  )
}
