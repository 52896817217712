export default [
   {
      _id: 0,
      StateName: 'Punjab',
      title: 'PNRRDA'
   }, {
      _id: 1,
      StateName: 'Assam',
      title: 'ARRDA'
   }, {
      _id: 2,
      StateName: 'Tamil Nadu',
      title: 'TNRRDA'
   }, {
      _id: 3,
      StateName: 'Jammu And Kashmir',
      title: 'JKRRDA'
   },{
      _id: 4,
      StateName: 'Uttrakhand',
      title: 'UKRRDA'
   },{
      _id: 4,
      StateName: 'Uttar Pradesh',
      title: 'UPRRDA'
   },{
      _id: 4,
      StateName: 'Himachal Pradesh',
      title: 'HPRRDA'
   },{
      _id: 4,
      StateName: 'Bihar',
      title: 'BRRDA'
   },
   {
      _id: 5,
      StateName: undefined,
      title: 'Super Admin Access'
   }
]