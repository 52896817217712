import SingleColumn from "../../../WebView/Dashboard/Analytics/Views/SingleColumn";
import ThreeColumn from "../../../WebView/Dashboard/Analytics/Views/ThreeColumn";
import TwoColumn from "../../../WebView/Dashboard/Analytics/Views/TwoColumn";
import { SET_DASHBOARD_CHART_DATA, SET_DASHBOARD_TH, SET_DASHBOARD_MAIN_DATA, SET_DASHBOARD_SETTING, SET_DASHBOARD_DATE_ARR, SET_DASHBOARD_JSON, SET_DASHBOARD_LIST_DATA, SET_DASHBOARD_MMI_DEVICES, SET_DASHBOARD_SERVER_DEVICES } from "../../Action/Dashboard/DashboardAction";
import DeviceListData from "./DeviceListData";

const initialState = {
    MainData: null,
    DeviceList: DeviceListData,
    MMIDevices: null,
    ServerDevices: null,
    chart: null,
    list: null,
    DateArr: null,
    json: { page: 1, limit: 5000, search: { vehType: 'Static Roller', "trips.round": { "$gt": 0 } }, 
    populate: [{ "path": "roadId", 'populate': {"path":"ommas"}}, { "path": "report_id_lite", select:'' }],
    select: '', type: true, apiCall: 'init' },
    Selected: {
        ViewSetting: { _id: 0, title: '1 Column', className: ' grid-cols-1 ', status: true }
    },
    Setting: {
        ViewSettings: [
            { _id: 0, title: '1 Column', file: <SingleColumn />, status: false },
            { _id: 0, title: '2 Column', file: <TwoColumn />, status: false },
            { _id: 0, title: '3 Column', file: <ThreeColumn />, status: true },
        ]
    },
    TH: [
        { id: 0, title: 'Sr. No', serverKey: 'index', className: '', isPdf: true, status: true },
        { id: 1, title: 'State', serverKey: 'state', className: '', isPdf: true, status: true },
        { id: 1, title: 'District-Name', serverKey: 'district', className: '', isPdf: true, status: true },
        { id: 2, title: 'Package-No', serverKey: 'packageId', className: '', isPdf: true, status: true },
        { id: 2, title: 'PIU-Name', serverKey: 'piuName', className: '', isPdf: true, status: true },
        { id: 2, title: 'Contractor-Name', serverKey: 'contractorName', className: '', isPdf: true, status: true },
        { id: 2, title: 'Road-Code', serverKey: 'roadCode', className: '', isPdf: true, status: true },
        { id: 2, title: 'Road-Name', serverKey: 'roadName', className: 'text-left', isPdf: true, status: true },
        { id: 2, title: 'Road-Length', serverKey: 'roadLength', className: '', isPdf: true, status: true },
        { id: 6, title: 'Active-Vehicles', serverKey: 'devices', className: '', isPdf: true, status: false },
        { id: 6, title: 'Vehicle-Type', serverKey: 'vehType', className: '', isPdf: true, status: true },
        { id: 9, title: 'Activity-Name', serverKey: 'workName', className: '', overrideClass: '', isPdf: true, status: false },
        { id: 9, title: 'Execution-date (GPS)', serverKey: 'exeDate', className: '', overrideClass: '', isPdf: true, status: false },
        { id: 9, title: 'Total-Rounds', serverKey: 'round', className: '', overrideClass: '', isPdf: true, status: false },
        { id: 9, title: 'Distance Covered-By Vehicle (in M)', serverKey: 'distance', className: '', overrideClass: '', suffix: 'm', isPdf: true, status: false },
        { id: 9, title: 'Efficiency-of Devices', serverKey: 'efficency', className: '', overrideClass: '', suffix: '%', isPdf: true, status: false },
        { id: 9, title: 'Running (in %)', serverKey: 'running', className: '', overrideClass: '', suffix: '%', isPdf: true, status: false },
        { id: 9, title: 'Stoppage (in %)', serverKey: 'stopage', className: '', overrideClass: '', suffix: '%', isPdf: true, status: false },
    ],
    timestamp: Date.now(),
}

const DashboardReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_DASHBOARD_MMI_DEVICES:
            return ({ ...state, MMIDevices: action.value, timestamp: Date.now() })
        case SET_DASHBOARD_SERVER_DEVICES:
            return ({ ...state, ServerDevices: action.value, timestamp: Date.now() })
        case SET_DASHBOARD_CHART_DATA:
            return ({ ...state, chart: action.value, timestamp: Date.now() })
        case SET_DASHBOARD_LIST_DATA:
            return ({ ...state, list: action.value, timestamp: Date.now() })
        case SET_DASHBOARD_JSON:
            return ({ ...state, json: action.value, timestamp: Date.now() })
        case SET_DASHBOARD_DATE_ARR:
            return ({ ...state, DateArr: action.value, timestamp: Date.now() })
        case SET_DASHBOARD_SETTING:
            return ({ ...state, Setting: action.value, timestamp: Date.now() })
        case SET_DASHBOARD_MAIN_DATA:
            return ({ ...state, MainData: action.value, timestamp: Date.now() })
        case SET_DASHBOARD_TH:
            return ({ ...state, TH: action.value, timestamp: Date.now() })
        default:
            return state;
    }
}

export default DashboardReducer;