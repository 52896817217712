import React, { useState } from 'react'

export default function Details({Data}) {

    const [ishover, sethover] = useState(25);

    const MouseOver = () => {
        sethover(Data?.address?.length)
    }
    const MouseOut = () => {
        sethover(25)
    }

    return (
        <div className='border w-full'>
            {
                Data ? <div style={{ backgroundColor: '#fff', padding: 10 }}>
                    <div className='flex justify-between text-center items-center'>
                       <label className='w-full flex flex-col px-2 border-r border-gray-300 border-dashed'>
                            <span style={{ fontSize: 12 }} className='font-bold text-primary tracking-wide'>District Name</span>
                            <span style={{ fontSize: 16 }} className='font-bold'>{Data?.accname}</span>
                        </label>
                         <label className='w-full flex flex-col px-2 border-r border-gray-300 border-dashed'>
                            <span style={{ fontSize: 12 }} className='font-bold text-primary tracking-wide'>Package No</span>
                            <span style={{ fontSize: 16 }} className='font-bold'>{Data?.vehnum}</span>
                        </label>
                        <label className='w-full flex flex-col px-2 border-r border-gray-300 border-dashed' data-tooltip="Default">
                            <span style={{ fontSize: 12 }} className='font-bold text-primary tracking-wide'>Road Name</span>
                            <span style={{ fontSize: 16, width: 300 }} className='font-bold' onMouseOver={MouseOver} onMouseOut={MouseOut}>{Data?.address?.substring(0, ishover) + (ishover === 25 ? '...' : '')}</span>
                        </label>
                       <label className='w-full flex flex-col px-2 border-r border-gray-300 border-dashed'>
                            <span style={{ fontSize: 12 }} className='font-bold text-primary tracking-wide'>IMEI</span>
                            <span style={{ fontSize: 16 }} className='font-bold'>{Data?.imei || '---'}</span>
                        </label>
                         <label className='w-full flex flex-col px-2 border-r border-gray-300 border-dashed'>
                            <span style={{ fontSize: 12 }} className='font-bold text-primary tracking-wide'>Vehicle Type</span>
                            <span style={{ fontSize: 16 }} className='font-bold'>{Data?.vehtype || '---'}</span>
                        </label>
                        <label className='w-full flex flex-col px-2 border-r border-gray-300 border-dashed'>
                            <span style={{ fontSize: 12 }} className='font-bold text-primary tracking-wide'>Department</span>
                            <span style={{ fontSize: 16 }} className='font-bold'>{Data?.department || '---'}</span>
                        </label>
                        <label className='w-full flex flex-col px-2  border-gray-300 border-dashed'>
                            <span style={{ fontSize: 12 }} className='font-bold text-primary tracking-wide'>Current Status</span>
                            <span style={{ fontSize: 16 }} className='font-bold'>{Data?.status || '---'}</span>
                        </label> 

                    </div>
                </div> : null
            }

        </div>
    )
}
