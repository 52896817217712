import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { legacy_createStore as createStore, combineReducers, applyMiddleware } from 'redux'
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";
import thunk from 'redux-thunk';
import AuthReducer from './Store/Reducer/AuthReducer';
import ApiReducer from './Store/Reducer/CommonApi/ApiReducer';
import SidebarReducer from './Store/Reducer/SidebarReducer';
import PaginationReducer from './Store/Reducer/Pagination/PaginationReducer';
import RoadReducer from './Store/Reducer/Roads/RoadReducer';
import AnalyticsReducer from './Store/Reducer/Analytics/AnalyticsReducer';
import ReportsReducer from './Store/Reducer/Reports/ReportsReducer';
import LiveAlertReducer from './Store/Reducer/LiveAlerts/LiveAlertReducer';
import FilterReducer from './Store/Reducer/Filter/FilterReducer';
import MapReducer from './Store/Reducer/Map/MapReducer';
import TrackAndPlayDatePickerReducer from './Store/Reducer/TrackAndPlay/TrackAndPlayReduce';
import ErrorReducer from './Store/Reducer/Error/ErrorReducer';
import UserReducer from './Store/Reducer/SuperAdmin/Users/UserReducer';
import DashboardReducer from './Store/Reducer/Dashboard/DashboardReducer';
import ServerDevicesReducer from './Store/Reducer/CommonApi/ServerDevicesReducer';
import ZoneReducer from './Store/Reducer/SuperAdmin/Zones/ZoneReducer';
import DefaultHeaderDropdownDataReducer from './Store/Reducer/CommonApi/DefaultHeaderDropdownDataReducer';

const rootReducer = combineReducers({
  AuthReducer: AuthReducer,
  ApiReducer: ApiReducer,
  SidebarReducer: SidebarReducer,
  PaginationReducer: PaginationReducer,
  RoadReducer: RoadReducer,
  AnalyticsReducer: AnalyticsReducer,
  ReportsReducer: ReportsReducer,
  LiveAlertReducer: LiveAlertReducer,
  FilterReducer: FilterReducer,
  MapReducer: MapReducer,
  TrackAndPlayDatePickerReducer: TrackAndPlayDatePickerReducer,
  ErrorReducer: ErrorReducer,
  UserReducer: UserReducer,
  DashboardReducer: DashboardReducer,
  ServerDevicesReducer: ServerDevicesReducer,
  ZoneReducer: ZoneReducer,
  DefaultHeaderDropdownDataReducer : DefaultHeaderDropdownDataReducer,
})

const store = createStore(rootReducer, applyMiddleware(thunk))

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

// root.render(
//   <Provider store={store}>
//     <BrowserRouter>
//       <App/>
//     </BrowserRouter>
//   </Provider>
// );


ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>, document.getElementById('root')
);