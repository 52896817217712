import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSigleRoad } from '../../../Store/Action/Roads/RoadAction'
import { GetRoads } from '../../../Constant/Api/Constant'
import { HitApi } from '../../../Store/Action/CommonApi/ApiAction'
import { CompileRoadData } from '../CompileRoadDataPromis'
import { setPagination } from '../../../Store/Action/Pagination/Pagination'
import Details from './Child/Details/Details'
import { setMapData, setRoute } from '../../../Store/Action/Map/MapAction'
import MapMyIndiaLocalToken from '../../../Component/Map/MapMyIndiaLocalToken'
import CustomGoogleMap from '../../../Component/GoogleMap/CustomGoogleMap'

export default function RoadDetails() {
    const dispatch = useDispatch()
    const reduxRoads = useSelector(state => state.RoadReducer)
    const reduxUser = useSelector(state => state.AuthReducer)
    const reduxPagination = useSelector(state => state.PaginationReducer)
    const [localData, setLocalData] = useState(null)
    const [showExitPrompt, setShowExitPrompt] = useState(false);

    useEffect(() => {
        if (reduxRoads?.single === null && localData === null) {
            var _id = window.location.pathname.split('/')?.[3]
            loadData('init', _id)
        }
    }, [reduxRoads])


    const loadData = (type, _id) => {
        var json = reduxRoads?.json
        if (type === 'init') {
            json.page = 1
            json.limit = 1
            json.populate = [
                { "path": "mapData", "select": "" },
                { "path": "ommas", "select": "" },
                { "path": "devices", "select": "" },
            ]
            Object.assign(json?.search, {
                _id: _id,
                StateName: reduxUser?.doc?.stateName
            })
        }
        HitApi(json, GetRoads).then((res) => {
            if (res?.doc?.docs) {

                CompileRoadData(res?.doc, reduxPagination?.pagination).then((CompledData) => {
                    if (CompledData) {

                        var tp = reduxPagination?.pagination
                        tp.totalRecords = CompledData?.totalDocs
                        tp.totalPages = CompledData?.totalPages
                        dispatch(setPagination(tp))
                        dispatch(setSigleRoad(CompledData))
                        dispatch(setMapData(CompledData?.docs?.[0]?.mapData))
                        dispatch(setRoute(CompledData?.docs?.[0]?.mapData))
                    }
                })
            }
        })
    }
    return (
        <div className='card p-5'>

            <div className='grid grid-cols-2 gap-4'>
                <div><Details /></div>
                <div>
                    {/* <div><MapMyIndiaLocalToken from={'roads'} /></div> */}
                    <div> <CustomGoogleMap zoom={13} from={'roads'} /> </div>
                </div>
            </div>
        </div>
    )
}
