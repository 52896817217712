import React, { useState } from 'react'

export default function MarkerDetails({Data, start}) {

    return (
        <div className='border w-full'>
            {
                Data && start ? <div style={{ backgroundColor: '#fff', padding: 10 }}>
                    <div className='flex justify-between text-center items-center'>
                       <label className='w-full flex flex-col px-2 border-r border-gray-300 border-dashed'>
                            <span style={{ fontSize: 12 }} className='font-bold text-primary tracking-wide'>Address</span>
                            <span style={{ fontSize: 16 }} className='font-bold'>{Data?.address}</span>
                        </label>
                        <label className='w-full flex flex-col px-2 border-r border-gray-300 border-dashed'>
                            <span style={{ fontSize: 12 }} className='font-bold text-primary tracking-wide'>Time</span>
                            <span style={{ fontSize: 16 }} className='font-bold'>{Data?.ts}</span>
                        </label>
                         <label className='w-full flex flex-col px-2 border-r border-gray-300 border-dashed'>
                            <span style={{ fontSize: 12 }} className='font-bold text-primary tracking-wide'>Status</span>
                            <span style={{ fontSize: 16 }} className='font-bold'>{Data?.status}</span>
                        </label>
                        <label className='w-full flex flex-col px-2 border-r border-gray-300 border-dashed' data-tooltip="Default">
                            <span style={{ fontSize: 12 }} className='font-bold text-primary tracking-wide'>lat</span>
                            <span style={{ fontSize: 16, width: 300 }} className='font-bold'>{Data?.lat}</span>
                        </label>
                       <label className='w-full flex flex-col px-2 '>
                            <span style={{ fontSize: 12 }} className='font-bold text-primary tracking-wide'>lng</span>
                            <span style={{ fontSize: 16 }} className='font-bold'>{Data?.lng || '---'}</span>
                        </label>
                    </div>
                </div> : null
            }
        </div>
    )
}
