import { SET_LIVE_VIEW_DATA } from "../../Action/Map/LiveView/LiveViewAction";
import { SET_MAP_DATA, SET_MY_MAP_GEO_JSON, SET_SELECTED_DATE, SET_GEO_JSON, SET_MAP_TOKEN, SET_MAP, SET_MARKER, SET_POLYLINE, SET_ROUTE, SET_WORK_TRACK, SET_LOCATION_DATA } from "../../Action/Map/MapAction";

const initialState = {
    doc: null,
    map: null,
    location: null,
    route: null,
    polyline: [],
    marker: null,
    myMapData: { "type": "FeatureCollection", features: [] },
    geoJson: { "type": "FeatureCollection", features: [] },
    workTrack: [],
    SelectedDate: [],
    LiveTrack: null,
    token: null,
    timestamp: Date.now()
}

const MapReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_MAP_DATA:
            return ({ ...state, doc: action.value, timestamp: Date.now() })
        case SET_LOCATION_DATA:
            return ({ ...state, location: action.value, timestamp: Date.now() })
        case SET_MAP:
            return ({ ...state, map: action.value, timestamp: Date.now() })
        case SET_ROUTE:
            return ({ ...state, route: action.value, timestamp: Date.now() })
        case SET_POLYLINE:
            return ({ ...state, polyline: action.value, timestamp: Date.now() })
        case SET_MARKER:
            return ({ ...state, marker: action.value, timestamp: Date.now() })
        case SET_WORK_TRACK:
            return ({ ...state, workTrack: action.value, timestamp: Date.now() })
        case SET_MY_MAP_GEO_JSON:
            return ({ ...state, myMapData: action.value, timestamp: Date.now() })
        case SET_GEO_JSON:
            return ({ ...state, geoJson: action.value, timestamp: Date.now() })
        case SET_MAP_TOKEN:
            return ({ ...state, token: action.value, timestamp: Date.now() })
        case SET_SELECTED_DATE:
            return ({ ...state, SelectedDate: action.value, timestamp: Date.now() })
        case SET_LIVE_VIEW_DATA:
            return ({ ...state, LiveTrack: action.value, timestamp: Date.now() })

        default:
            return state;
    }
}

export default MapReducer;