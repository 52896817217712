export const LoginInput = 'form-input peer w-full rounded-lg bg-slate-150 px-3 py-2 pl-9 ring-primary/50 placeholder:text-slate-400 hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:ring-accent/50 dark:placeholder:text-navy-300 dark:hover:bg-navy-900 dark:focus:bg-navy-900'

export const SuccessBg = 'badge bg-success/10 text-success'
export const ErrorBg = 'badge bg-error/10 text-error'


export const ErrorBadgeClass = 'badge bg-error/10 text-error '
export const ErrorBadgeWithUppercaseClass = 'badge bg-error/10 text-error uppercase font-semibold'
export const SuccessBadge = 'badge bg-success/10 text-success uppercase font-semibold'
export const InputClass = 'form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2'
export const InputClassDisabled = 'form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary disabled:pointer-events-none disabled:select-none disabled:border disabled:bg-zinc-100 disabled:border-slate-400/70 dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent dark:disabled:bg-navy-600'

export const ErrorClass = 'text-small tracking-wide text-error dark:text-error'


export const ChildTHClass = 'p-2.5 text-center bg-success/10 text-success tracking-normal text-slate-500 dark:bg-navy-800 dark:text-navy-100 lg:px-5 '

export const ActiveClass = 'bg-slate-500 text-white'

export const HoverNavClass = 'hover:bg-slate-500 hover:text-white '

export const Colors = {
    good: '#50B432',
    fair: '#ff914d',
    poor: '#ED561B',
    Earthwork: '#ffcc00',
    Subgrade: '#009900',
    'Granular Sub Base': '#00cc99',
    "WBM Grading 2": '#0088cc',
    "WBM Grading 3": '#006699',
    "Wet Mix Macadam" : '#666699',
    "Bituminous Macadam" : '#993399',
    "Surface Course (OGPC/MSS/CC)" : '#990033'
}