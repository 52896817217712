export const IMZTH = [
    { id: 0, title: 'Sr. No', serverKey: 'index', className: '', isPdf: true, status: true },
    { id: 2, title: 'Action', serverKey: 'Action', className: '', isPdf: true, status: true },
    { id: 1, title: 'District-Name', serverKey: 'accname', className: '', isPdf: true, status: true },
    { id: 2, title: 'Package-No', serverKey: 'vehnum', className: '', isPdf: true, status: true },
    { id: 2, title: 'Vehicle-Type', serverKey: 'vehtype', className: '', isPdf: true, status: true },
    { id: 2, title: 'Address', serverKey: 'address', className: 'text-left max-w-md', isPdf: true, status: true },
    { id: 2, title: 'IMEI', serverKey: 'imei', className: '', isPdf: true, status: true },
    { id: 2, title: 'Department', serverKey: 'department', className: '', isPdf: true, status: true },
    // { id: 2, title: 'differenceInTime', serverKey: 'differenceInTime', className: '', isPdf: true, status: true },
    { id: 2, title: 'Current-Status', serverKey: 'currentStatus', className: '', isPdf: true, status: true },
    { id: 2, title: 'Last Polling-Date & Time', serverKey: 'lastActive', className: '', isPdf: true, status: true },
]
