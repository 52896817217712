export const SET_MAP = 'SET_MAP'
export const SET_MAP_DATA = 'SET_MAP_DATA'
export const SET_LOCATION_DATA = 'SET_LOCATION_DATA'
export const SET_ROUTE = 'SET_ROUTE'
export const SET_POLYLINE = 'SET_POLYLINE'
export const SET_MARKER = 'SET_MARKER'
export const SET_WORK_TRACK = 'SET_WORK_TRACK'
export const SET_GEO_JSON = 'SET_GEO_JSON'
export const SET_MY_MAP_GEO_JSON = 'SET_MY_MAP_GEO_JSON'
export const SET_MAP_TOKEN = 'SET_MAP_TOKEN'
export const SET_SELECTED_DATE = 'SET_SELECTED_DATE'


export const setMapData = (data) => ({ type: SET_MAP_DATA, value: data });
export const setLocaitonData = (data) => ({ type: SET_LOCATION_DATA, value: data });
export const setMap = (data) => ({ type: SET_MAP, value: data });
export const setRoute = (data) => ({ type: SET_ROUTE, value: data });
export const setPolyline = (data) => ({ type: SET_POLYLINE, value: data });
export const setMarker = (data) => ({ type: SET_MARKER, value: data });
export const setWorkTrack = (data) => ({ type: SET_WORK_TRACK, value: data });
export const setGeoJson = (data) => ({ type: SET_GEO_JSON, value: data });
export const setMyMapGeoJson = (data) => ({ type: SET_MY_MAP_GEO_JSON, value: data });
export const setMapToken = (data) => ({ type: SET_MAP_TOKEN, value: data });
export const setSelectedDate = (data) => ({ type: SET_SELECTED_DATE, value: data });
