import md5 from "md5";

export const Imz_Login = [
    {
        state:'Uttar Pradesh',
        username: 'uprrdaapi2',
        pin: md5('uprrdaapi21234567890'),
        accid: '3215'
    },
    {
        state:'Bihar',
        username: 'brrdaapi',
        pin: md5('brrdaapi1234567890'),
        accid: '3466'
    },
    {
        state:'Himachal Pradesh',
        username: 'hpgsdaapi',
        pin: '9b12bac89495eb8b63585cccc3e575fe',
        accid: '3468'
    },{
        state:'Jharkhand',
        username: 'jsrrdaapi',
        pin: 'e063e79f2bf9a112cbe53fdc1227df48',
        accid: '2872'
    }
]