import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Form from './form/AddUserForm'

export default function AddUser() {
    const dispatch = useDispatch()
    const reduxUser = useSelector(state => state.AuthReducer)

    useEffect(() => {

    }, [])

    return (
        <div>
            <Form/>
        </div>
    )
}
