import { GetPageCount, GetTimestampBasedOnStartEnd } from "../../Utils/Utils";

export const CompileLiveAlerts = (data, pagination) => {

    const MyPromiss = new Promise((resolve, reject) => {
        data?.map((ele, index)=>Object.assign(ele,{index:GetPageCount(pagination?.limit, pagination?.page, index)}))
        resolve(data)
    })

    return MyPromiss;
}

export const CompileDeviceIds = (data) => {
    const MyPromiss = new Promise((resolve, reject) => {
        var tp = []
        data?.map((ele) => {ele?.devices?.map((innerEle)=>tp.push(parseInt(innerEle?.id)))})
        var st = GetTimestampBasedOnStartEnd(null,'start')
        var et = GetTimestampBasedOnStartEnd(null,'end')
        var js = `startTime=${st}&endTime=${et}&deviceId=${tp.toString()}`
        
        resolve(js)
    })

    return MyPromiss;
}

