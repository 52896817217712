import React, { useEffect, useState } from 'react'
import SearchableSelect from '../Select/SearchableSelect'
import { useDispatch, useSelector } from 'react-redux'
import { CompileDefaultData, CompileDropdownData } from './Promiss/CustomHeaderPromiss'
import { setCommonDistrictData, setCommonPackageData } from '../../Store/Action/CommonApi/DefaultHeaderDropdownDataAction.js'
import { GetRoads } from '../../Constant/Api/Constant'
import { HitApi } from '../../Store/Action/CommonApi/ApiAction'
import { setFilter } from '../../Store/Action/Filter/FilterAction'
import FilterConstant from '../../Constant/Filter/FilterConstant'
import { setPagination } from '../../Store/Action/Pagination/Pagination'
import Download from './Download/Download.js'

export default function CustomHeader({ defaultDropdown, apiHit, json, districtKey, packageKey, screen, TH, fileName, data }) {

    const dispatch = useDispatch()
    const reduxCommonData = useSelector(state => state.DefaultHeaderDropdownDataReducer)
    const reduxUser = useSelector(state => state.AuthReducer)
    const reduxFilter = useSelector(state => state.FilterReducer)
    const reduxPagination = useSelector(state => state.PaginationReducer)

    const [loading, setLoading] = useState()

    let td = FilterConstant.find(Obj => Obj.screen === screen)


    useEffect(() => {
        loadDistrictData()
    }, [])

    const loadDistrictData = () => {
        CompileDefaultData(reduxUser?.doc).then((res) => {
            if (res) {
                dispatch(setCommonDistrictData(res))
            }
        })
    }

    const handleChange = (e, serverKey, searchBy, load) => {
        var value = e?.value
        if (value !== 'All' && value !== '') {
            Object.assign(json.search, { [serverKey]: value })
        } else {
            delete json.search[serverKey]
        }
        if (apiHit) {
            apiHit()
            if(load){
                handleDataLoad(value, 'districtName', searchBy)
            }
        }
    }

    const handleDataLoad = (value, serverKey, searchBy) => {
        var json = {
            page: 1,
            limit: 5000,
            search: {
                [serverKey]: value
            },
            select: 'Package_no'
        }
        setLoading(true)
        HitApi(json, GetRoads).then((res) => {
            if (res?.doc?.docs) {
                CompileDropdownData(res?.doc?.docs, searchBy).then((result) => {
                    if (result) {
                        var json = reduxFilter?.doc
                        Object.assign(json, { [searchBy]: result })
                        dispatch(setFilter(json))
                        dispatch(setCommonPackageData(result))
                    }
                    setLoading(false)
                })
            }
        })
    }

    const handleOnChange = (e, element) => {
        let value = e?.text

        if (value) {
            let childelement = element.value.find(Obj => Obj.text === value)
            var pagination = reduxPagination?.pagination
            pagination.page = 1
            dispatch(setPagination(pagination))
            if (childelement.value !== 'ALL') {
                var tp = { [element.title]: childelement.value }
                Object.assign(json.search, tp)
            } else {
                delete json.search[element.title]
            }
        }
        else {
            delete json.search[element.title]
        }
        apiHit && apiHit();
    }

    return (
        <div className='p-5'>
            <div className='grid grid-cols-4'>
                <div className='col-span-3'>
                    <div className='grid grid-cols-5 gap-4'>
                        {
                            defaultDropdown ? <>
                                <div><SearchableSelect title={'District'} defaultText={'Select District...'} data={reduxCommonData?.districtData} onChange={(e) => handleChange(e, districtKey?districtKey:'districtName', 'Package_no', true)} /></div>
                                <div><SearchableSelect title={'Package No.'} defaultText={'Select Package...'} data={reduxCommonData?.packageData} onChange={(e) => handleChange(e, packageKey?packageKey:'Package_no', 'Road_Code')} /></div>
                            </>
                                : null
                        }
                        {td?.filterType?.map((element, i) => (
                            <div key={i} className='max-w-lg'>
                                <label className="block">
                                    <h2 className="font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100 lg:text-base">{element.text}</h2>
                                    
                                    <SearchableSelect data={element.value} onChange={(e) => handleOnChange(e, element)}  />
                                </label>
                            </div>
                        )) || null}
                    </div>
                </div>
                <div className='col-span-1'>
                    <Download TH={TH} data={data} screen={screen} fileName={fileName} />
                </div>
            </div>

        </div>
    )
}
