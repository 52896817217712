import React from 'react'
import LocalFilter from '../../Component/Table/Filter/LocalFilter'
import CustomHeader from '../../Component/Table/CustomHeader'
import CustomTh from '../../Component/Table/CustomTh'
import LoadingTableData from '../../Component/Common/Loading/LoadingTableData'
import ServerPagination from '../../Component/Table/Pagination/Server/ServerPagination'
import LocalPagination from '../../Component/Table/Pagination/Local/LocalPagination'

export default function MapViewTable({ defaultDropdown, searchType, TH, data, paginationType, pagination, apiHit, handleClick, loading, header, json, screen, fileName, dateFilter, districtKey, packageKey, handleTrackPlay }) {
    var dataArr = data?.paginate?.[pagination?.page - 1]
    
    return (
        <div className='card'>
            {header && searchType === 'local' ? <LocalFilter json={json} TH={TH} fileName={fileName} /> : <CustomHeader defaultDropdown={defaultDropdown} TH={TH} json={json} apiHit={apiHit} screen={screen} data={data} fileName={fileName} dateFilter={dateFilter} districtKey={districtKey} packageKey={packageKey} />}
            <div className='overflow-x-auto'>
                <table className={`is-hoverable w-full text-left ${paginationType === 'server' ? 'overflow-x-auto' : 'overflow-hidden'} `}>
                    <thead><CustomTh TH={TH} paginationType={paginationType} /></thead>
                    <tbody style={{ maxHeight: 550 }}>
                        {loading ? <tr><td colSpan={TH?.length < 12 ? TH?.length : 12}><LoadingTableData /></td></tr> :
                            (dataArr)?.map((ele, index) => (
                                <tr key={index} className='cursor-pointer'>
                                    <td className={'text-center py-4'}>{ele?.index}</td>
                                    <td className={'text-center py-4'}>
                                        <div className='flex'>
                                            <label className="mx-2 cursor-pointer hover:text-success">
                                                <button className="btn bg-primary/10 text-primary hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25 " onClick={ handleClick?(event)=>handleClick(ele, event):{}}>Live Status</button>
                                            </label>
                                        </div>
                                    </td>
                                    <td className={'text-center py-4'}>{ele?.accname}</td>
                                    <td className={'text-center py-4'}>{ele?.vehnum}</td>
                                    <td className={'text-center py-4'}>{ele?.vehtype}</td>
                                    <td className={'py-4 text-left max-w-md'}>{ele?.address}</td>
                                    <td className={'text-center py-4'}>{ele?.imei}</td>
                                    <td className={'text-center py-4'}>{ele?.department}</td>
                                    <td className={'text-center py-4'}>{ele?.currentStatus}</td>
                                    <td className={'text-center py-4'}>{ele?.lastActive}</td>
                                </tr>
                            ))
                        }
                        {!loading && !data?.[0] && paginationType !== 'local' ? <tr className='text-center'><td colSpan={12}><label className='w-full font-bold tracking-wide text-lg m-5'>No Data Found</label></td></tr> : null}
                    </tbody>
                </table>
            </div>
            <div>{pagination?.totalPages ? paginationType === 'server' ? <ServerPagination pagination={pagination} apiHit={apiHit} /> : <LocalPagination pagination={pagination} apiHit={apiHit} /> : null}</div>

        </div>
    )
}
