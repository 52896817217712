import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setDashboardJson } from '../../../Store/Action/Dashboard/DashboardAction'

export default function Switch() {
    const dispatch = useDispatch()
    const reduxDashboard = useSelector(state => state.DashboardReducer)
    let type = reduxDashboard?.json?.type

    const handleSelectionType = (value) =>{
        var json = reduxDashboard?.json
        Object.assign(json, {type : value})
        if(json?.apiCall === 'call'){
            json.apiCall = 'init'
        }
        dispatch(setDashboardJson(json))
    }
    return (
        <div className='flex'>
            <div className={`cursor-pointer border-2 py-2 px-6 mr-4 card ${type ? 'bg-primary/10 text-primary border-primary' : ''}`} onClick={()=>handleSelectionType(true)}>Single Month</div>
            <div className={`cursor-pointer border-2 py-2 px-6 mr-4 card ${!type ? 'bg-primary/10 text-primary border-primary' : ''}`} onClick={()=>handleSelectionType(false)}>Month Range</div>
        </div>
    )
}
