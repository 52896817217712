import React, { useEffect, useRef } from 'react'
import { GetFullYear } from '../../../Utils/Utils';

export default function Marker({ data, map, mapplsClassObject, cluster }) {

  var markerObject = useRef;




  useEffect(() => {
    markerObject.current = mapplsClassObject.Marker({
      map : map,
      position:cluster?data:{lat:data?.latitude, lng:data?.longitude},
      clusters:cluster,
      icon:  'https://apis.mapmyindia.com/map_v3/1.png',
      popupHtml : `
        <div style="display:flex; flex-direction : column;font-weight : 800">
          <label style="width : 100%; margin-bottom : 10px">Address : ${data?.address}</label>
          <label style="width : 100%">GPS Time : ${GetFullYear(data?.gpsTime*1000)}</label>
        </div>
      `
    })
  })

  return (
    <div></div>
  )
}
