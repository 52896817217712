import { SET_ROADS, SET_SINGLE_ROADS } from "../../Action/Roads/RoadAction";

const initialState = {
    doc: null,
    json: {
        page: 1,
        limit: 10,
        search: {},
        populate: [
            { "path": "ommas", "select": "" },
            { "path": "devices", "select": "" },
        ],
        lite: false,
        select: ""
    },
    single: null,
    timestamp: Date.now()
}

const RoadReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_ROADS:
            return ({ ...state, doc: action.value, timestamp: Date.now() })
        case SET_SINGLE_ROADS:
            return ({ ...state, single: action.value, timestamp: Date.now() })
        default:
            return state;
    }
}

export default RoadReducer;