export const SaveUserData = (data) =>{
    localStorage.setItem('ud', data)
}

export const GetUserData = (data) =>{
    return JSON.parse(localStorage.getItem('ud', data))
}

export const SetBuildVersion = (data)=>{
    localStorage.setItem('bv', JSON.stringify(data));
}

export const GetBuildVersion = () =>{
    return JSON.parse(localStorage.getItem('bv'));
}