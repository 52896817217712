import {
    SET_DPIU_DATA,
    SET_PIU_DATA,
    SET_ZONE_DATA,
    SET_CREATE_ZONE_DPIU_PIU_JSON
} from "../../../Action/SuperAdmin/Zones/ZoneAction";

const initialState = {
    zone: null,
    dpiu: null,
    piu: null,
    json: { page: 1, limit: 10, search: {}, populate:"" },
    addJson:{},
    timestamp: Date.now(),
};

const ZoneReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ZONE_DATA:
            return { ...state, zone: action.value, timestamp: Date.now() };
        case SET_DPIU_DATA:
            return { ...state, dpiu: action.value, timestamp: Date.now() };
        case SET_PIU_DATA:
            return { ...state, piu: action.value, timestamp: Date.now() };
        case SET_CREATE_ZONE_DPIU_PIU_JSON:
            return { ...state, addJson: action.value, timestamp: Date.now() };
        default:
            return state;
    }
};

export default ZoneReducer;
