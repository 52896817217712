import { SET_USER_JSON, USER_DATA } from "../Action/AuthAction";

const initialState = {
    doc: null,
    json: { },
    timestamp: Date.now()
}

const AuthReducer = (state = initialState, action) => {

    switch (action.type) {
        case USER_DATA:
            return ({ ...state, doc: action.value, timestamp: Date.now() })
        case SET_USER_JSON:
            return ({ ...state, json: action.value, timestamp: Date.now() })
        default:
            return state;
    }
}

export default AuthReducer;