export const TH = [
    { id: 0, title: 'Sr. No', serverKey: 'index', className: '', isPdf: true, status: true },
    { id: 0, title: 'State Name', serverKey: 'StateName', className: '', isPdf: true, status: false },
    { id: 1, title: 'District-Name', serverKey: 'districtName', className: '', isPdf: true, status: true },
    { id: 2, title: 'Package-No', serverKey: 'Package_no', className: '', isPdf: true, status: true },
    { id: 3, title: 'Road-Code', serverKey: 'Road_Code', className: '', isPdf: true, status: true },
    { id: 4, title: 'Road-Name', serverKey: 'ROAD_NAME', className: 'text-left max-w-xs ', isPdf: true, status: true },
    { id: 5, title: 'PIU-Name', serverKey: 'PIU_NAME', className: '', isPdf: true, status: true },
    { id: 5, title: 'Contractor-Name', serverKey: 'CONTRACTOR_NAME', className: '', isPdf: true, status: true },
    { id: 6, title: 'No. of-Devices', serverKey: 'deviceCount', className: '', isPdf: true, status: true },
    { id: 7, title: 'Work-Stage', serverKey: 'ACTIVITY_NAME', className: '', isPdf: true, status: true },
    { id: 8, title: 'Device-Installation Status', serverKey: 'DeviceStatus', className: 'DeviceStatusClass', overrideClass: 'center', isPdf: true, status: true },
    { id: 9, title: 'KML-Data Status', serverKey: 'KMLStatus', className: 'KMLStatusClass', overrideClass: 'center', isPdf: true, status: true },
    { id: 9, title: 'Santion-Date', serverKey: 'SANCTION_DATE', className: '', overrideClass: '', isPdf: true, status: false },
    { id: 9, title: 'Santion-Length', serverKey: 'SANCTION_LENGTH', className: '', overrideClass: '', isPdf: true, status: false },
    { id: 9, title: 'Activity-Name', serverKey: 'ACTIVITY_NAME', className: '', overrideClass: '', isPdf: true, status: false },
    { id: 9, title: 'Activity-Quantity', serverKey: 'ACTIVITY_QUANTITY', className: '', overrideClass: '', isPdf: true, status: false },
    { id: 9, title: 'Activity', serverKey: 'ACTIVITY_START_DATE', className: '', overrideClass: '', isPdf: true, status: false },
    { id: 9, title: 'Actual Activity-Start Date', serverKey: 'ACTUAL_ACTIVITY_START_DATE', className: '', overrideClass: '', isPdf: true, status: false },
    { id: 9, title: 'Activity-Completion Date', serverKey: 'ACTIVITY_COMPLETION_DATE', className: '', overrideClass: '', isPdf: true, status: false },
    { id: 9, title: 'Actual Activity-Completion Date', serverKey: 'ACTUAL_ACTIVITY_COMPLETION_DATE', className: '', overrideClass: '', isPdf: true, status: false },
    { id: 9, title: 'Award Date', serverKey: 'AWARD_DATE', className: '', overrideClass: '', isPdf: true, status: false },
    { id: 9, title: 'Completed-Road Length', serverKey: 'COMPLETED_ROAD_LENGTH', className: '', overrideClass: '', isPdf: true, status: false },
    { id: 9, title: 'Completion-Date', serverKey: 'COMPLETION_DATE', className: '', overrideClass: '', isPdf: true, status: false },
    { id: 9, title: 'Executed-Quantity', serverKey: 'EXECUTED_QUANTITY', className: '', overrideClass: '', isPdf: true, status: false },
    { id: 9, title: 'PIMS-Finalize Date', serverKey: 'PMIS_FINALIZE_DATE', className: '', overrideClass: '', isPdf: true, status: false },
    { id: 10, title: 'Action', serverKey: 'Action', className: '', isPdf: false, status: true },
]
