import _ from "lodash"
import { GetFullYear, GetPageCount } from "../../../Utils/Utils"
import { setSeelctedDevice } from "../../../Store/Action/Analytics/AnalyticsAction"

let WorkType = [
    { _id: 0, title: 'Earthwork', serverKey: 'ew', status: true },
    { _id: 1, title: 'Granular Sub Base', serverKey: 'gsb', status: true },
    { _id: 2, title: 'Surface Course (OGPC/MSS/CC)', serverKey: 'sc', status: true },
    { _id: 3, title: 'WBM Grading 2', serverKey: 'wbm2', status: true },
    { _id: 4, title: 'WBM Grading 3', serverKey: 'wbm3', status: true },
    { _id: 5, title: 'Bituminous Macadam', serverKey: 'mb', status: true },
    { _id: 6, title: 'Subgrade', serverKey: 'sg', status: true },
    { _id: 7, title: 'Wet Mix Macadam', serverKey: 'sg', status: true },
]
let selctedClass = 'text-primary font-bold tracking-wide'
let disabledClass = 'bg-transparent text-slate-400/70'

var td = [
    { _id: 0, title: 'Earthwork', Percentage: '0%', activeClass: selctedClass, progressColor: '', percentageColor: '', status: true },
    { _id: 0, title: 'Subgrade', Percentage: '0%', activeClass: selctedClass, progressColor: '', percentageColor: '', status: true },
    { _id: 0, title: 'Granular Sub Base', Percentage: '0%', activeClass: selctedClass, progressColor: '', percentageColor: '', status: true },
    { _id: 0, title: 'WBM Grading 2', Percentage: '0%', activeClass: selctedClass, progressColor: '', percentageColor: '', status: true },
    { _id: 0, title: 'WBM Grading 3', Percentage: '0%', activeClass: selctedClass, progressColor: '', percentageColor: '', status: true },
    { _id: 0, title: 'Wet Mix Macadam', Percentage: '0%', activeClass: selctedClass, progressColor: '', percentageColor: '', status: true },
    { _id: 0, title: 'Bituminous Macadam', Percentage: '0%', activeClass: selctedClass, progressColor: '', percentageColor: '', status: true },
    { _id: 0, title: 'Surface Course (OGPC/MSS/CC)', Percentage: '0%', activeClass: selctedClass, progressColor: '', percentageColor: '', status: true },
]

var tp = []
td?.map((ele) => tp.push(ele?.title))

export const CompileCompleteAnalytics = (data, pagination, dispatch) => {
    const MyPromiss = new Promise((resolve, reject) => {
        var tp = data

        var tempArr = []
        tp?.docs?.map((ele, index) => {
            var obj = {}
            var ommas = ele?.roadId?.ommas?.[0]
            Object.assign(obj, {
                index: GetPageCount(pagination?.limit, pagination?.page, index),
                _id: ele?._id,
                ele: ele,
                devicesList: ele?.roadId?.devices,
                created_at: GetFullYear(ele?.created_at),
                mapData: ele?.roadId?.mapData,
                department: 'NA',
                districtName: ele?.district,
                piuName: ommas?.PIU_NAME,
                contractorName: ommas?.CONTRACTOR_NAME,
                roadCode: ommas?.Road_Code,
                roadName: ommas?.ROAD_NAME,
                packageNo: ele?.packageId,
                sanctionedLength: ommas?.SANCTION_LENGTH,
                excutedQty: ommas?.EXECUTED_QUANTITY,
                awardDate: ommas?.AWARD_DATE,
                actiualActivityCompletionDate: ommas?.ACTIVITY_COMPLETION_DATE,
                startPoint: ele?.patch?.[0],
                endPoint: ele?.patch?.[1],
                startTime: GetFullYear(ele?.startDate),
                endTime: GetFullYear(ele?.endDate),
                movementDistance: '',
                earthWorkPasses: '',
                earthWorkQuality: '',
                subgradePasses: '',
                subgradeQuality: '',
                wbmGrading2Passes: '',
                wbmGrading2Quality: '',
                wbmGrading3Passes: '',
                wbmGrading3Quality: '',
                gsbPasses: '',
                gsbQuality: '',
                total: ele?.patchCompleted,
                quality: '',
                vehicleNo: ele?.imeiData?.deviceDetails?.registrationNumber,
                vehicleType: ele?.imeiData?.deviceDetails?.deviceType,
                imei: ele?.imeiData?.deviceDetails?.trackingCode,
                deviceName: ele?.imeiData?.deviceDetails?.name,
                deviceInstallData: '',
                deviceUninstallDate: '',
                wt: GenerateWorkDetails(ele, ele?.roadId?.ommas),
                workName: ele?.workName,
                // Percentage
                ew: parseFloat(ele?.ew).toFixed(2),
                gsb: parseFloat(ele?.gsb).toFixed(2),
                sc: parseFloat(ele?.sc).toFixed(2),
                wbm2: parseFloat(ele?.wbm2).toFixed(2),
                wbm3: parseFloat(ele?.wbm3).toFixed(2),
                mb: parseFloat(ele?.mb).toFixed(2),
                sg: parseFloat(ele?.sg).toFixed(2),
                sg: parseFloat(ele?.sg).toFixed(2),
                Action: <label className="cursor-pointer hover:text-success">
                    <button className="btn bg-primary/10 text-primary hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25 ">Show Details</button>
                </label>
            })
            tempArr.push(obj)
            if (ele?.roadId?.devices && dispatch) {
                var findStaticRoller = ele?.roadId?.devices?.find((Obj) => Obj?.vehtype === 'Static Roller')
                if (findStaticRoller) {
                    dispatch(setSeelctedDevice({ imei: findStaticRoller?.imei, type: findStaticRoller?.vehtype }))
                } else {
                    dispatch(setSeelctedDevice({ imei: ele?.roadId?.devices?.[0]?.imei, type: ele?.roadId?.devices?.[0]?.vehname }))
                }
            }
        })
        tp.docs = tempArr
        if (tp?.pages) {
            Object.assign(tp, {
                totalPages: tp?.pages,
                totalDocs: tp?.total
            })
        }

        resolve(tp)
    })
    return MyPromiss;
}


export const GenerateWorkDetails = (data, ommasData) => {
    
    var allWorks = [...new Set(ommasData.map(x => x.ACTIVITY_NAME))]
    const matchedWork = td.filter(item => allWorks.includes(item?.title));

    if (matchedWork?.length > 0) {
        return matchedWork;
    } else {
        var workT = WorkType?.filter(res => data?.[res?.serverKey])
        workT?.map((el) => {
            var index = td?.findIndex((inner) => inner?.title === el?.title)
            if (index !== -1) {
                const referenceValue = 12;
                var Percentage = parseFloat((parseFloat(data?.[el?.serverKey]) / data?.roadLength) * 100)
                Percentage = Percentage > 100 ? 100 : Percentage.toFixed(2)
                td[index].activeClass = selctedClass
                td[index].Percentage = Percentage + '%'
                td[index].ProgressValue = (Percentage / 100) * referenceValue;
                td[index].progressColor = 'bg-success'
                td[index].percentageColor = 'bg-success/10 text-success'
            }
        })



        return td;
    }

}

export const CalculateOverallPercentage = (data) => {

    // var value  = data?.[keyName]

}
