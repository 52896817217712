export const GetCenter = (data) =>{
    return new Promise((resolve, reject)=>{
        var length = data?.length
        var index = Math.round(length/2)
        var center = {lat : parseFloat(data?.[index]?.lat),lng : parseFloat(data?.[index]?.lng)}
        resolve(center)
    })
}


export const GenerateCenter = (data, from) => {
    const MyPromiss = new Promise((resolve, reject) => {
        
        if (!data) {
            resolve({ status: false, center: [28.5419808, 77.2060235], route: [] });
            return;
        }

        var t_coordinates  = from === 'roads' ? data[0]?.geometry?.coordinates :  data?.data?.[0]?.geometry?.coordinates;

        const coordinates = from === 'roads' ? (t_coordinates?.length === 1 ? t_coordinates?.[0] : t_coordinates) : t_coordinates;

        const tl = coordinates?.length;
        const tc = coordinates?.[Math.round(tl / 2)];

         if(tc && from!=='roads'){
            resolve({ status: true, center: {lat : parseFloat(tc?.lat),lng : parseFloat(tc?.lng)}, markers: coordinates });
        }else{
            var route = coordinates?.map((ele)=>({lat:ele?.[1], lng:ele?.[0]}))
            resolve({ status: true, center: {lat : parseFloat(tc?.[1]),lng : parseFloat(tc?.[0])}, route: route });
        }
    })

    return MyPromiss;
}
