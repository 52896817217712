export const SET_ZONE_DATA = "SET_ZONE_DATA";
export const SET_DPIU_DATA = "SET_DPIU_DATA";
export const SET_PIU_DATA = "SET_PIU_DATA";
export const SET_CREATE_ZONE_DPIU_PIU_JSON = 'SET_CREATE_ZONE_DPIU_PIU_JSON'

export const zone = (data) => ({
    type: SET_ZONE_DATA,
    value: data,
});

export const dpiu = (data) => ({
    type: SET_DPIU_DATA,
    value: data,
});

export const piu = (data) => ({
    type: SET_PIU_DATA,
    value: data,
});


export const addZoneDpuiuPiuJson = (data) => ({
    type: SET_CREATE_ZONE_DPIU_PIU_JSON,
    value: data,
});