import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetDistrictConditionData } from '../../Utils/Utils'
import { HitApi } from '../../Store/Action/CommonApi/ApiAction'
import { setDashboardJson } from '../../Store/Action/Dashboard/DashboardAction'

export default function Item({ element }) {
  const dispatch = useDispatch()
  const reduxUser = useSelector(state => state.AuthReducer)
  const reduxDashboard = useSelector(state => state.DashboardReducer)

  const [data, setData] = useState(-1)

  useEffect(() => {
    if (data === -1 && element?.api && element?.json && element?.status && reduxUser?.doc) {
      // LoadData()
    }
  }, [])

  const LoadData = () => {
    var query = GetDistrictConditionData(reduxUser?.doc, element?.keyName || 'district')
    Object.assign(element?.json?.search, query)
    HitApi(element?.json, element?.api).then((res) => {
      if (res?.status === 200) {
        setData(res?.doc?.totalDocs)
      }
    })
  }

  const handleClick = (value) => {
    var json = reduxDashboard?.json
    Object.assign(json?.search, { vehType: value })
    dispatch(setDashboardJson(json))
  }

  return (
    <div className={`card cursor-pointer p-4  flex flex-row justify-between border-2 ${reduxDashboard?.json?.search?.vehType === element?.title ? 'bg-primary/10  text-primary border-primary' : ''}`} onClick={() => handleClick(element?.title)}>
      <label className='cursor-pointer'>{element?.title}</label>
      {/* <div className={`badge border ${reduxDashboard?.json?.search?.vehType === element?.title ? 'bg-primary text-white border-primary' : ''} dark:border-accent-light dark:text-accent-light`}>
        {data !== -1 ? data : 0}
      </div> */}
    </div>
  )
}
