import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setApiJson } from '../../Store/Action/CommonApi/ApiAction'

function Select(props) {

  const dispatch = useDispatch()
  const reduxApiReducer = useSelector(state => state.ApiReducer)
  const handleChange = (e) => {

    var json = reduxApiReducer?.json
    if (props?.parent) {
      json[props?.parent] = json[props?.parent] || {};
      Object.assign(json[props?.parent], { [props?.fieldName]: e?.target?.value });
    } else {
      Object.assign(json, { [props?.fieldName]: e?.target?.value });
    }

    dispatch(setApiJson(json))

  }

  return (
    <div className='mb-3'>
      <label className="block">
        <h2 className="font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100 lg:text-base">{props.title}{props.important ? <span className='text-error'>&nbsp;*</span> : ''}</h2>
        <select
          className="form-select mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
          onChange={props.onChange || handleChange}>
          {props?.defaultText ? <option value={''}> {props?.defaultText} </option> : null}
          {props?.option !== null && props?.option?.[0] ? props?.option.map((element, i) => element?.status ? <option key={i} label={element?.title} value={element?.value}>{element?.title}</option> : '') : null}
        </select>
      </label>
      {props.loading && props.show ? <div className="progress h-1 bg-slate-150 dark:bg-navy-500"> <div className="is-indeterminate relative w-4/12 rounded-full bg-slate-500 dark:bg-navy-400" ></div> </div> : null}
      <span className="text-small tracking-wide text-error dark:text-error">{props.errors !== undefined ? props.errors[props.field]?.message : null}</span>
    </div>
  )
}

export default Select