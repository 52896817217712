import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MapviewIcon } from '../../Constant/Icon/Icon'
import { HitApi } from '../../Store/Action/CommonApi/ApiAction'
import { getAnalyticsDaily } from '../../Constant/Api/Constant'
import { setPagination } from '../../Store/Action/Pagination/Pagination'
import { setAfterCalculationDoc, setCompleteAnalytics } from '../../Store/Action/Analytics/AnalyticsAction'
import { setGeoJson, setMyMapGeoJson, setSelectedDate } from '../../Store/Action/Map/MapAction'

let activeColor = 'bg-white text-black font-bold tracking-wide'
let selctedClass = 'bg-success/10 text-success font-bold tracking-wide border-l-4 border-success'
let inactiveColor = 'bg-zinc-100 text-slate-300 font-bold tracking-wide'

function Item({ ele, data, selected, setSelected, apiHit, tempArr, count, setCount, FindSelected, length }) {
    const dispatch = useDispatch()
    const reduxData = useSelector(state => state.AnalyticsReducer)
    const reduxPagination = useSelector(state => state.PaginationReducer)

    const [lacalData, setLocalData] = useState(null)

    useEffect(() => {
        if (lacalData === null) {
            loadData()
        }



    }, [])

    const Progress = ({ active, ele }) => {
        return <div className="progress bg-slate-150 dark:bg-navy-500" style={{ height: '1px' }}><div className={`w-${ele?.ProgressValue || 0}/12 rounded-full ${active ? active : 'bg-red-500'} dark:bg-navy-400`}></div></div>
    }

    const Percentage = ({ data, color }) => {
        return <label className='text-right cursor-pointer'><div className={`badge w-12 ${color} dark:bg-success/15`}>{data}</div></label>
    }

    const loadData = () => {
        dispatch(setMyMapGeoJson(null))
        var json = reduxData?.workJson
        var tempId = window.location.pathname.split('/')
        var packageId = tempId[tempId?.length - 1]
        Object.assign(json.search, {
            workName: ele?.title,
            packageId: packageId,
            // "totalKm": { '$gt': 0 },
            // "trips.0": { $exists: true },
            // "trips.largest": { '$lte': 1800 },
            "$or": [{ vehType: reduxData?.selectedDevice?.type || "Static Roller" }]
        })
        Object.assign(json,{limit : 1})

        // reduxData?.selectedDevice?.type !== 'Static Roller' ? 2000 : 0

        HitApi(json, getAnalyticsDaily).then((res) => {
            var tc = count
            tc.push(ele?.title)
            setCount(tc)
            if (res?.doc?.totalDocs) {
                tempArr.push(ele?.title)
                setLocalData(res?.doc?.totalDocs)
            }
            if (tc.length === length) {
                FindSelected(tempArr)
                dispatch(setAfterCalculationDoc(tempArr))
            }

        })
    }

    const handleClick = (element) => {
        dispatch(setGeoJson({ "type": "FeatureCollection", features: [] }))
        dispatch(setSelectedDate([]))
        setSelected(element?.title)
        var json = reduxData?.dailyJson
        json.search.workName = element?.title
        var tp = reduxPagination?.pagination
        tp.page = 1
        dispatch(setPagination(tp))
        if (apiHit) { apiHit() }
    }




    return (
        // <div className='bg-white border-b h-full' onClick={() => lacalData ? handleClick(ele, data) : null}>
        //     <div className={`flex align-middle h-full items-center  ${lacalData ? 'cursor-pointer' : 'cursor-not-allowed'} px-4 ${lacalData ? activeColor : inactiveColor} ${selected === ele?.title ? selctedClass : ''}`}>
        //         <span className={`${lacalData ? 'cursor-pointer' : 'cursor-not-allowed'}`}>{MapviewIcon} </span>
        //         <label className={`ml-2 w-full ${lacalData ? 'cursor-pointer' : 'cursor-not-allowed'}`}>{ele?.title}</label>
        //         {/* <Percentage data={lacalData || '0'} color={ele?.percentageColor} /> */}
        //     </div>
        //     <Progress active={ele?.progressColor} ele={ele} />
        // </div>
        <div className='bg-white border-b h-full' onClick={() => handleClick(ele, data)}>
        <div className={`flex align-middle h-full items-center   px-4 ${lacalData ? activeColor : activeColor} ${selected === null ? 'Earthwork' : selected === ele?.title ? selctedClass : ''}`}>
            <span className={``}>{MapviewIcon} </span>
            <label className={`ml-2 w-full `}>{ele?.title}</label>
            {/* <Percentage data={lacalData || '0'} color={ele?.percentageColor} /> */}
        </div>
        <Progress active={ele?.progressColor} ele={ele} />
    </div>
    )
}

export default Item