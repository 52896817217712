import { SET_ERROR, SET_API_JSON , SET_API_RESULT} from "../../Action/CommonApi/ApiAction";

const initialState = {
    doc: null,
    json: {page:1, limit : 100, search:{}},
    error: {},
    result : {},
    timestamp: Date.now()
}

const ApiReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_ERROR:
            return ({ ...state, error: action.value, timestamp: Date.now() })
        case SET_API_JSON:
            return ({ ...state, json: action.value, timestamp: Date.now() })
        case SET_API_RESULT:
            return ({ ...state, result: action.value, timestamp: Date.now() })
        default:
            return state;
    }
}

export default ApiReducer;