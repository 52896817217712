import { SET_TRACK_AND_PLAY_DATA, SET_SELECTED_DEVICE_FOR_TRACK_AND_PLAY, SET_TRACK_AND_PLAY_DATA_CONTROL, SET_TRACK_AND_PLAY_CURRENT_TRACK } from "../../Action/TrackAndPlay/TrackAndPlayAction";

const initialState = {
    doc: null,
    start: false,
    selected: null,
    currentTrack: null,
    json: {},
    timestamp: Date.now()
}

const TrackAndPlayDatePickerReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_TRACK_AND_PLAY_DATA:
            return ({ ...state, doc: action.value, timestamp: Date.now() })
        case SET_SELECTED_DEVICE_FOR_TRACK_AND_PLAY:
            return ({ ...state, selected: action.value, timestamp: Date.now() })
        case SET_TRACK_AND_PLAY_DATA_CONTROL:
            return ({ ...state, start: action.value, timestamp: Date.now() })
        case SET_TRACK_AND_PLAY_CURRENT_TRACK:
            return ({ ...state, currentTrack: action.value, timestamp: Date.now() })
        default:
            return state;
    }
}

export default TrackAndPlayDatePickerReducer;