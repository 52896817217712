import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { HitApi } from '../../../Store/Action/CommonApi/ApiAction'
import { IMZBaseURL } from '../../../Constant/Api/Constant'
import { GetDetailUsingImei } from '../LiveView/LiveViewPromiss'

export default function TrackAndPlay() {
  const dispatch = useDispatch()
  const reduxUser = useSelector(state => state.AuthReducer)
  const [data, setData] = useState(null)

  useEffect(() => {

    if (data === null) {
      var imei = window.location.pathname.split('/')?.[3]
      loadData(imei)
    }

  }, [])

  const loadData = (imei) => {
    var json = {
      "requesttype": "DEVICEVIEW", "vendorcode": "IMZ", "request": {
        username: reduxUser?.doc?.imz_login[0]?.username,
        pin: reduxUser?.doc?.imz_login[0]?.pin, "ipaddress": "196.195.156.107", "clienttype": "web"
      }
    }



    HitApi(json, IMZBaseURL).then((res) => {
      if (res?.response?.data?.[0]) {
        GetDetailUsingImei(res?.response?.data, imei).then((Result) => {
          if (Result) {
            setData(Result)
          }
        })
      }
    })

  }
  return (
    <div>TrackAndPlay</div>
  )
}
