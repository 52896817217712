export const CompileLiveTrackData = (data, ele) =>{
    return new Promise((resolve, reject)=>{
        var td=[]
        data?.map((ele)=>td?.push({lat:parseFloat(ele?.lat), lng:parseFloat(ele?.lng)}))
        if(td){
            var len = data?.length
            Object.assign(ele,{status : data?.[len-1]?.status, address :  data?.[len-1]?.address,department:  data?.[len-1]?.name?.split('-')?.[1]})
            resolve({ele:ele, td:td})
        }
    })
}

export const GetDetailUsingImei = (data, imei, liveData) =>{
    return new Promise((resolve, reject)=>{
        var found = data?.find((ele)=>ele?.imei === imei)
        resolve(found)
    })
}