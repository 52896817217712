import React from 'react'
import { ActionIcon, Tooltip } from 'rizzui'

export default function AddButton({ text, onClick, Icon }) {
    return (
        <div>
            <Tooltip size="md" content={text} placement="top" color="invert" className='bg-slate-600 text-white'>
                <ActionIcon as="span" size="sm" variant="outline" aria-label={text} className="hover:!border-gray-900 hover:text-gray-700" onClick={() => onClick ? onClick() : {}}>
                    <button class="" type="button" aria-label="Delete Item" >
                        {Icon}
                    </button>

                </ActionIcon>
            </Tooltip>
        </div>
    )
}
