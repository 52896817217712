export const USER_DATA = 'USER_DATA'
export const SET_USER_JSON = 'SET_USER_JSON'


export const setUserData = (data) => ({
    type: USER_DATA,
    value: data
});

export const setUserJson = (data) => ({
    type: SET_USER_JSON,
    value: data
});
