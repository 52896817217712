import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Input from '../../../../Component/Forms/Input/Input'
import { InputClass } from '../../../../Constant/Style/StyleConstant'
import FieldTitle from '../../../../Component/Common/Header/FieldTitle'
import Select from '../../../../Component/Select/Select'
import user from '../data/user'
import department from '../data/department'
import StateList from '../../../../Constant/Common/StateList'
import UserControl from '../UserControl'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import SelectWithRegister from '../../../../Component/Select/SelectWithRegister'

export default function Form() {
    const dispatch = useDispatch()
    const reduxApiReducer = useSelector(state => state.ApiReducer)

    const validationSchema = Yup.object().shape({
        username: Yup.string()
            .required('Username is required'),
        contact: Yup.string()
            .required('Phone Number is required')
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(10, 'Invalid Phone Number'),
        state: Yup.string()
            .required('State Name is required'),
        userType: Yup.string()
            .required('User Type is required'),
        name: Yup.string()
            .required('Name is required'),
        password: Yup.string()
            .required('Password is required'),
        confirmPassword: Yup.string()
            .required('Confirm Password is required')
            .oneOf([Yup.ref('password')], 'Passwords does not match'),
        email: Yup.string()
            .required('Email ID is required')
            .email('Invalid Email ID'),
        department_type: Yup.string()
            .required('Department Type is required'),
        // zone :Yup.string()
        //     .required('User Type is required'),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState, setError } = useForm(formOptions);
    const { errors } = formState;

    const onSubmit = () => {
        var json = reduxApiReducer?.json


    }

    const handleDataLoad = (e, key, loadType) => {
        
    }



    return (
        <div className='card p-5'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='grid grid-cols-3 gap-4 mb-4'>
                    <div>
                        <div><FieldTitle text={'Name'} /></div>
                        <div><Input fieldName={'name'} parent={'infor'} className={InputClass} /></div>
                    </div>
                    <div>
                        <div><FieldTitle text={'Email'} /></div>
                        <div><Input fieldName={'email'} className={InputClass} /></div>
                    </div>
                    <div>
                        <div><FieldTitle text={'Contact'} /></div>
                        <div><Input fieldName={'contact'} className={InputClass} /></div>
                    </div>
                </div>
                <div className='grid grid-cols-3 gap-4 mb-4'>
                    <div>
                        <div><FieldTitle text={'Username'} /></div>
                        <div><Input fieldName={'username'} className={InputClass} /></div>
                    </div>
                    <div>
                        <div><FieldTitle text={'Password'} /></div>
                        <div><Input fieldName={'password'} className={InputClass} /></div>
                    </div>
                    <div>
                        <div><FieldTitle text={'Confirm Password'} /></div>
                        <div><Input fieldName={'confirmPassword'} className={InputClass} /></div>
                    </div>
                </div>
                {/* <div className='grid grid-cols-3 gap-4 mb-4'>
                    <div>
                        <div><FieldTitle text={'State'} /></div>
                        <div><SelectWithRegister option={StateList} defaultText={'Select State'} register={register} field={'state'} onChange={(e) => handleDataLoad(e, 'state', 'zone')} /></div>
                        <div><Select fieldName={'stateName'} defaultText={'Select State...'} option={StateList} /></div>
                    </div>
                    <div>
                        <div><FieldTitle text={'Department'} /></div>
                        <div><Select fieldName={'department'} defaultText={'Select Department...'} option={department} /></div>
                    </div>
                    
                </div> */}
                <div>
                        <div><FieldTitle text={'User Type'} /></div>
                        <div><Select fieldName={'userType'} defaultText={'Select User Type...'} option={user} /></div>
                    </div>
                <div><UserControl register={register} errors={errors} searchJson={reduxApiReducer} /></div>
            </form>

            <button onClick={onSubmit}> CLick me </button>
        </div>
    )
}
