import { Fair, Good } from "../../../../Constant/Common/ColorCombination"
import { Colors } from "../../../../Constant/Style/StyleConstant"
import { GetFullYear, GetPageCount } from "../../../../Utils/Utils"

export const CompileDetailsAnalytics = (data, pagination) => {
    const MyPromiss = new Promise((resolve, reject) => {
        var tp = data
        
        var tempArr = []
        var trips = []
        tp?.docs?.map((ele, index) => {
            var obj = {}
            var ommas = ele?.roadId?.ommas?.[0]
            Object.assign(obj, {
                index: GetPageCount(pagination?.limit, pagination?.page, index),
                _id: ele?._id,
                report_id: ele?.report_id,
                ele: ele,
                created_at: GetFullYear(ele?.created_at),
                device_id: ele?.device_id,
                device_id: ele?.roadId?.devicesList,
                department: 'NA',
                districtName: ele?.district,
                ommas: ele?.roadId?.ommas,
                piuName: ommas?.PIU_NAME,
                contractorName: ommas?.CONTRACTOR_NAME,
                roadCode: ommas?.Road_Code,
                roadName: ommas?.ROAD_NAME,
                packageNo: ele?.packageId,
                activityName: ele?.workName,
                sanctionedLength: ommas?.SANCTION_LENGTH,
                excutedQty: ommas?.SANCTION_LENGTH,
                awardDate: ommas?.AWARD_DATE,
                activityCompletionDate: ommas?.ACTIVITY_COMPLETION_DATE,
                actiualActivityCompletionDate: GetFullYear(ele?.endDate),
                activityStartDate: ommas?.ACTIVITY_START_DATE, // onlick get particular ommas data
                actiualActivityStartDate: GetFullYear(ele?.startDate),
                startPoint: ele?.patch?.[0],
                endPoint: ele?.patch?.[1],
                startTime: GetFullYear(ele?.created_at),
                endTime: GetFullYear(ele?.created_at),
                movementDistance: parseFloat((ele?.trips?.[0]?.distance)).toFixed(2) + ' m',
                Passes: parseFloat(ele?.trips?.[0]?.round).toFixed(1),
                total: ele?.patchCompleted,
                ColorClass: CalculateQuality(parseFloat(ele?.trips?.[0]?.round).toFixed(1)),
                vehicleNo: ele?.device_id?.deviceDetails?.registrationNumber,
                vehType: ele?.vehType,
                imei: ele?.imeiData?.imei,
                deviceName: ele?.device_id?.deviceDetails?.name,
                deviceInstallData: '',
                deviceUninstallDate: '',
                mapData: ele?.mapData,
                trips: ele?.trips?.[0],
                exeLength: '0',
                largest: Math.round(ele?.trips?.[0]?.largest),
                working_hours_in_sec: ((ele?.working_hours_in_sec) / 60) / 60,
                Action: <label className="cursor-pointer hover:text-success">
                    <button className="btn bg-primary/10 text-primary hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25 ">Play Track</button>
                </label>
            })
            trips.push(obj?.trips)
            tempArr.push(obj)
        })

        if (tempArr) {
            CalculateRound(trips, tempArr)
            GenerateDataForChart(trips, tempArr)
        }
        
        tp.docs = tempArr
        var workSet = [...new Set(tempArr.map(x => x.activityName))]

        resolve({ tp: tp, workSet: workSet })
    })
    return MyPromiss;
}

export const CalculateQuality = (round) => {
    var q = round > Good ? '' : round > Fair ? '' : ' bg-error/10 '

    return q;
}

export const CompileTrackData = (data, startTime) => {
    const MyPromiss = new Promise((resolve, reject) => {
        var td = []
        data?.map((ele) => td.push(...ele?.details))
        if (td) {
            GenerateLatLngForTrackData(td, startTime).then((res) => {
                if (res?.locationData) {
                    resolve(res)
                }
            })
        }
        // resolve(td)
    })
    return MyPromiss;
}

export const GenerateLatLngForTrackData = (data, startTime) => {
    const MyPromiss = new Promise((resolve, reject) => {
        var td = []
        var locationData = []
        var tempLatLngData = []
        data?.map((ele) => {
            var tj = {}
            Object.assign(tj, {
                address: ele?.address,
                lat: ele?.latitude,
                lng: ele?.longitude,
                status: ele?.movementStatus,
                speed: ele?.speed
            })
            td.push(tj)
            tempLatLngData.push([ele?.latitude, ele?.longitude])
        })
        var features = {
            "type": "Feature",
            "properties": {
                "color": "green"
            },
            "geometry": {
                "type": "LineString",
                "coordinates": tempLatLngData
            }
        }

        resolve({ data: td, locationData: features })
    })
    return MyPromiss;
}


export const CalculateRound = (data, tempArr) => {

    if (data?.[0] !== undefined) {

        // var rounds = data?.reduce((n, { round }) => n + Math.round(round || 0), 0)
        // var avg = rounds / data?.length

        var rounds = data?.map((ele, index) => {
            let t_round = 0
            if (ele) {
                t_round = t_round + Math.round(ele?.round || 0)
            }
        })
        var avg = rounds / data?.length
        Object.assign(tempArr?.[0], { roundData: rounds || 0, avgRounds: avg || 0 })
        return tempArr

    }
    return []
}

export const GenerateDataForChart = (data, tempArr) => {
    var DateSeries = []
    var RoundSeries = []
    var WorkingTimeSeries = []
    var poor = 0
    var fair = 0
    var good = 0
    var referenceValue = 0

    tempArr?.map((ele) => {
        var tempRound = Math.round(ele?.Passes)
        // var tempWorkTime = (ele?.working_hours_in_sec > 10 ? 10 : ele?.working_hours_in_sec)
        var tempWorkTime = ele?.working_hours_in_sec
        tempWorkTime = +parseFloat(tempWorkTime).toFixed(2)
        referenceValue = referenceValue + tempRound
        DateSeries.push(ele?.startTime)
        RoundSeries.push(
            {
                y: tempRound,
                color: tempRound > Good ? Colors?.good : tempRound > Fair ? Colors?.fair : Colors?.poor
            }
        )
        WorkingTimeSeries.push({
            y: tempWorkTime
        })
        if (tempRound > Good) {
            good = good + tempRound
        } else if (tempRound > Fair && tempRound < Good) {
            fair = fair + tempRound
        } else if (tempRound < Fair) {
            poor = poor + tempRound
        }
    })

    // var referenceValue = good?.length + fair?.length + poor?.length

    var PieChartData = [
        { name: 'Good', y: (good * referenceValue) / 100 },
        { name: 'Fair', y: (fair * referenceValue) / 100 },
        { name: 'Poor', y: (poor * referenceValue) / 100 },
    ]

    if (tempArr?.[0]) {
        Object.assign(tempArr?.[0], { chartData: { ChartXAxis: DateSeries, ChartYAxis: { name: 'Round', data: RoundSeries }, PieChartData: PieChartData, WorkTimeChartData: { ChartXAxis: DateSeries, ChartYAxis: { name: 'Working Hours', data: WorkingTimeSeries } } } })
    }

    return (tempArr)
}