import { Marker } from '@react-google-maps/api';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { HitApi } from '../../Store/Action/CommonApi/ApiAction';
import { IMZBaseURL } from '../../Constant/Api/Constant';

export default function MovingMarker({ imei }) {
    const reduxUser = useSelector(state => state.AuthReducer)
    const [data, setData] = useState({ lat: 26.464407, lng: 83.5567245 })



    useEffect(() => {
        const interval = setInterval(() => {
            if (reduxUser?.doc && imei) {
                GetLiveLocation()
            }
        }, (5000));
        
        return () => {
            clearInterval(interval);
        };

    }, [])

    const GetLiveLocation = () => {
        var json = {
            requesttype: "LASTPKT",
            vendorcode: "IMZ",
            request: {
                username: reduxUser?.doc?.imz_login[0]?.username,
                pin: reduxUser?.doc?.imz_login[0]?.pin,
                ipaddress: "124.123.18.38",
                clienttype: "web",
                imei: imei,
            },
        }
        HitApi(json, IMZBaseURL).then((res) => {

            if (res) {
                setData({ lat: res?.response?.data?.lat, lng: res?.response?.data?.lng })
            }
        })
    }

    return (
        <div>
            <Marker position={data} icon={{url : 'http://www.mapmyindia.com/api/advanced-maps/doc/sample/map_sdk/car.png', scaledSize:{width : 15, height:20}}} />
        </div>
    )
}
