import React, { useEffect, useState } from 'react'
import DeviceList from '../../../Component/DeviceList/DeviceList'
import CustomChart from './CustomChart/CustomChart'
import CustomSlider from './CustomChart/CustomSlider'
import { useDispatch, useSelector } from 'react-redux'
import { HitApi } from '../../../Store/Action/CommonApi/ApiAction'
import { getAnalyticsDaily } from '../../../Constant/Api/Constant'
import { GenerateMonths, GetDistrictConditionData, GetFullYear, GetMonthName } from '../../../Utils/Utils'
import { setDashboardChartData, setDashboardListData, setDashboardMainData, setDashboardSetting } from '../../../Store/Action/Dashboard/DashboardAction'
import Switch from './Switch'
import { CompileFilterData } from './AnalyticsPromiss'
import PageTitle from '../../../Component/Common/Title/PageTitle'
import LoadingTableData from '../../../Component/Common/Loading/LoadingTableData'
import NoDataFound from '../../../Component/Common/DataError/NoDataFound'
import DistrictAndPackageFilter from '../../../Component/CommonFilters/DistrictAndPackageFilter'

export default function Analytics() {

  const dispatch = useDispatch()
  const reduxDashboard = useSelector(state => state.DashboardReducer)
  const reduxUser = useSelector(state => state.AuthReducer)
  const reduxPagination = useSelector(state => state.PaginationReducer)
  let view = ['1 Column', '2 Column', '3 Column']
  const [selected, setSelected] = useState('3 Column')
  const [loading, setLoading] = useState(false)

  const [defualtMonth, setDefaultMonth] = useState(null)

  useEffect(() => {
    if (reduxDashboard?.chart === null && defualtMonth === null && reduxDashboard?.DateArr !== null) {
      // GetData()
    }

  }, [reduxDashboard])

  const GetData = () => {
    var Data = require('./DummyData.json')
    var json = reduxDashboard?.json
    var query = GetDistrictConditionData(reduxUser?.doc, 'district')
    Object.assign(json.search, query)
    var StartMonth = reduxDashboard?.DateArr?.[reduxDashboard?.DateArr?.length - 1]
    var EndMonth = reduxDashboard?.DateArr?.[0]
    setDefaultMonth(StartMonth)

    var SingleMonth = { created_at: { "$gte": 1704047400000, "$lte": 1706725799000 } }
    var MonthRange = { created_at: { "$gte": 1661970600000, "$lte": 1706725799000 } }

    if (json?.search?.created_at === undefined || json?.apiCall === 'init') {
      if (json?.type) {
        Object.assign(json.search, SingleMonth)
        Object.assign(json, { StartMonth: StartMonth?.label })
        if (json?.EndMonth) { delete json.EndMonth }
      } else {
        Object.assign(json.search, MonthRange)
        Object.assign(json, { EndMonth: StartMonth?.label, StartMonth: EndMonth?.label })
      }
    }

    setLoading(true)
    dispatch(setDashboardChartData(null))

    HitApi(json, getAnalyticsDaily).then((res) => {
      if (res?.doc?.docs?.length > 0) {
        CompileFilterData(res?.doc?.docs, json, reduxPagination?.pagination).then((CompiledData) => {
          if (CompiledData) {
            dispatch(setDashboardChartData(CompiledData))
            dispatch(setDashboardListData(CompiledData?.ListData))
            setLoading(false)
          }
        })
      } else {
        dispatch(setDashboardChartData([]))
        dispatch(setDashboardListData([]))
        setLoading(false)
      }
      setLoading(false)
    })
  }


  const handleSelected = (type, value) => {
    if (type === 'view') {
      setSelected(value)
      var ViewSettings = reduxDashboard?.Setting?.ViewSettings
      ViewSettings?.map((ele) => {
        if (ele?.title === value) { ele.status = true }
        else { ele.status = false }
      })
      dispatch(setDashboardSetting(reduxDashboard?.Setting))
    }
  }

  setTimeout(() => {
    setLoading(false)
  }, 60000);

  return (
    <div>
      <div className='card p-4'>
        <div><PageTitle text={'Filters'} /></div>
        <div>
          <div><label>{'Select Month(s)'}</label></div>
          <div className='my-4'><Switch /></div>
        </div>
        <div>
          <div><label>{'Select Vehicle '}</label></div>
          <div className='my-4'><DeviceList /></div>
        </div>
        <div>
          <div><label>{`Choose ${reduxDashboard?.json?.type ? ' Month' : ' one or more months'}`}</label></div>
          <div className='px-8'><CustomSlider /></div>
        </div>
        <div className='my-5'>
          <div className='my-2' ><DistrictAndPackageFilter districtKey={'district'} packageKey={'packageId'} json={reduxDashboard?.json} /></div>
        </div>
        <div className='mt-5'>
          {
            loading ? <label className='flex'><span className='spinner is-elastic h-5 w-5 animate-spin rounded-full border-[3px] border-primary/30 border-r-primary'></span>&nbsp;Please wait...</label> :
              <button className='border-2 border-primary rounded-full bg-primary/10 text-primary px-10 py-2' onClick={() => GetData()}>Update</button>
          }
        </div>
      </div>
      <div>
        {
          reduxDashboard?.chart !== null ? reduxDashboard?.chart?.ActiveVechileData ? <div>
            {
              loading ? <div className='mt-10'></div> : <div className='mt-10 mb-2'>
                <div className='flex'>{view?.map((ele, index) => <div key={index} className={`card mr-4 py-2 px-4 ${ele === selected ? 'bg-primary/10 text-primary' : ''}`} onClick={() => handleSelected('view', ele)}>{ele}</div>)}</div>
              </div>
            }
            <div className='card p-4'>
              <div>
                {
                  loading ? <LoadingTableData /> : reduxDashboard?.chart !== null ? reduxDashboard?.Setting?.ViewSettings?.map((ele) => ele?.status ? <div>{ele['file']}</div> : null) : null
                }
              </div>
            </div>
          </div> : <NoDataFound /> : null
        }
      </div>


    </div>
  )
}
