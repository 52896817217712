export const SET_DASHBOARD_CHART_DATA = 'SET_DASHBOARD_CHART_DATA'
export const SET_DASHBOARD_LIST_DATA = 'SET_DASHBOARD_LIST_DATA'
export const SET_DASHBOARD_JSON = 'SET_DASHBOARD_JSON'
export const SET_DASHBOARD_MMI_DEVICES = 'SET_DASHBOARD_MMI_DEVICES'
export const SET_DASHBOARD_SERVER_DEVICES = 'SET_DASHBOARD_SERVER_DEVICES'
export const SET_DASHBOARD_DATE_ARR = 'SET_DASHBOARD_DATE_ARR'
export const SET_DASHBOARD_SETTING = 'SET_DASHBOARD_SETTING'
export const SET_DASHBOARD_MAIN_DATA = 'SET_DASHBOARD_MAIN_DATA'
export const SET_DASHBOARD_TH = 'SET_DASHBOARD_TH'


export const setDashboardMMIDevices = (data) => ({
    type: SET_DASHBOARD_MMI_DEVICES,
    value: data
});

export const setDashboardServerDevices = (data) => ({
    type: SET_DASHBOARD_SERVER_DEVICES,
    value: data
});

export const setDashboardJson = (data) => ({
    type: SET_DASHBOARD_JSON,
    value: data
});

export const setDashboardChartData = (data) => ({
    type: SET_DASHBOARD_CHART_DATA,
    value: data
});

export const setDashboardListData = (data) => ({
    type: SET_DASHBOARD_LIST_DATA,
    value: data
});

export const setDashboardDateArr = (data) => ({
    type: SET_DASHBOARD_DATE_ARR,
    value: data
});

export const setDashboardSetting = (data) => ({
    type: SET_DASHBOARD_SETTING,
    value: data
});

export const setDashboardMainData = (data) => ({
    type: SET_DASHBOARD_MAIN_DATA,
    value: data
});

export const setDashboardTh = (data) => ({
    type: SET_DASHBOARD_TH,
    value: data
});
