import { SET_USERS , SET_ADD_USER_JSON} from "../../../Action/SuperAdmin/Users/UserAction";

const initialState = {
    doc: null,
    json: {
        page: 1, limit: 10, search: {
            populate: [
                { "path": "dpiu", "select": "" },
            ]
        }, select: ''
    },
    addJson:{},
    timestamp: Date.now()
}

const UserReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_USERS:
            return ({ ...state, doc: action.value, timestamp: Date.now() })
        case SET_ADD_USER_JSON:
                return ({ ...state, addJson: action.value, timestamp: Date.now() })
        default:
            return state;
    }
}

export default UserReducer;