import React from 'react'
import { useSelector } from 'react-redux'
import DeviceList from '../TrackAndPlay/DeviceList';
import { TH } from './TH';

export default function Details() {
  const reduxRoads = useSelector(state => state.RoadReducer)



  const Content = ({ ele }) => {

    var items = ele?.title?.split('-')
    return <label className='w-full flex flex-col px-2  border-gray-300 border-dashed py-1'>
      <span style={{ fontSize: 12 }} className='font-bold text-primary tracking-wide flex flex-col'>{items?.map((Obj, index) => <span key={index}> {Obj} </span>)}</span>
      <span style={{ fontSize: 16 }} className='font-bold'>{reduxRoads?.single?.docs?.[0]?.[ele?.serverTitle]}</span>
    </label>
  }

  return (
    <div className='px-5 py-3'>
      <label className='text-warning text-lg' style={{ fontWeight: 800 }}>Road Details</label>
      <div className='grid grid-cols-3 gap-4 '>
        {TH?.map((ele) => { return <Content key={ele?.serverTitle} ele={ele} /> })}
      </div>
      <div className='border-b-2 border-dashed my-4'></div>
      <div ><DeviceList /></div>
    </div>
  )
}
