import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PanelConstants from '../../Constant/Common/PanelConstants'
import { setSidebar } from '../../Store/Action/SidebarAction'
import { navLinks } from '../NavigationData'
import Routes from '../Routes'
import UserPanel from '../UserPanel/UserPanel'
import { ChevronRight, DarkMode } from '../../Constant/Icon/Icon'
import DetailsHeader from '../../Component/Common/Header/DetailsHeader'
import Progress from '../../Component/Forms/Progress/Progress'
import FloatingButton from '../../Component/Forms/Button/FloatingButton'
import {ChevronDown} from '../../Constant/Icon/Icon'

const SidebarItem = ({ item, onClick }) => {
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
    const [selected, setSelected] = useState('dashboard')
    const hasSubItems = item.child && item.child.length > 0;
    var url = window.location.pathname
    
    const handleSubMenuToggle = () => {
        setIsSubMenuOpen((prevState) => !prevState);
    };

    return (
        <li className='text-slate-600'>
            {
                hasSubItems ?
                    <div>
                        <button className="ac-trigger flex w-full items-center justify-between py-2 text-xs+ tracking-wide text-slate-600 outline-none transition-[color,padding-left] duration-300 ease-in-out hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50" onClick={handleSubMenuToggle}>
                            <span className="flex px-3.5 py-3.5">{item.icon}&nbsp;{item.title}</span>
                            {isSubMenuOpen?ChevronDown:ChevronRight}
                        </button>
                        {isSubMenuOpen && (
                            <ul>
                                {item.child.map((subItem, index) => (
                                    <SidebarItem key={index} item={subItem} onClick={onClick} />
                                ))}
                            </ul>
                        )}
                    </div>
                    : <div>
                        <a href={item.url} onClick={onClick} className={`${url === (item.url)?'bg-primary/10 text-primary border-l-4 border-primary':''}  px-3.5 ${'windowUrl' === item.url ? 'text-primary' : ''} py-3.5 nav-link ${item.color !== undefined ? item.color : ''} flex py-2 text-sm  items-center tracking-wide outline-none transition-colors duration-300 ease-in-out hover:bg-primary/10 hover:text-primary hover:border-l-4 hover:border-primary hover:cursor-pointer hover:font-semibold dark:text-navy-200 dark:hover:text-navy-50`}>
                            {item.icon}&nbsp;{item.title}
                        </a>
                        {item._id === 99 ? <div className="my-3 mx-4 h-px bg-slate-200 dark:bg-navy-500"></div> : null}
                    </div>
            }
        </li>
    );
};


export default function Main() {
    const dispatch = useDispatch()
    const reduxUser = useSelector(state => state.AuthReducer)
    const reduxSidebar = useSelector(state => state.SidebarReducer)
    const [name, setName] = useState(null)
    const [localNav, setLocalNav] = useState([])


    let reduxsidebarData = { doc: ''}

    useEffect(() => {
        if (name === null && reduxUser?.doc) {
            var tp = PanelConstants.find(Obj => Obj.StateName === reduxUser?.doc?.stateName)?.title
            setName(tp)
        }
        if (reduxUser?.doc !== null && localNav.length === 0) {
            UserPermission()
        }

    }, [reduxUser])

    const UserPermission = () => {
        var findOne = navLinks.filter((Obj) => Obj.permission.includes(reduxUser?.doc?.userType))
        findOne.map((element) => { return Object.assign(element, { active: true }) })
        setLocalNav(findOne)
    }

    const handleSidebar = (value) =>{
        dispatch(setSidebar(value))
    }
    const handleItemClick = (e) => {}



    return (
        <div className={reduxSidebar?.doc}>
            
            <div id="root" className="min-h-100vh flex grow bg-slate-50 dark:bg-navy-900 relative overflow-hidden" >
                <div className="sidebar sidebar-panel print:hidden">
                    <div className="flex h-full grow flex-col border-r border-slate-150 bg-white dark:border-navy-700 dark:bg-navy-750">
                        <div className="flex justify-between px-3 pt-4 pb-2" style={{ borderBottom: '1px solid lightgrey' }}>
                            <h1 className="text-xl ml-2"> {name + ' ' + reduxUser?.doc?.userType?.toUpperCase()} </h1>
                            <button className="sidebar-toggle ml-0.5 flex h-7 w-7 flex-col justify-center space-y-1.5 text-primary outline-none focus:outline-none dark:text-accent-light/80" onClick={() => handleSidebar(reduxSidebar.doc === 'is-sidebar-open' ? '' : 'is-sidebar-open')}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </button>
                        </div>

                        <div className="nav-wrapper my-5 h-[calc(100%-4.5rem)] overflow-x-hidden pb-6" data-simplebar="init"><div className="simplebar-wrapper" style={{ margin: '0px 0px -24px' }}><div className="simplebar-height-auto-observer-wrapper"><div className="simplebar-height-auto-observer"></div></div><div className="simplebar-mask"><div className="simplebar-offset" style={{ right: '0px', bottom: '0px' }}>
                            <div className="simplebar-content-wrapper" tabIndex="0" role="region" aria-label="scrollable content" style={{ height: '100%', overflow: 'hidden' }}><div className="simplebar-content" style={{ padding: '0px 0px 24px' }}>
                                <ul>
                                    {localNav.map((item, index) => (
                                        item.active ? <SidebarItem key={index} item={item} onClick={handleItemClick} /> : null
                                    ))}
                                </ul>
                            </div>
                            </div></div></div><div className="simplebar-placeholder" style={{ width: '259px', height: '618px' }}></div></div><div className="simplebar-track simplebar-horizontal" style={{ visibility: 'hidden' }}><div className="simplebar-scrollbar" style={{ width: '0px', display: 'none' }}></div></div><div className="simplebar-track simplebar-vertical" style={{ visibility: 'hidden' }}><div className="simplebar-scrollbar" style={{ height: '0px', display: 'none' }}></div></div></div>
                    </div>
                </div>
                <nav className=" header  print:hidden relative flex flex-col" style={{zIndex : 1000}}>
                    <div className="header-container relative flex w-full bg-white dark:bg-navy-700 print:hidden">
                        <div className="flex w-full items-center justify-between h-16">
                            <div className="h-7 w-7">
                                {
                                    reduxSidebar?.doc === '' ? <button className="sidebar-toggle ml-0.5 flex h-7 w-7 flex-col justify-center space-y-1.5 text-primary outline-none focus:outline-none dark:text-accent-light/80" onClick={() => handleSidebar(reduxsidebarData.doc === 'is-sidebar-open' ? '' : 'is-sidebar-open')}>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </button> : null
                                }

                            </div>
                            <div className="-mr-1.5 flex items-center space-x-2">
                                <div><button className="darkmode-toggle btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">{DarkMode}</button></div>
                                <div><UserPanel /></div>
                            </div>
                        </div>
                    </div>
                    <DetailsHeader/>
                    <Progress/>
                    <FloatingButton/>
                </nav>
                <main className="main-content px-[var(--margin-x)] pb-8 w-full">
                    <div className="space-x-0 py-5 lg:py-6">
                        <Routes />
                    </div>
                </main>
            </div>
            
        </div>
    )
}
