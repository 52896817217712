import React, { useState } from 'react'
import CustomTable from '../../../../Component/Table/CustomTable'
import { TH } from './TH'
import { TH as UserTH } from '../../Users/TH'
import { useDispatch, useSelector } from 'react-redux'
import CustomTh from '../CustomTH'
import LoadingTableData from '../../../../Component/Common/Loading/LoadingTableData'
import CustomModal from '../../../../Component/Modal/CustomModal'
import AddUser from '../../Users/Add/AddUser'
import { setAddUserJson, setUserData } from '../../../../Store/Action/SuperAdmin/Users/UserAction'
import { HitApi } from '../../../../Store/Action/CommonApi/ApiAction'
import { getUsers } from '../../../../Constant/Api/Constant'
import Users from '../../Users/Users'
import { CompileUserData } from '../../Users/UserPromiss'
import { setPagination } from '../../../../Store/Action/Pagination/Pagination'

export default function Piu() {
    const dispatch = useDispatch()
    const reduxZone = useSelector(state => state.ZoneReducer)
    const reduxUser = useSelector(state => state.UserReducer)
    const reduxAuth = useSelector(state => state.AuthReducer)
    const reduxPagination = useSelector(state => state.PaginationReducer)
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState('hide')

    let item;

    if (reduxZone?.piu !== null) {
        item = reduxZone?.piu?.docs?.map((element, i) => {
            return <tr key={i} className='cursor-pointer'>
                <td className={`text-center py-4`}>{element?.index}</td>
                <td className={` py-4 text-center`}>{element?.zoneId} || </td>
                <td className={` py-4 text-center`}>{element?.dpiuId} || </td>
                <td className={` py-4 text-center`}>{element?._id} || </td>
                <td className={` py-4 text-center`}>{element?.name}</td>
                <td className={` py-4 text-center`}>{element?.userType}</td>
                <td className={` py-4 text-center`}><label className='cursor-pointer hover:underline' onClick={() => handleUserList(element)}>Show Assigned Users</label></td>
                <td className={` py-4 text-center`}>
                    <label className="px-4 cursor-pointer hover:text-success">
                        <button className="btn bg-primary/10 text-primary hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25 " onClick={() => handleNavigate(element)}>Assign User</button>
                    </label>
                </td>
            </tr>
        })
    }

    const handleNavigate = (ele) => {
        setShow('show')
        var json = reduxUser?.addJson
        Object.assign(json, {
            userType: 'piu',
            stateName: ele?.state,
            zoneId: ele?.zoneId,
            zoneName: ele?.zoneName,
            dpiuId: ele?.dpiuId,
            dpiuName: ele?.dpiuName,
            piuId: ele?._id,
            piuName: ele?.name,
            department_type: 'PWD',
            imz_login: { state: 'Assam', token: '0a5c9b92-c75b-48d6-8e45-533d760b4e7a', },
            status: 'Active',
            created_by: reduxAuth?.doc?._id,
            districtName: ele?.value,
            assignPackages: ele?.packages
        })



        dispatch(setAddUserJson(json))
    }

    const handleUserList = (ele) => {

        var json = { page: 1, limit: 100, search: { userType: 'piu', piu: ele?._id }, populate: [{ path: 'piu', select: '' }, { path: 'piu', populate: [{ path: "packages", select: 'StateName Package_no' }] }] }

        HitApi(json, getUsers).then((res) => {
            if (res?.doc?.docs) {
                CompileUserData(res?.doc, reduxPagination?.pagination).then((CompileData) => {
                    if (CompileData) {
                        var tp = reduxPagination?.pagination
                        tp.totalRecords = CompileData?.totalDocs
                        tp.totalPages = CompileData?.totalPages
                        dispatch(setPagination(tp))
                        dispatch(setUserData(CompileData))
                        setLoading(false)
                    }
                })
            }
        })
    }

    return (
        <div className='my-4'>
            {
                reduxZone?.piu !== null ? <div>
                    <div className='flex justify-between'>
                        <h2 class="rizzui-title-h2 font-semibold mb-2 text-[22px] lg:text-2xl 4xl:text-[26px] text-gray-900 custom-font">PIU List <span className='font-bold text-teal-600'>{reduxZone?.dpiu?.docs?.[0]?.name}</span></h2>
                        {/* <label className="cursor-pointer hover:text-success">
                            <button className="btn bg-primary/10 text-primary hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25 ">Create PIU</button>
                        </label> */}
                    </div>
                    <div className='my-2 card'>
                        <table className={`is-hoverable w-full `}>
                            <thead><CustomTh TH={TH} /></thead>
                            <tbody style={{ maxHeight: 550 }} className=''>
                                {loading ? <tr><td colSpan={TH?.length < 12 ? TH?.length : 12}><LoadingTableData /></td></tr> : item}
                            </tbody>
                        </table>
                    </div>
                </div> : null
            }
            <Users />
            <CustomModal title={'Assign User'} show={show} setShow={setShow} data={
                <div className='p-5'>
                    <AddUser setShow={setShow} />
                </div>
            } />

        </div>
    )
}
