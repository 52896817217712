import React, { useEffect, useState } from 'react'
import { GoogleMap, useLoadScript, Marker, Polyline, MarkerClusterer } from '@react-google-maps/api';
import { useDispatch, useSelector } from 'react-redux';
import MovingMarker from './MovingMarker';
import { DecompileGeoJson, GenerateCenter, GetCenter } from './CustomGoogleMapPromiss';
import LoadingTableData from '../Common/Loading/LoadingTableData';
import MarkerCluster from './MarkerCluster';
import { setMarker, setRoute } from '../../Store/Action/Map/MapAction';
import TrackAndPlayMarker from './TrackAndPlayMarker';
import PageTitle from '../Common/Title/PageTitle';

const libraries = ['places'];
const mapContainerStyle = {
  width: '100%',
  height: '60vh',
};

export default function CustomGoogleMap({ zoom, WithoutDoc, from, imei }) {
  const dispatch = useDispatch()
  const reduxMap = useSelector(state => state.MapReducer)
  const reduxTrackData = useSelector(state => state.TrackAndPlayDatePickerReducer)
  const [center, setCenter] = useState(null)

  useEffect(() => {
    if (center === null && reduxMap?.LiveTrack && WithoutDoc !== true) {
      GetCenter(reduxMap?.LiveTrack).then((Result) => {
        if (Result) {
          setCenter(Result)
        }
      })
    }
    else if (center === null && reduxMap?.doc && WithoutDoc && from === 'mapview') {
      GenerateCenter(reduxMap?.doc, from).then((res) => {
        if (res?.status) {
          setCenter(res?.center)
          dispatch(setMarker(res?.markers))
        }
      })
    }
    else if (center === null && reduxMap?.doc && WithoutDoc !== true && from === 'roads') {
      GenerateCenter(reduxMap?.doc, from).then((res) => {
        if (res?.status) {
          setCenter(res?.center)
          dispatch(setRoute(res?.route))
        }
      })
    }
    else if (center && reduxTrackData?.doc && from === 'liveview') {
      GetCenter(reduxTrackData?.doc).then((Result) => {
        if (Result) {
          setCenter(Result)
        }
      })
    }
  }, [reduxMap, center])
  const { isLoaded, loadError } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyBq3vDY_h8v85geg1bltO7O6YcSyf07STU',
    libraries,
  });

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }


  return (
    <div>
      {/* {
        center !== null ? center?.lat && center?.lng ? <GoogleMap mapContainerStyle={mapContainerStyle} zoom={zoom || 8} center={center}>
          {reduxMap?.LiveTrack ? <Polyline path={reduxMap?.LiveTrack} geodesic={true} options={{ strokeColor: "#0f53fe", strokeOpacity: 2.0, strokeWeight: 3, }} /> : null}
          {reduxMap?.route ? <Polyline path={reduxMap?.route} geodesic={true} options={{ strokeColor: "#0f53fe", strokeOpacity: 2.0, strokeWeight: 3, }} /> : null}
          {reduxMap?.geoJson ? <Polyline path={reduxMap?.geoJson} geodesic={true} options={{ strokeColor: "red", strokeOpacity: 2.0, strokeWeight: 3, }} /> : null}
          {reduxMap?.marker?.length > 0 ? <MarkerCluster /> : null}
          {imei && reduxTrackData?.doc === null ? <MovingMarker imei={imei} /> : null}
          {reduxTrackData?.doc ? <Polyline path={reduxTrackData?.doc} geodesic={true} options={{ strokeColor: "red", strokeOpacity: 2.0, strokeWeight: 3, }} /> : null}
          {reduxTrackData?.doc && reduxTrackData?.start ? <TrackAndPlayMarker track={reduxTrackData?.doc} /> : null}
        </GoogleMap> : <PageTitle text={`No data found, kindly change the date range`} className={'p-10 flex justify-center text-error'}/> :<div className="col-span-3 border border-slate-300"><LoadingTableData msg={'We are processing map data...'} /></div>
      } */}
      {
        center !== null ? <GoogleMap mapContainerStyle={mapContainerStyle} zoom={zoom || 8} center={center}>
          {reduxMap?.LiveTrack ? <Polyline path={reduxMap?.LiveTrack} geodesic={true} options={{ strokeColor: "#0f53fe", strokeOpacity: 2.0, strokeWeight: 3, }} /> : null}
          {reduxMap?.route ? <Polyline path={reduxMap?.route} geodesic={true} options={{ strokeColor: "#0f53fe", strokeOpacity: 2.0, strokeWeight: 3, }} /> : null}
          {reduxMap?.geoJson ? <Polyline path={reduxMap?.geoJson} geodesic={true} options={{ strokeColor: "red", strokeOpacity: 2.0, strokeWeight: 3, }} /> : null}
          {reduxMap?.marker?.length > 0 ? <MarkerCluster /> : null}
          {imei && reduxTrackData?.doc === null ? <MovingMarker imei={imei} /> : null}
          {reduxTrackData?.doc ? <Polyline path={reduxTrackData?.doc} geodesic={true} options={{ strokeColor: "red", strokeOpacity: 2.0, strokeWeight: 3, }} /> : null}
          {reduxTrackData?.doc && reduxTrackData?.start ? <TrackAndPlayMarker track={reduxTrackData?.doc} /> : null}
        </GoogleMap> : <div className="col-span-3 border border-slate-300"><LoadingTableData msg={'We are processing map data...'} /></div>
      }
    </div>
  )
}
