import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SelectWithRegister from '../../../Component/Select/SelectWithRegister'
import StateList from '../../../Constant/Common/StateList'
import Select from '../../../Component/Select/Select'
import FieldTitle from '../../../Component/Common/Header/FieldTitle'
import { HitApi, setApiResult } from '../../../Store/Action/CommonApi/ApiAction'
import { getZone } from '../../../Constant/Api/Constant'

function UserControl({ register, errors, searchJson }) {
    const dispatch = useDispatch()
    const reduxApiReducer = useSelector(state => state.ApiReducer)

    useEffect(() => {

    }, [])

    const handleDataLoad = (e, key, loadType) => {
        if(loadType!==null){
            var json = reduxApiReducer?.json
            Object.assign(json.search, { [key]: e.target.value, userType: loadType })
            HitApi(json, getZone).then((res) => {

                if (res?.doc?.docs) {
                    SaveValueToRedux(res?.doc?.docs, loadType)
                }
            })
        }
    }

    const SaveValueToRedux = (value, loadType) => {
        var result = reduxApiReducer?.result
        if (value) {
            value?.map((ele) => Object.assign(ele, { title: ele?.name, value: ele?._id, status: true }))
            Object.assign(result, { [loadType]: value })
            dispatch(setApiResult(result))
        }
        // if(key!=='state'){
        //     var findValue = reduxUsers.result[key].find(Obj=>Obj.name === value)

        //     if(findValue!==undefined){
        //         dispatch(UpdateUserJson(reduxUsers.json, key, findValue._id))
        //     }
        // }
    }

    return (
        <div >
            <div className='grid grid-cols-4 gap-4'>
                <div className='col-span-1'>
                    <div><FieldTitle text={'State'} /></div>
                    <div><SelectWithRegister option={StateList} defaultText={'Select State'} register={register} field={'state'} onChange={(e) => handleDataLoad(e, 'state', 'zone')} /></div>
                </div>
                <div className='col-span-3'>
                    {
                        reduxApiReducer.json.userType === 'piu' ?
                            <div className='grid grid-cols-4 gap-4'>
                                <div><Select option={reduxApiReducer?.result?.zone || []} defaultText={'Select Zone'} onChange={(e) => handleDataLoad(e, 'zone', 'dpiu')} /></div>
                                <div><Select option={reduxApiReducer?.result?.dpiu || []} defaultText={'Select DPIU'} onChange={(e) => handleDataLoad(e, 'dpiu', 'piu')} /></div>
                                <div><Select option={reduxApiReducer?.result?.piu || []} defaultText={'Select PIU'} onChange={(e) => handleDataLoad(e, 'piu', null)} /></div>
                            </div> : reduxApiReducer.json.userType === 'dpiu' ? <div className='grid grid-cols-4 gap-4'>
                                <div><Select option={reduxApiReducer?.result?.zone || []} defaultText={'Select Zone'} onChange={(e) => handleDataLoad(e, 'zone', 'dpiu')} /></div>
                                <div><Select option={reduxApiReducer?.result?.dpiu || []} defaultText={'Select DPIU'} onChange={(e) => handleDataLoad(e, 'dpiu', 'piu')} /></div>
                            </div> : reduxApiReducer.json.userType === 'zone' ? <div className='grid grid-cols-4 gap-4'>
                                <div><Select option={reduxApiReducer?.result?.zone || []} defaultText={'Select Zone'} onChange={(e) => handleDataLoad(e, 'zone', 'dpiu')} /></div>
                            </div> : null
                    }

                </div>
            </div>
        </div>
    )
}

export default UserControl