import axios from "axios";
import { GetToken, MapToken } from "../../Constant/Api/Constant";

export const GetMMIToken = () => {
    const MyPromiss = new Promise((resolve, reject) => {
        // GetToken
    })

    return MyPromiss;
}

export const GetCenter = (data, from) => {
    const MyPromiss = new Promise((resolve, reject) => {
        //     if (data?.[0]) {

        //         if (from === 'roads') {
        //             var coordinates = data?.[0]?.geometry?.coordinates
        //             var tl = coordinates?.length
        //             var tc = coordinates?.[Math.round(tl / 2)]
        //             if (tl) {
        //                 GenerateRoute(data).then((res) => {
        //                     if (res) {
        //                         resolve({ status: true, center: [tc?.[1], tc?.[0]], route: res })
        //                     } else {
        //                         resolve({ status: false, center: [28.5419808, 77.2060235], route: [] })
        //                     }
        //                 })
        //             } else {
        //                 resolve({ status: false, center: [28.5419808, 77.2060235], route: [] })
        //             }
        //         } else {
        //             var coordinates = data?.[0]?.geometry?.coordinates?.[0]
        //             var tl = coordinates?.length
        //             var tc = coordinates?.[Math.round(tl / 2)]

        //             if (tl) {
        //                 GenerateRoute(data).then((res) => {
        //                     if (res) {
        //                         resolve({ status: true, center: [tc?.[1], tc?.[0]], route: res })
        //                     } else {
        //                         resolve({ status: false, center: [28.5419808, 77.2060235], route: [] })
        //                     }
        //                 })
        //             } else {
        //                 resolve({ status: false, center: [28.5419808, 77.2060235], route: [] })
        //             }
        //         }

        //     }
        // })

        // New v2 codes

        if (!data?.[0]) {
            resolve({ status: false, center: [28.5419808, 77.2060235], route: [] });
            return;
        }




        const coordinates = from === 'roads' ? data[0]?.geometry?.coordinates : data[0]?.geometry?.coordinates?.[0];
        const tl = coordinates?.length;
        const tc = coordinates?.[Math.round(tl / 2)];
        if (!tl) {
            resolve({ status: false, center: [28.5419808, 77.2060235], route: [] });
            return;
        }

        GenerateRoute(data, from).then((res) => {
            resolve({
                status: !!res,
                center: [tc?.[1], tc?.[0]],
                route: res || []
            });
        });

    })

    return MyPromiss;
}

export const GenerateRoute = (data, from) => {
    const MyPromiss = new Promise((resolve, reject) => {
        // var tempArr = []
        // if (data?.length) {
        //     data?.map((ele) => {
        //         var tc = ele?.geometry?.coordinates?.[0]
        //         tc?.map((innerEle) => {
        //             tempArr.push([innerEle?.[1], innerEle?.[0]])
        //         })
        //     })
        //     resolve(tempArr)
        // }
        // resolve(false)

        // New V2 code

        if (!data?.length) {
            resolve(false);
            return;
        }
        
        const tempArr = [];
        data.forEach((ele) => {
            const coordinates = from === 'roads' ? ele?.geometry?.coordinates : ele?.geometry?.coordinates?.[0];
            if (coordinates) {
                coordinates.forEach((innerEle) => {
                    tempArr.push([innerEle?.[1], innerEle?.[0]]);
                });
            }
        });
        
        resolve(tempArr);
        
    })
    return MyPromiss;

}

export const CompileTrackDate = (data) => {
    const MyPromiss = new Promise((resolve, reject) => {
        var td = []
        var locationData = []
        data?.map((ele) => {
            var tj = {}
            Object.assign(tj, {
                address: ele?.address,
                lat: ele?.latitude,
                lng: ele?.longitude,
                status: ele?.movementStatus,
                speed: ele?.speed
            })
            td.push(tj)
            locationData.push({ lat: ele?.latitude, lng: ele?.longitude })

        })
        resolve({ data: td, locationData: locationData })
    })
    return MyPromiss;
}