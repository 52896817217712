import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetAlertLogs, GetRoads, LiveDeviceData, MMIToken, getDevice, getMMIData } from '../../Constant/Api/Constant'
import { AxiosHitApi, HitApi } from '../../Store/Action/CommonApi/ApiAction'
import { CompileDeviceIds, CompileLiveAlerts } from './CompileLiveAlerts'
import { GetDistrictConditionData, GetTimestampBasedOnStartEnd } from '../../Utils/Utils'
import TempData from './TempData'
import PageTitle from '../../Component/Common/Title/PageTitle'
import CustomTable from '../../Component/Table/CustomTable'
import { TH } from './TH'
import { setLiveAlertsData } from '../../Store/Action/LiveAlerts/LiveAlertsAction'
import StateList from '../../Constant/Common/StateList'
import Data from './response.json'

export default function LiveAlerts() {
    const dispatch = useDispatch()
    const reduxLiveAlerts = useSelector(state => state.LiveAlertReducer)
    const reduxUser = useSelector(state => state.AuthReducer)
    const reduxPagination = useSelector(state => state.PaginationReducer)
    const [loading, setLoading] = useState(false)
    const [deviceList, setDeviceList]=  useState(null)

    

    useEffect(() => {
        // if (reduxLiveAlerts?.doc === null) {
        //     loadLiveAlerts('inint')
        // }

        if(deviceList === null && reduxUser?.doc){
            GetDevieIDs()
        }

        

    //    if(!reduxLiveAlerts?.doc){
    //     CompileLiveAlerts(TempData, reduxPagination?.pagination).then((CompledData) => {
    //         if (CompledData) {

    //             dispatch(setLiveAlertsData(CompledData))
    //         }
    //     })
    //    }



    }, [reduxLiveAlerts])

    const GetDevieIDs = () =>{
        var query = GetDistrictConditionData(reduxUser?.doc, 'districtName')
        var json={
            page : 1,limit : 50, search : {}, populate:[{path:'devices', select:'id'}],
            select : "Package_no StateName devices districtName"
        }
        Object.assign(json?.search, {
            StateName: reduxUser?.doc?.stateName
        })

        // if(Data){
        //     CompileDeviceIds(Data).then((Compiled)=>{
        //         if(Compiled){
        //             loadLiveAlerts('init', Compiled)
        //         }
        //     })
        // }


        
        HitApi(json, GetRoads).then((res)=>{

            if(res?.doc?.docs){
                CompileDeviceIds(res?.doc?.docs).then((Compiled)=>{
                    if(Compiled){
                        loadLiveAlerts('init', Compiled)
                    }
                })
            }
        })
    }


    const loadLiveAlerts = (type,Compiled) => {
        var stateName = reduxUser?.doc?.stateName
        var syncName = StateList.find((ele) => ele.title === stateName)?.syncName
        var json = {
            "syncName": syncName,
            'api': GetAlertLogs+Compiled,
        }


        HitApi(json, getMMIData).then((res) => {

            if (res?.doc?.data) {
                CompileLiveAlerts(res?.doc?.data, reduxPagination?.pagination).then((CompledData) => {
                    if (CompledData) {

                        // var tp = reduxPagination?.pagination
                        // tp.totalRecords = CompledData?.totalDocs
                        // tp.totalPages = CompledData?.totalPages
                        // dispatch(setPagination(tp))
                        // dispatch(setRoads(CompledData))
                        // setLoading(false)
                    }
                })
            }
        })
    }


    


    return (
        <div>
            <div><PageTitle text={'Alert Logs'} /></div>
            <div className={'mt-5'}>
                <CustomTable
                    // header={true}
                    // loading={loading}
                    TH={TH}
                    data={reduxLiveAlerts?.doc}
                    // pagination={reduxPagination?.pagination}
                    // paginationType={'server'}
                    // apiHit={loadRoads}
                    // handleClick={handleClick}
                    // json={reduxRoads?.json}
                    // screen={'road'}
                    // fileName={('roads')}
                    // dateFilter={true}
                />
            </div>
        </div>
    )
}
