import React, { useEffect, useState } from 'react'
import PageTitle from '../../Component/Common/Title/PageTitle'
import { useDispatch, useSelector } from 'react-redux'
import { HitApi } from '../../Store/Action/CommonApi/ApiAction'
import { IMZBaseURL } from '../../Constant/Api/Constant'
import { CompileMapInstalledData } from './MapViewPromiss'
import InstalledDevicesData from './Response.json'
import LiveLocationData from './Resords.json'
import InstallDevicesMap from '../../Component/Map/MapMyIndia'
import LoadingTableData from '../../Component/Common/Loading/LoadingTableData'
import { setMapData, setMarker } from '../../Store/Action/Map/MapAction'
import MapMyIndiaLocalToken from '../../Component/Map/MapMyIndiaLocalToken'
import { IMZTH } from './IMZTH';
import LocalCustomTable from '../../Component/Table/LocalCustomTable'
import CustomGoogleMap from '../../Component/GoogleMap/CustomGoogleMap'
import MapViewTable from './MapViewTable'

export default function MapView() {
    const dispatch = useDispatch()
    const reduxUser = useSelector(state => state.AuthReducer)
    const reduxMap = useSelector(state => state.MapReducer)
    const reduxFilter = useSelector(state => state.FilterReducer)

    const reduxPagination = useSelector(state => state.PaginationReducer)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (reduxMap?.doc === null && reduxUser?.doc !== null) {
            loadData()
        }
    }, [])

    const loadData = () => {
        var json = {
            "requesttype": "DEVICEVIEW", "vendorcode": "IMZ", "request": {
                username: reduxUser?.doc?.imz_login[0]?.username,
                pin: reduxUser?.doc?.imz_login[0]?.pin, "ipaddress": "196.195.156.107", "clienttype": "web"
            }
        }
        // if (InstalledDevicesData?.[0]) {
        //     loadLiveLocation(InstalledDevicesData)
        // }

        HitApi(json, IMZBaseURL).then((res)=>{
            if(res?.response?.data?.[0]){
                loadLiveLocation(res?.response?.data)
            }
        })
    }

    const loadLiveLocation = (InstalledDevicesData) => {
        var json = {
            requesttype: "MAPVIEW",
            vendorcode: "IMZ",
            request: {
                username: reduxUser?.doc?.imz_login[0]?.username,
                pin: reduxUser?.doc?.imz_login[0]?.pin,
                ipaddress: "2401:4900:1f30:af7:e5de:3e6a:8915:ca14",
                clienttype: "web",
                accid: reduxUser?.doc?.imz_login[0]?.accid,
                type: "",
                transtype: "",
            },
        };

        // if (LiveLocationData?.[0]) {
        //     CompileMapInstalledData(InstalledDevicesData, LiveLocationData, reduxPagination?.pagination).then((result) => {
        //         // if (result?.features) {
        //         //     dispatch(setMapData(result))
        //         //     dispatch(setMarker(result))
        //         // }

        //         if (result) {
        //             dispatch(setMapData(result))
        //             dispatch(setMarker(result))
        //         }
        //     })
        // }

        HitApi(json, IMZBaseURL).then((res) => {
            if (res?.response?.records?.[0]) {
                CompileMapInstalledData(InstalledDevicesData, res?.response?.records, reduxPagination?.pagination).then((result)=>{
                    if (result?.features) {
                        dispatch(setMapData(result))
                        dispatch(setMarker(result))
                    }
                })
            }

        })

    }

    const handleClick = (ele) => {
        window.open( '/mapview/live/' + ele?.imei,'_blank');
    }
    const handleTrackPlay = (ele) => {
        window.open( '/mapview/track-and-play/' + ele?.imei,'_blank');
        
    }

    return (
        <div>
            <div><PageTitle text={'All Installed Devices (in MAP)'} /></div>
            {/* {reduxMap?.doc ? <div><MapMyIndiaLocalToken height={600} WithoutDoc={true} /></div> : <div className="col-span-3 border border-slate-300"><LoadingTableData msg={'We are processing map data...'} /></div>}<div className='mt-10 mb-2'><PageTitle text={'All Installed Devices (in Table)'} /></div> */}

            {reduxMap?.doc ? <CustomGoogleMap WithoutDoc={true} from="mapview" zoom={6} /> :  <div className="col-span-3 border border-slate-300"><LoadingTableData msg={'We are processing map data...'} /></div>}
            <div>
                {reduxMap?.marker ? <MapViewTable
                    header={true}
                    defaultDropdown={true}
                    searchType={'local'}
                    json={reduxFilter?.localFilterJson}
                    loading={loading}
                    TH={IMZTH}
                    data={reduxMap?.doc?.MainData}
                    paginationType={'local'}
                    pagination={reduxPagination?.pagination}
                    fileName={'Installed Devices'}
                    handleClick={handleClick}
                    handleTrackPlay={handleTrackPlay}
                /> : null}
            </div>
        </div>
    )
}
