import moment from "moment";
import { GenerateAllDays, GenerateMonths, GetFullYear, GetMonth, GetMonthName, GetPageCount } from "../../../Utils/Utils"
import React from "react";
import { Colors } from "../../../Constant/Style/StyleConstant";
import { isUndefined } from "underscore";
import { CreatePagination } from "../../../Store/Action/Pagination/Pagination";


export const CompileFilterData = (data, json, pagination) => {
    return new Promise((resolve, reject) => {
        // True = Single , False = Range
        
        data?.map((ele, i) =>
         {
            Object.assign(ele, {startDate:ele?.created_at})
            var ommas = ele?.roadId?.ommas?.[0]
            Object.assign(ele, { 
                MonthName: GetMonthName(ele?.startDate), 
                Day: GetFullYear(ele?.startDate), 
                round: (parseFloat(ele?.trips?.[0]?.round).toFixed(2)) || 0, 
                distance: (parseFloat(ele?.trips?.[0]?.distance).toFixed(2)) || 0,
                efficency: (parseFloat(ele?.efficency).toFixed(2))|| 0,
                index : GetPageCount(pagination?.limit, pagination?.page, i),
                piuName : ommas?.PIU_NAME,
                contractorName : ommas?.CONTRACTOR_NAME,
                roadCode : ommas?.Road_Code,
                roadName : ommas?.ROAD_NAME,
                running : ele?.running>100 ? 100 : ele?.running,
                exeDate : GetFullYear(ele?.created_at)
            })
         }
        )
        var type = json?.type
        if (type) {
            SingleMonthChartData(data, json, pagination).then((res) => {
                if (res) {
                    resolve(res)
                }
            })
        } else {
            MonthRangeChartData(data, json, pagination).then((res) => {
                if (res) {
                    resolve(res)
                }
            })
        }
    })

}


export const SingleMonthChartData = (data, json, pagination) => {
    const MyPromiss = new Promise((resolve, reject) => {
        var newDate = new Date(json?.StartMonth)
        var NoOfDays = GenerateAllDays(newDate)
        var MonthName = GetMonth(newDate) // JAN 1 , 29-01-2023
        var YearName = newDate.getFullYear()
        var tempDate = []
        NoOfDays?.map((ele) => tempDate.push(ele + "-" + MonthName + '-' + YearName))


        var td = []

        tempDate?.map((ele) => {
            var tempArr = data?.filter((Obj) => Obj.Day === ele)
            var tempData = tempArr.find(Obj => Obj.Day === ele);



            td.push({
                Day: ele,//29-01-2023
                data: tempArr,
                rounds: tempData?.trips?.[0]?.round || 0,
                totalKm: tempData?.trips?.[0]?.distance / 1000 || 0,
                devices: [... new Set(tempArr.map(x => x?.report_id_lite?.device_id))],
                efficency: tempData?.efficency
            })
        }
        )






        var series = []
        if(json?.search?.vehType==='Static Roller'){
            series.push({
                name: 'Distance Covered By Vehicle (in M)',
                data: td?.map((ele) => Math.round(ele?.totalKm))
            },
            {
                name: 'Total Rounds',
                data: td?.map((ele) => Math.round(ele?.rounds))
            })
        }else{
            series.push({
                name: 'Distance Covered By Vehicle (in M)',
                data: td?.map((ele) => Math.round(ele?.totalKm))
            })
        }

        var activeVehicles = [
            {
                name: 'Active Vehicles',
                data: td?.map((ele) => ele?.devices?.length)
            },
        ]
        var EfficiencyOfVehicleSeries = []
        td?.map((ele) => {
            var eff = parseFloat(ele?.efficency).toFixed(2)
            var tr = Math.round(eff) || 0
            EfficiencyOfVehicleSeries.push({
                y: tr,
                color: tr > 50 ? Colors?.good : tr > 25 ? Colors?.fair : Colors?.poor
            })
        })

        var TestingDemoData = [
            {
                name : 'Effi',
                data: td?.map((ele) => Math.round(ele?.efficency) || 0)
            }
        ]

        // Pie Chart Data Construction


        var PieChartData = [... new Set(data.map(x => x?.workName))].map(workName => {
            var filteredData = data?.filter((Obj)=>Obj.workName === workName)
            return Object.assign({
                name : workName,
                workName: workName,
                data: filteredData,
                Percentage : (filteredData?.length/data?.length) * 100,
                y : (filteredData?.length/data?.length) * 100,
                running : 100,
                stoppage : 50,
                color : Colors[workName]
            })
        });

        var running = data.reduce((n, { running }) => n + Math.round(running), 0)
        var stopage = data.reduce((n, { stopage }) => n + Math.round(stopage), 0)

        var total = running + stopage
        var RunningVsStoppage = [
            {
                name : 'Running',
                y : (running/total)*100,
                color : Colors?.good
            },{
                name : 'Stopage',
                y : (stopage/total)*100,
                color : Colors?.poor
            }
        ]

        // Create List Data

        var ListData = CreatePagination(data, 10)
        pagination.totalPages = ListData?.paginate?.length

        var ChartData = {
            MainData: { ChartYAxis: series, ChartXAxis: tempDate, Data : data },
            ActiveVechileData: { ChartYAxis: activeVehicles, ChartXAxis: tempDate, Data : data },
            Data1: { ChartYAxis: { name: `Efficiency of ${json?.search?.vehType}`, data: EfficiencyOfVehicleSeries }, ChartXAxis: tempDate, Data : data },
            PieChartData : PieChartData,
            RunningVsStoppage : RunningVsStoppage,
            ListData : ListData
            
        }
        resolve(ChartData)
    })
    return MyPromiss
}

export const MonthRangeChartData = (data, json, pagination) => {
    const MyPromiss = new Promise((resolve, reject) => {
        var td = []

        var tempMonths = GenerateMonths(json?.StartMonth, json?.EndMonth)

        tempMonths?.map((ele) => {
            var tempArr = data?.filter((Obj) => Obj.MonthName.toLowerCase() === ele.toLowerCase())
            var n = 0;
            var tk = 0;
            var tempEff = 0;

            var rounds = tempArr.forEach(Obj => {
                var eff = parseFloat(Obj?.efficency || 0).toFixed(2)
                n = n + (Obj?.trips?.[0]?.round !== null ? Obj?.trips?.[0]?.round : 0)
                tk = tk + (Obj?.trips?.[0]?.distance !== null ? Obj?.trips?.[0]?.distance : 0)
                tempEff = parseFloat(tempEff) + parseFloat(eff)
            })

            


            td.push({
                MonthName: ele,
                data: tempArr,
                district: [... new Set(tempArr.map(x => x.district))],
                devices: [... new Set(tempArr.map(x => x?.report_id_lite?.device_id))],
                efficency: tempEff / tempArr?.length,
                efficencyNot: tempEff,
                totalKm: tk / 1000,
                rounds: n
            })
        }
        )


        var series = []

        if(json?.search?.vehType==='Static Roller'){
            series.push({
                name: 'Distance Covered By Vehicle (in M)',
                data: td?.map((ele) => Math.round(ele?.totalKm))
            },
            {
                name: 'Total Rounds',
                data: td?.map((ele) => Math.round(ele?.rounds))
            })
        }else{
            series.push({
                name: 'Distance Covered By Vehicle (in M)',
                data: td?.map((ele) => Math.round(ele?.totalKm))
            })
        }

        var activeVehicles = [
            {
                name: 'Active Vehicles',
                data: td?.map((ele) => ele?.devices?.length)
            },
        ]

        var EfficiencyOfVehicleSeries = []



        td?.map((ele) => {
            var tr = Math.round(parseFloat(ele?.efficency).toFixed(2)) || 0
            EfficiencyOfVehicleSeries.push({
                y: tr,
                color: tr > 60 ? Colors?.good : tr > 25 ? Colors?.fair : Colors?.poor
            })
        })

        var TestingDemoData = [
            {
                name : 'Effi',
                data: td?.map((ele) => Math.round(ele?.efficency) || 0)
            }
        ]

        // Pie Chart Data Construction

        var PieChartData = [... new Set(data.map(x => x?.workName))].map(workName => {
            var filteredData = data?.filter((Obj)=>Obj.workName === workName)
            return Object.assign({
                name : workName,
                workName: workName,
                data: filteredData,
                Percentage : (filteredData?.length/data?.length) * 100,
                y : (filteredData?.length/data?.length) * 100,
                running : 100,
                stoppage : 50,
                color : Colors[workName]
            })
        });

        var running = data.reduce((n, { running }) => n + Math.round(running), 0)
        var stopage = data.reduce((n, { stopage }) => n + Math.round(stopage), 0)

        var total = running + stopage
        var RunningVsStoppage = [
            {
                name : 'Running',
                y : (running/total)*100,
                color : Colors?.good
            },{
                name : 'Stopage',
                y : (stopage/total)*100,
                color : Colors?.poor
            }
        ]

        // Create List Data

        var ListData = CreatePagination(data, 10)
        pagination.totalPages = ListData?.paginate?.length

        var ChartData = {
            MainData: {
                ChartYAxis: series, ChartXAxis: tempMonths
            },
            ActiveVechileData: { ChartYAxis: activeVehicles, ChartXAxis: tempMonths },
            Data1: { ChartYAxis: { name: `Efficiency of ${json?.search?.vehType}`, data: EfficiencyOfVehicleSeries } , ChartXAxis: tempMonths },
            PieChartData : PieChartData,
            RunningVsStoppage : RunningVsStoppage,
            ListData : ListData
        }
        resolve(ChartData)
    })
    return MyPromiss
}