import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useSelector } from 'react-redux'
import { Colors } from '../../../../Constant/Style/StyleConstant'

export default function CustomPieChart({title, serverKey}) {

    const reduxDashboard = useSelector(state => state.DashboardReducer)
    
    const options = {
        chart: {
            borderWidth: 1,
            borderColor: '#eee',
            plotBorderWidth: 0,
            plotShadow: false,
            type: 'pie'
        },
        title: {
            text: title
        },
        tooltip: {
            pointFormat: '<b>{point.percentage:.1f}%</b>'
        },
        plotOptions: {
            pie: {
                size: '60%',
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b><br><b>{point.percentage:.1f} %</b> ',
                    style: {
                        fontWeight: 'normal'
                    },
                    
                },
                colors: [Colors?.good],
                showInLegend: true
            }
        },
        series: [{
            name: 'Teams',
            colorByPoint: true,
            data: reduxDashboard?.chart?.[serverKey]
        }]
    }

    return (
        <div >
            {/* <div className='py-5 bg-white border-dotted border'>
                <label className='flex justify-center w-full pb-4'>Chart Legends</label>
                <div className='grid grid-cols-3 gap-4 text-center'>
                    <div><label><span className='px-2 mr-2' style={{ backgroundColor: Colors?.good }}></span>Good</label></div>
                    <div><label><span className='px-2 mr-2' style={{ backgroundColor: Colors?.fair }}></span>Fair</label></div>
                    <div><label><span className='px-2 mr-2' style={{ backgroundColor: Colors?.poor }}></span>Poor</label></div>
                </div>
            </div> */}
            <HighchartsReact highcharts={Highcharts} options={options}/>
        </div>
    )
}
