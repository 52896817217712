import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MyText from '../../../../../Component/Common/Text/MyText';
import TrackAndPlay from './TrackAndPlay';
import { setDeviceForTrackPlay } from '../../../../../Store/Action/TrackAndPlay/TrackAndPlayAction';

export default function DeviceList() {
    const dispatch = useDispatch()
    const [selected, setSelected] = useState(null)
    const reduxRoads = useSelector(state => state.RoadReducer)
    let data = reduxRoads?.single?.docs?.[0]

    useEffect(() => {



    }, [])

    const handleSelect = (DeviceData) => {
        setSelected(DeviceData?.trackingCode)
        dispatch(setDeviceForTrackPlay(DeviceData))
    }

    return (
        <div className='pb-6 border-dashed'>
            <label className='text-warning text-lg' style={{ fontWeight  : 800}}>List of Installed Devices</label>
            <div className='grid grid-cols-3 gap-4 pt-4'>
                {data?.devices?.map((ele, index) =>
                    <label key={index} className='w-full flex flex-col px-2 border border-gray-300 border-dotted py-1'>
                        <span style={{ fontSize: 12 }} className='font-bold text-primary tracking-wide'>{'Device Type : '+ele?.vehtype}</span>
                        <span style={{ fontSize: 10 }} className='font-bold'>Tracking No : {ele?.imei}</span>
                    </label>
                )}
            </div>
        </div>
    )
}
