import React, { useEffect, useState } from 'react'

import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import { useDispatch, useSelector } from 'react-redux'
import { FilterOptions } from '../List/FilterSwithData'
import { setDashboardTh } from '../../../../Store/Action/Dashboard/DashboardAction'


export default function CustomChart({ title, serverKey, showMoreBtn, show, setShow, _id }) {
    const dispatch = useDispatch()
    const reduxDashboard = useSelector(state => state.DashboardReducer)

    const [render, setRender] = useState(null)

    useEffect(() => {
       
        setRender(Date.now())
        
    }, [reduxDashboard])

    const options = {
        chart: {
            type: 'column'
        },
        title: {
            text: title
        },
        xAxis: {
            title: {
                text: 'Start Date'
            },
            categories: reduxDashboard?.chart?.[serverKey]?.ChartXAxis,
            crosshair: true,
            accessibility: {
                description: 'Countries'
            }
        },
        yAxis: {
            min: 0,
            title: {
                text: null
            },
            stackLabels: {
                enabled: true
            }
        },

        // series: [{ name: temp?.ChartYAxis?.name, data: temp?.ChartYAxis?.data }],
        series: reduxDashboard?.chart?.[serverKey]?.ChartYAxis,
        plotOptions: {
            column: {
                dataLabels: {
                    stacking: 'normal',
                    enabled: true,
                }
            }
        },
    }

    const handleClick = (value) => {
        var FindOne = FilterOptions?.find((Obj)=>Obj?._id === _id)
        if(FindOne){
            var th = reduxDashboard?.TH
            th = th.map(item => ({...item,status: FindOne?.serverKey.includes(item.serverKey)}));
            dispatch(setDashboardTh(th))
        }
        Object.assign(reduxDashboard?.json, { selected: _id })

        if (show === '') {
            setShow('show')
        } else {
            setShow('')
        }
    }
    return (
        <div>
            {showMoreBtn ? <div className='text-right'><label className='cursor-pointer text-primary' onClick={() => handleClick(title)}>View in Details</label></div> : null}
            <div>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                />
            </div>
        </div>
    )
}
