import React, { useState } from 'react'
import { useSelector } from 'react-redux';

export default function DetailsHeader() {
  const reduxAnalyticsData = useSelector(state => state.AnalyticsReducer)
  let Complete = reduxAnalyticsData?.complete?.docs?.[0]
  let Daily = reduxAnalyticsData?.daily?.docs?.[0]

  // reduxData?.selectedDevice?.imei

  const [ishover, sethover] = useState(25);

  const MouseOver = () => {
    sethover(Complete?.roadName.length)
  }
  const MouseOut = () => {
    sethover(25)
  }

  const handleDate = (value, type) => {
    if (type === 'startDate') {

    } else {

    }
  }

  return (
    <div className='border-t w-full'>
      {
        Complete && reduxAnalyticsData?.selectedDevice?.imei ? <div style={{ backgroundColor: '#fff', padding: 10 }}>
          <div className='flex justify-between text-center items-center'>
            <label className='w-full flex flex-col px-2 border-r border-gray-300 border-dashed'>
              <span style={{ fontSize: 12 }} className='font-bold text-primary tracking-wide'>Package No</span>
              <span style={{ fontSize: 16 }} className='font-bold'>{Complete?.packageNo}</span>
            </label>
            <label className='w-full flex flex-col px-2 border-r border-gray-300 border-dashed'>
              <span style={{ fontSize: 12 }} className='font-bold text-primary tracking-wide'>Road Code</span>
              <span style={{ fontSize: 16 }} className='font-bold'>{Complete?.roadCode}</span>
            </label>
            <label className='w-full flex flex-col px-2 border-r border-gray-300 border-dashed' data-tooltip="Default">
              <span style={{ fontSize: 12 }} className='font-bold text-primary tracking-wide'>Road Name</span>
              <span style={{ fontSize: 16, width: 300 }} className='font-bold' onMouseOver={MouseOver} onMouseOut={MouseOut}>{Complete?.roadName?.substring(0, ishover) + (ishover === 25 ? '...' : '')}</span>
            </label>
            <label className='w-full flex flex-col px-2 border-r border-gray-300 border-dashed'>
              <span style={{ fontSize: 12 }} className='font-bold text-primary tracking-wide'>PIU Name</span>
              <span style={{ fontSize: 16 }} className='font-bold'>{Complete?.piuName || '---'}</span>
            </label>
            <label className='w-full flex flex-col px-2 border-r border-gray-300 border-dashed'>
              <span style={{ fontSize: 12 }} className='font-bold text-primary tracking-wide'>Contractor Name</span>
              <span style={{ fontSize: 16 }} className='font-bold'>{Complete?.contractorName || '---'}</span>
            </label>
            <label className='w-full flex flex-col px-2 border-r border-gray-300 border-dashed'>
              <span style={{ fontSize: 12 }} className='font-bold text-primary tracking-wide'>Sanctioned Length (in KM)</span>
              <span style={{ fontSize: 16 }} className='font-bold'>{Complete?.sanctionLength || '---'}</span>
            </label>
            <label className='w-full flex flex-col px-2  border-gray-300 border-dashed'>
              <span style={{ fontSize: 12 }} className='font-bold text-primary tracking-wide'>Award Date</span>
              <span style={{ fontSize: 16 }} className='font-bold'>{Complete?.awardDate || '---'}</span>
            </label>
          </div>
        </div> : null
      }

    </div>
  )
}
