import React, { useEffect, useState } from 'react'
import CustomMarker from './CustomMarker';
import { setTrackAndPlayCurrentTrack } from '../../Store/Action/TrackAndPlay/TrackAndPlayAction';
import { useDispatch } from 'react-redux';

let cursor = 0;

export default function TrackAndPlayMarker(props) {
    const dispatch = useDispatch()
    const [currentTrack, setCurrentTrack] = useState(null);

    useEffect(() => {
        setCurrentTrack(props.track[cursor]);
        const interval = setInterval(() => {
            if (cursor === props.track.length - 1) {
                cursor = 0;
                setCurrentTrack(props.track[cursor]);
                return;
            }
            cursor += 1;
            setCurrentTrack(props.track[cursor]);
            dispatch(setTrackAndPlayCurrentTrack(props.track[cursor]))
        }, (300));

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <div>{currentTrack !== null ? <CustomMarker ele={currentTrack ?? {}} /> : null}</div>
    )
}
