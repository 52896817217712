import React, { useState } from 'react'
import Item from './Item'
import { useSelector } from 'react-redux'
import _ from 'underscore'

function DateCard() {
    const reduxData = useSelector(state => state.AnalyticsReducer) 
    const [selected, setSelected] = useState([])

  return (
    <div className='flex overflow-x-auto'>
        {_.sortBy(reduxData?.daily?.docs,'startTime')?.map((ele, index)=><div key={index} className=''> <Item index={index} ele={ele} selected={selected} setSelected={setSelected}/> </div>)}
    </div>
  )
}

export default DateCard