import { getAnalyticsDaily } from "../../../Constant/Api/Constant";
import { VehicleIcon } from "../../../Constant/Icon/Icon";

export default [
    { _id: 0, icon: VehicleIcon, title: 'Static Roller', api: getAnalyticsDaily, json: { page: 1, limit: 0, search: { vehType: 'Static Roller', "trips.round": { '$gt': 0 } }, populate: [], lite: true }, status: true },
    { _id: 0, icon: VehicleIcon, title: 'Pad Foot Roller', api: getAnalyticsDaily, json: { page: 1, limit: 0, search: { vehType: 'Pad Foot Roller', "trips.round": { '$gt': 0 } } }, status: true },
    { _id: 0, icon: VehicleIcon, title: 'Chip Spreader', api: getAnalyticsDaily, json: { page: 1, limit: 0, search: { vehType: 'Chip Spreader', "trips.round": { '$gt': 0 } } }, status: true },
    { _id: 0, icon: VehicleIcon, title: 'Cement Spreader', api: getAnalyticsDaily, json: { page: 1, limit: 0, search: { vehType: 'Cement Spreader', "trips.round": { '$gt': 0 } } }, status: true },
    { _id: 0, icon: VehicleIcon, title: 'Pneumatic Roller', api: getAnalyticsDaily, json: { page: 1, limit: 0, search: { vehType: 'Pneumatic Roller', "trips.round": { '$gt': 0 } } }, status: true },
    { _id: 0, icon: VehicleIcon, title: 'Tandem Roller', api: getAnalyticsDaily, json: { page: 1, limit: 0, search: { vehType: 'Tandem Roller', "trips.round": { '$gt': 0 } } }, status: true },
    { _id: 0, icon: VehicleIcon, title: 'Reclimer', api: getAnalyticsDaily, json: { page: 1, limit: 0, search: { vehType: 'Reclimer', "trips.round": { '$gt': 0 } } }, status: true },
    { _id: 0, icon: VehicleIcon, title: 'Excavator', api: getAnalyticsDaily, json: { page: 1, limit: 0, search: { vehType: 'Excavator', "trips.round": { '$gt': 0 } }, populate: [], lite: true }, status: true },
    { _id: 0, icon: VehicleIcon, title: 'JCB', api: getAnalyticsDaily, json: { page: 1, limit: 0, search: { vehType: 'JCB', "trips.round": { '$gt': 0 } }, populate: [], lite: true }, status: true },
    { _id: 0, icon: VehicleIcon, title: 'Loader', api: getAnalyticsDaily, json: { page: 1, limit: 0, search: { vehType: 'Loader', "trips.round": { '$gt': 0 } }, populate: [], lite: true }, status: true },
    { _id: 0, icon: VehicleIcon, title: 'Paver', api: getAnalyticsDaily, json: { page: 1, limit: 0, search: { vehType: 'Paver', "trips.round": { '$gt': 0 } }, populate: [], lite: true }, status: true },
    { _id: 0, icon: VehicleIcon, title: 'PTR', api: getAnalyticsDaily, json: { page: 1, limit: 0, search: { vehType: 'PTR', "trips.round": { '$gt': 0 } }, populate: [], lite: true }, status: true },
    { _id: 0, icon: VehicleIcon, title: 'Tractor', api: getAnalyticsDaily, json: { page: 1, limit: 0, search: { vehType: 'Tractor', "trips.round": { '$gt': 0 } }, populate: [], lite: true }, status: true },
    { _id: 0, icon: VehicleIcon, title: 'Truck', api: getAnalyticsDaily, json: { page: 1, limit: 0, search: { vehType: 'Truck', "trips.round": { '$gt': 0 } }, populate: [], lite: true }, status: true },
]