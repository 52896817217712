import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { HitApi } from '../../../../Store/Action/CommonApi/ApiAction'
import { getAnalyticsDaily, getCompleteRrdaReportsLite } from '../../../../Constant/Api/Constant'
import { CompileDetailsAnalytics, CompileTrackData } from './DetailsPromiss'
import { setPagination } from '../../../../Store/Action/Pagination/Pagination'
import { setCompleteAnalytics, setDailyAnalytics, setDataForDelete, setWork } from '../../../../Store/Action/Analytics/AnalyticsAction'
import PageTitle from '../../../../Component/Common/Title/PageTitle'
import CustomTable from '../../../../Component/Table/CustomTable'
import ChildTableTH from './ChildTableTH'
import Sidebar from '../../../../Component/Sidebar/Sidebar'
import WorkDetails from '../../../../Component/Reports/WorkDetails'
import { CompileCompleteAnalytics } from '../CompleteAnalyticsPromiss'
import CustomChart from '../../../../Component/Chart/CustomChart'
import CustomPieChart from '../../../../Component/Chart/CustomPieChart'
import LoadingTableData from '../../../../Component/Common/Loading/LoadingTableData'
import DateCard from '../../../../Component/DateCard/DateCard'
import DeleteModal from '../../../../Component/Modal/DeleteModal'
import { setMapData } from '../../../../Store/Action/Map/MapAction'
import CustomGoogleMap from '../../../../Component/GoogleMap/CustomGoogleMap'
import Efficiencychart from '../../../../Component/Chart/Efficiencychart'
import DeviceAnalytics from '../../DeviceAnalytics/DeviceAnalytics'

export default function DetailsOld() {
    const dispatch = useDispatch()
    const reduxData = useSelector(state => state.AnalyticsReducer)
    const reduxUser = useSelector(state => state.AuthReducer)
    const reduxPagination = useSelector(state => state.PaginationReducer)
    const [id, setId] = useState(null)
    const [show, setShow] = useState(null)
    const [loading, setLoading] = useState(false)
    const [localData, setLocalData] = useState(null)
    const [EnableTrack, setEnableTrack] = useState(false)
    const [selected, setSelected] = useState(null)

    useEffect(() => {
        var tempId = window.location.pathname.split('/')
        if (reduxData?.complete === null) {
            var packageId = tempId[tempId?.length - 1]
            loadCompleteData('init', packageId)
        } else if (reduxData?.daily === null && reduxData?.complete && localData === null) {
            var tempId = window.location.pathname.split('/')
            var packageId = tempId[tempId?.length - 1]
            setId(packageId)
            loadData('init', packageId)
        }

        if (reduxData?.selectedDevice !== null) {
            var findTh = ChildTableTH?.find((Obj) => Obj.serverKey === 'Passes')
            if (findTh?.status && reduxData?.selectedDevice?.type !== 'Static Roller') {
                findTh.status = false
            } else if (reduxData?.selectedDevice?.type === 'Static Roller') {
                findTh.status = true
            }
        }
    }, [reduxData])

    // reduxData is removed from rerendering

    const loadCompleteData = (type, packageId) => {
        var json = reduxData?.completeJson
        if (type === 'init') {
            Object.assign(json.search, {
                state: reduxUser?.doc?.stateName,
                packageId: packageId,
            })
        }
        Object.assign(json, {
            page: reduxPagination?.pagination?.page,
            limit: 1,
        })
        HitApi(json, getCompleteRrdaReportsLite).then((res) => {
            if (res?.doc?.docs) {
                CompileCompleteAnalytics(res?.doc, reduxPagination?.pagination, dispatch).then((CompledData) => {
                    if (CompledData) {
                        dispatch(setCompleteAnalytics(CompledData))
                        dispatch(setMapData(CompledData?.docs?.[0]?.mapData))
                        // setLoading(false)

                    }
                })
            }
        })
    }

    const loadData = (type, packageId) => {
        var json = reduxData?.dailyJson
        if (type === 'init') {
            Object.assign(json.search, {
                state: reduxUser?.doc?.stateName,
                packageId: packageId
            })

            if (reduxData?.selectedDevice?.type === 'Static Roller' || reduxData?.selectedDevice?.type === 'Roller') {
                Object.assign(json.search, {
                    "totalKm": { '$gt': 0 },
                    "trips.0": { $exists: true },
                    "trips.largest": { '$gte': 50 },
                    "trips.largest": { '$lte': 1800 },
                    "$or": [{ vehType: "Static Roller" }],
                })
                Object.assign(json.search,{workName: "Earthwork"})

            } else {
                delete json?.search?.["totalKm"]
                delete json?.search?.["trips.0"]
                delete json?.search?.["trips.largest"]
                delete json?.search?.["trips.largest"]
                // Object.assign(json.search,{workName: "WBM Grading 3"})
            }
        }
    
        json.limit = reduxData?.selectedDevice?.type === 'Static Roller' ? 50 : 2000

        Object.assign(json, {
            page: reduxPagination?.pagination?.page,
            limit: reduxPagination?.pagination?.limit,
        })
        CallApi(json)
    }

    const CallApi = (json) => {
        setLoading(true)

        HitApi(json, getAnalyticsDaily).then((res) => {
            if (res?.doc?.docs) {
                CompileDetailsAnalytics(res?.doc, reduxPagination?.pagination).then((CompledData) => {
                    
                    if (CompledData?.tp) {
                        var tp = reduxPagination?.pagination
                        tp.totalRecords = CompledData?.tp?.totalDocs
                        tp.totalPages = CompledData?.tp?.totalPages

                        // Adding Total Work time and Total Work
                        var t_compliedData = CompledData?.tp
                        Object.assign(t_compliedData, {
                            workingTime: res?.roadCover,
                            movement: res?.movement
                        })
                        
                        dispatch(setPagination(tp))
                        dispatch(setDailyAnalytics(t_compliedData))
                        dispatch(setWork(CompledData?.workSet))
                        setLoading(false)
                    }
                })
            }
        })
    }

    const handleDelete = (element, event) => {
        var td = reduxData?.dataToBeDelete
        var findEle = td?.find((Obj) => Obj._id === element?._id)
        if (findEle) {
            var index = td?.findIndex((Obj) => Obj._id === element?._id)
            if (index !== -1) {
                var tempData = td.splice(index, 1)
                dispatch(setDataForDelete(td))
            }
        } else {
            td.push(element)
            dispatch(setDataForDelete(td))
        }
    }

    const handleDeleteAction = () => {
        setShow('show')
    }

    const handleClick = () => {
        setEnableTrack(!EnableTrack)
    }
    return (
        <div>
            <div className='mt-32'></div>
            <div><DeviceAnalytics selected={selected} setSelected={setSelected} setLoading={setLoading} /></div>
            <div className='my-4'><PageTitle text={'Compaction Report of Static Roller'} /></div>
            <div className='grid grid-cols-6 gap-4'>
                <div className='col-span-1'><Sidebar loading={loading} setLoading={setLoading} apiHit={loadData} /></div>
                {
                    reduxData?.view === 'chart' && reduxData?.daily ?
                        <div className='col-span-5'>
                            <div className={`grid ${loading ? `grid-cols-4` : `${reduxData?.selectedDevice?.type === 'Static Roller' ? 'grid-cols-4' : 'grid-cols-2'} `} gap-4`}>
                                <div>{loading ? <LoadingTableData /> : <WorkDetails />}</div>
                                <div>{loading ? <LoadingTableData /> : <Efficiencychart />}</div>
                                {loading ? <div><LoadingTableData /></div> : reduxData?.selectedDevice?.type === 'Static Roller' ? <CustomChart /> : null}
                                {loading ? <div><LoadingTableData /></div> : reduxData?.selectedDevice?.type === 'Static Roller' ? <CustomPieChart /> : null}
                                {/* <div>{loading ? <LoadingTableData /> : <CustomPieChart />}</div> */}
                            </div>
                        </div> :
                        <div className='col-span-5 '>
                            {reduxData?.selectedDevice?.type !== 'Static Roller' ? null : <div className='bg-slate-150 py-5 '> <DateCard /> </div>}
                            <div><CustomGoogleMap zoom={13} from={'roads'} /></div>
                        </div>
                }
            </div>
            {reduxData?.dataToBeDelete?.[0] ? <button className='btn border border-error font-medium text-error hover:bg-error hover:text-white focus:bg-error focus:text-white active:bg-error/90' onClick={() => handleDeleteAction()}> Delete Selected Reports </button> : null}
            <div className={'mt-5'}>
                <CustomTable
                    loading={loading}
                    TH={ChildTableTH}
                    data={reduxData?.daily?.docs}
                    pagination={reduxPagination?.pagination}
                    paginationType={'server'}
                    apiHit={loadData}
                    json={reduxData?.dailyJson}
                    enableDeleteBtn={true}
                    handleDelete={handleDelete}
                    handleClick={handleClick}
                />
            </div>
            <DeleteModal show={show} setShow={setShow} />
        </div>
    )
}
