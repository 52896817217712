import { SET_FILTER, SET_LOCAL_FILTER } from "../../Action/Filter/FilterAction";

const initialState = {
    doc: { districtName: null, Package_no: null },
    localFilterJson: {page:1, limit : 5000, search:{}},
    timestamp: Date.now()
}

const FilterReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_FILTER:
            return ({ ...state, doc: action.value, timestamp: Date.now() })
        case SET_LOCAL_FILTER:
            return ({ ...state, localFilterJson: action.value, timestamp: Date.now() })

        default:
            return state;
    }
}

export default FilterReducer;