import React from 'react'
import { ActionIcon, Button, Tooltip } from 'rizzui'
import useConfirm from '../../Dialog/ConfirmDialog';
import { HitApi } from '../../../Store/Action/CommonApi/ApiAction';

export default function RemoveButton({ _id, apiUrl, apiHit }) {
    const confirm = useConfirm()

    const handleDelete = async () => {

        // const choice = await confirm({ title: "Delete", description: "Are you sure to delete this?", confirmBtnLabel: "Yes" });
        // if (choice) {
            
        // }

        const r = window.confirm('Are you sure to delete this?')

        
        

        if (r && _id && apiUrl) {

            var json = { _id:_id }

            HitApi(json, apiUrl).then((res)=>{

                if(res?.status === 200){
                    alert('Removed Successfully')
                    if(apiHit){apiHit()}
                }
            })
        }

    }

    return (
        <div>
            <Tooltip size="md" content={'Delete'} placement="top" color="invert" className='bg-red-500 text-white'>
                <ActionIcon as="span" size="sm" variant="outline" aria-label={'Delete'} className="hover:!border-gray-900 hover:text-gray-700" onClick={() => handleDelete()}>
                    <button class="" type="button" aria-label="Delete Item" > <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 w-4">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"></path>
                    </svg>
                    </button>
                </ActionIcon>
            </Tooltip>
        </div>

    )
}
