import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Item from './Item'

export default function DeviceList() {
    const dispatch = useDispatch()
    const reduxDashboard = useSelector(state=>state.DashboardReducer)

    useEffect(()=>{

    },[])

    return (
        <div className='flex items-center flex-wrap'>
            {reduxDashboard?.DeviceList?.map((element, index)=><div key={index} className='mr-3 mb-3' style={{ width : 180}}><Item element={element}/></div>)}
        </div>
    )
}
