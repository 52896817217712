import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import { HitApi } from '../../../Store/Action/CommonApi/ApiAction'
import { getUsers } from '../../../Constant/Api/Constant'
import { CompileUserData } from './UserPromiss'
import PageTitle from '../../../Component/Common/Title/PageTitle'
import CustomTable from '../../../Component/Table/CustomTable'
import { TH } from './TH'
import { setUserData } from '../../../Store/Action/SuperAdmin/Users/UserAction'
import { setPagination } from '../../../Store/Action/Pagination/Pagination'

export default function SuperUser() {
  let history = useHistory();
  const dispatch = useDispatch()
  const reduxUserData = useSelector(state => state.UserReducer)
  const reduxUser = useSelector(state => state.AuthReducer)
  const reduxPagination = useSelector(state => state.PaginationReducer)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    
    if (!reduxUserData?.doc) {
      loadData('init')
    }


  }, [reduxUserData])

  const loadData = (type) => {
    var json = reduxUserData?.json
    if (type === 'init') {
      Object.assign(json.search, {
        populate: [
          { path: 'dpiu', select: '' }
        ]
      })
    }
    Object.assign(json, { page: reduxPagination?.pagination?.page, limit: 100, })



    setLoading(true)
    HitApi(json, getUsers).then((res) => {

      if (res?.doc?.docs) {
        CompileUserData(res?.doc, reduxPagination?.pagination).then((CompiledData) => {
          if (CompiledData) {
            var tp = reduxPagination?.pagination
            tp.totalRecords = CompiledData?.totalDocs
            tp.totalPages = CompiledData?.totalPages
            dispatch(setPagination(tp))
            dispatch(setUserData(CompiledData))
            setLoading(false)
          }
        })
      }
    })

  }

  const handleNavigation = () =>{
    history.push("/sup/user/add")
  }

  
  return (
    <div>
      <div><PageTitle text={'List of Users'} /></div>
      <div><label onClick={()=>handleNavigation()}>Add User</label></div>
      <div className={'mt-5'}>
        <CustomTable
          loading={loading}
          TH={TH}
          json={reduxUserData?.json}
          data={reduxUserData?.doc?.docs}
          pagination={reduxPagination?.pagination}
          paginationType={'server'}
          // apiHit={loadData}
          fileName={('roads')}
          screen={'superuser'}
        />
      </div>
    </div>
  )
}
