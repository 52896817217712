// import React from 'react'


// export default function CustomTable({ defaultDropdown, searchType, enableDeleteBtn, handleDelete, TH, data, paginationType, pagination, apiHit, handleClick, loading, header, json, screen, fileName, dateFilter, districtKey, packageKey }) {

//   return (

// <div className='card'>
//   {header && searchType ==='local' ? <LocalFilter json={json} TH={TH} fileName={fileName}/> : <CustomHeader defaultDropdown={defaultDropdown} TH={TH} json={json} apiHit={apiHit} screen={screen} data={data} fileName={fileName} dateFilter={dateFilter} districtKey={districtKey} packageKey={packageKey} />}
//   <div className='overflow-x-auto'>
//     <table className={`is-hoverable w-full text-left ${paginationType==='server'?'overflow-x-auto':'overflow-hidden'} `}>
//       <thead><CustomTh TH={TH} paginationType={paginationType} /></thead>
//       <tbody style={{ maxHeight : 550}}>
//         {loading ? <tr><td colSpan={TH?.length < 12 ? TH?.length : 12}><LoadingTableData /></td></tr> :
//           (data?.paginate?.[pagination?.page-1]??data)?.map((ele, index) => (
//             <tr key={index} className='cursor-pointer'>
//               {/* {enableDeleteBtn ?<DeleteButton ele={ele} data={reduxData?.dataToBeDelete} handleDelete={handleDelete} enableDeleteBtn={enableDeleteBtn}/> : null} */}
//               {TH?.map((obj, j) => {
//                 return obj.status ? <td key={j} style={{ textAlign: obj?.overrideClass }} className={`${obj?.className ? obj?.className : 'text-center' } ${ele?.ColorClass} py-4`}>
//                   <label className={ele?.[obj.className]} onClick={obj?.serverKey === 'Action' && handleClick ? (event) => handleClick(ele, event) : null}>{ele?.[obj.serverKey] || ele?.[obj.serverKey] !== '' && ele?.[obj.serverKey] !== undefined ? obj?.suffix ? ele?.[obj?.serverKey] + [obj?.suffix] : ele?.[obj?.serverKey] : '---'}</label>
//                 </td> : null
//               })}
//             </tr>
//           ))
//         }
//         {!loading && !data?.[0] && paginationType!=='local' ? <tr className='text-center'><td colSpan={12}><label className='w-full font-bold tracking-wide text-lg m-5'>No Data Found</label></td></tr> : null}
//       </tbody>
//     </table>
//   </div>
//   <div>{pagination?.totalPages ? paginationType === 'server' ? <ServerPagination pagination={pagination} apiHit={apiHit} /> : <LocalPagination pagination={pagination} apiHit={apiHit} /> : null}</div>

// </div>
//   )
// }


import React from 'react'
import CustomTh from './CustomTh'
import ServerPagination from './Pagination/Server/ServerPagination'
import { useDispatch, useSelector } from 'react-redux'
import CustomHeader from './CustomHeader'
import DeleteButton from './Delete/DeleteButton'
import LocalPagination from './Pagination/Local/LocalPagination'
import LocalFilter from './Filter/LocalFilter'
import LoadingTableData from '../Common/Loading/LoadingTableData';

function getNestedProperty(obj, key) {
  if (!key) return undefined;
  return key.split('.').reduce((acc, part) => acc && acc[part], obj);
}

function CustomTable({ defaultDropdown, searchType, enableDeleteBtn, handleDelete, TH, data, paginationType, pagination, apiHit, handleClick, loading, header, json, screen, fileName, dateFilter, districtKey, packageKey }) {

  return (
    <div className='card'>
      {header && searchType === 'local' ? <LocalFilter json={json} TH={TH} fileName={fileName} /> : <CustomHeader defaultDropdown={defaultDropdown} TH={TH} json={json} apiHit={apiHit} screen={screen} data={data} fileName={fileName} dateFilter={dateFilter} districtKey={districtKey} packageKey={packageKey} />}
      <div className='overflow-x-auto'>
        <table className={`is-hoverable w-full text-left ${paginationType === 'server' ? 'overflow-x-auto' : 'overflow-hidden'}`}>
          <thead>
            <CustomTh TH={TH} paginationType={paginationType} />
          </thead>
          <tbody style={{ maxHeight: 550 }}>
            {loading ? (
              <tr>
                <td colSpan={TH?.length < 12 ? TH?.length : 12}>
                  <LoadingTableData />
                </td>
              </tr>
            ) : (
              (data?.paginate?.[pagination?.page - 1] ?? data)?.map((ele, index) => (
                <tr key={index} className='cursor-pointer'>
                  {TH?.map((obj, j) => {
                    const value = getNestedProperty(ele, obj.serverKey);
                    return obj.status ? (
                      <td key={j} style={{ textAlign: obj?.overrideClass }} className={`${obj?.className ? obj?.className : 'text-center'} ${ele?.ColorClass} py-4`}>
                        <label className={ele?.[obj.className]} onClick={obj?.serverKey === 'Action' && handleClick ? (event) => handleClick(ele, event) : null}>
                          {value !== undefined && value !== '' ? obj?.suffix ? value + obj.suffix : value : '---'}
                        </label>
                      </td>
                    ) : null;
                  })}
                </tr>
              ))
            )}
            {!loading && !data?.[0] && paginationType !== 'local' ? (
              <tr className='text-center'>
                <td colSpan={12}>
                  <label className='w-full font-bold tracking-wide text-lg m-5'>No Data Found</label>
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
      <div>{pagination?.totalPages ? paginationType === 'server' ? <ServerPagination pagination={pagination} apiHit={apiHit} /> : <LocalPagination pagination={pagination} apiHit={apiHit} /> : null}</div>
    </div>
  )
}

export default CustomTable