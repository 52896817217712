import React from 'react'
import { useSelector } from 'react-redux';

export default function Progress() {
    let completed = true;
    const reduxData = useSelector(state => state.AnalyticsReducer)
    const Data = useSelector(state => state.AnalyticsReducer?.daily?.docs?.[0])
    var SideBarData = reduxData?.complete?.docs?.[0]?.wt


    const CheckWorkStatus = () =>{
        if(reduxData?.doc){
            var MainDataLength = SideBarData?.length
            var tempDataLength = reduxData?.doc?.length

            var WorkProcessPercentage = (tempDataLength/MainDataLength)*100
            var ProgressBarPercentage = 12 / 100 * WorkProcessPercentage
            return  { WorkProcessPercentage , ProgressBarPercentage }
        }
    }


    return (
        <div>
            {
                Data ? <div className=" progress bg-slate-150 dark:bg-navy-500 rounded-none">
                    <div className={`relative w-${CheckWorkStatus()?.ProgressBarPercentage}/12 overflow-hidden  bg-primary dark:bg-accent`}>
                        <label style={{ color: '#fff', fontSize: 14, float: 'right' }}>
                            <div className={`badge rounded-none ${completed ? 'bg-success text-white' : 'bg-slate-150 text-slate-700'}`}>Work In Progress</div>
                        </label>
                    </div>
                </div> : null
            }
        </div>
    )
}
