export const TH = [
    { id: 0, title: 'Sr. No', serverKey: 'index', className: '', isPdf: true, status: true },
    { id: 0, title: 'ZONE ID', serverKey: 'zoneId', className: '', isPdf: true, status: true },
    { id: 0, title: 'DPIU ID', serverKey: '_id', className: '', isPdf: true, status: true },
    { id: 0, title: 'State-Name', serverKey: 'state', className: '', isPdf: true, status: true },
    { id: 0, title: 'ZONE', serverKey: 'zoneName', className: '', isPdf: true, status: true },
    { id: 0, title: 'DPIU', serverKey: 'name', className: '', isPdf: true, status: true },
    { id: 0, title: 'User-Type', serverKey: 'userType', className: '', isPdf: true, status: true },
    { id: 0, title: 'No. of-PIUs', serverKey: '', className: '', isPdf: true, status: true },
    { id: 0, title: 'No. of-Assigned Users', serverKey: '', className: '', isPdf: true, status: true },
    { id: 0, title: 'Action', serverKey: 'Action', className: '', isPdf: true, status: true },
]
