import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FieldTitle from '../../../../Component/Common/Header/FieldTitle'
import { useDispatch, useSelector } from 'react-redux';
import Select from '../../../../Component/Select/Select';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { HitApi } from '../../../../Store/Action/CommonApi/ApiAction';
import { AddZone, AutoCompleteUPRRDA, GetRoads } from '../../../../Constant/Api/Constant';
import { CompilePackageData } from './DpiuPromiss';
import { addZoneDpuiuPiuJson } from '../../../../Store/Action/SuperAdmin/Zones/ZoneAction';
let inputClass = 'form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2'
let disabledClass = 'form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-slate-200 px-3 py-2'


export default function AddDpiu({setShow, apiHit}) {
    const dispatch = useDispatch()
    const reduxZone = useSelector(state => state.ZoneReducer)
    const [selectedDistrict, setSelectedDistrict] = useState([]);
    const [data, setData] = useState(null)
    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Name is required')
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState, setError } = useForm(formOptions);
    const { errors } = formState;

    // const handleChange = (e) => {
    //     var value = e.target.value
    //     if (value) {
    //         var json = { page: 1, limit: 1000, search: { districtName: value }, select: 'Package_no' }
    //         HitApi(json, GetRoads).then((res) => {

    //             if (res?.doc?.docs) {
    //                 CompilePackageData(res?.doc?.docs).then((CompileData) => {

    //                     if (CompileData) {
    //                         setData(CompileData)
    //                     }
    //                 })
    //             }
    //         })
    //     }
    // }
    const handleChange = (e, fieldName) => {
        var json = reduxZone?.addJson
        Object.assign(json, { [fieldName]: e?.target?.value });
        dispatch(addZoneDpuiuPiuJson(json))
    }

    const handlePromissForDistrict = (value, event) => {
        var data = reduxZone?.addJson?.assignDistrict
        if (event.action === "select-option" && event.option.value === "All") {
            setSelectedDistrict(data);
        } else if (
            event.action === "deselect-option" &&
            event.option.value === "All"
        ) {
            setSelectedDistrict([]);
        } else if (event.action === "deselect-option") {
            setSelectedDistrict(value.filter((o) => o.value !== "All"));
        } else if (value.length === data.length - 1) {
            setSelectedDistrict(data);
        } else {
            setSelectedDistrict(value);
        }
    }

    const onSubmit = (data) => {
        var json = reduxZone?.addJson
        var finalJson = {
            name: json?.name,
            parent: json?.zoneId,
            userType: 'dpiu',
            state: json?.stateName,
            stateCode: json?.stateCode,
            value: selectedDistrict?.filter((o) => o.value !== "All")?.map((ele) => { return ele?.value })
        }
        var isAnyUndefined = Object.values(finalJson).includes(undefined); //Check for undefined

        if (!isAnyUndefined) {

            HitApi(finalJson, AddZone).then((res) => {
                if (res?.doc?._id) {
                    alert('DPIU Added Successfully')
                    setShow('')
                    if(apiHit){apiHit()}
                }
            })
        } else {
            alert('check for errors')
        }


    }

    return (
        <div style={{ minHeight: 700 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='grid grid-cols-4 gap-4 my-3'>
                    <div>
                        <div><FieldTitle text={'State'} /></div>
                        <div><input type='text' value={reduxZone?.addJson?.stateName} {...register('stateName')} className={disabledClass} disabled /></div>
                    </div>
                    <div>
                        <div><FieldTitle text={'ZONE'} /></div>
                        <div><input type='text' value={reduxZone?.addJson?.zoneName} placeholder={`Enter Name`} {...register('zoneName')} className={disabledClass} disabled /></div>
                        <div><span className="text-sm text-error dark:text-navy-300">{errors['zoneName'] !== undefined ? errors['zoneName'].message : null}</span></div>
                    </div>
                    <div >
                        <div><FieldTitle text={'User Type'} /></div>
                        <div><input type='text' value={reduxZone?.addJson?.userType} placeholder={`Enter Name`} {...register('userType')} className={disabledClass} disabled /></div>
                        <div><span className="text-sm text-error dark:text-navy-300">{errors['userType'] !== undefined ? errors['userType'].message : null}</span></div>
                    </div>
                </div>
                <div className='grid grid-cols-4 gap-4 my-3'>
                    {/* <div>
                        <div><FieldTitle text={'Assign District'} /></div>
                        <div><Select option={reduxZone?.addJson?.assignDistrict} onChange={(e) => handleChange(e)} /></div>
                        <div><span className="text-sm text-error dark:text-navy-300">{errors['districtName'] !== undefined ? errors['districtName'].message : null}</span></div>
                    </div> */}
                    <div >
                        <div><FieldTitle text={'Name'} /></div>
                        <div><input type='text' placeholder={`Enter Name`} {...register('name')} className={inputClass} onChange={(e) => handleChange(e, 'name')} /></div>
                        <div><span className="text-sm text-error dark:text-navy-300">{errors['name'] !== undefined ? errors['name'].message : null}</span></div>
                    </div>
                    <div >
                        <div><FieldTitle text={'Assign District'} /></div>
                        {reduxZone?.addJson?.assignDistrict ? <div><ReactMultiSelectCheckboxes placeholderButtonLabel="Select District" options={reduxZone?.addJson?.assignDistrict} onChange={handlePromissForDistrict} value={selectedDistrict} setState={setSelectedDistrict} /></div> : null}
                    </div>
                </div>

                <div className='grid grid-cols-1 gap-4 my-3'>
                    <div className='flex justify-end'>
                        <button className="btn space-x-2 bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90">
                            <span>Create DPIU</span>
                        </button>
                    </div>
                </div>

            </form>
        </div>
    )
}
