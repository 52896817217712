export const TH = [
    { title: 'District Name', serverTitle: 'DISTRICT_NAME', activeClass: 'font-semibold', status : true },
    { title: 'Package No', serverTitle: 'PACKAGE_No', activeClass: 'font-semibold', status : true },
    { title: 'Road Name', serverTitle: 'ROAD_NAME', activeClass: '', status : true },
    { title: 'Road Code', serverTitle: 'Road_Code', activeClass: '', status : true },
    { title: 'Santion Date', serverTitle: 'SANCTION_DATE', activeClass: 'font-semibold', status : true },
    { title: 'Sanction Length', serverTitle: 'SANCTION_LENGTH', activeClass: 'font-semibold', status : true },
    { title: 'Contractor Name', serverTitle: 'CONTRACTOR_NAME', activeClass: '', status : true },
    { title: 'PIU Name', serverTitle: 'PIU_NAME', activeClass: '', status : true },
    { title: 'Activity Name', serverTitle: 'ACTIVITY_NAME', activeClass: 'font-semibold', status : true },
    { title: 'Activity-Completion Date', serverTitle: 'ACTIVITY_COMPLETION_DATE', activeClass: 'font-semibold', status : true },
    { title: 'Activity-Quantity', serverTitle: 'ACTIVITY_QUANTITY', activeClass: 'font-semibold', status : true },
    { title: 'Activity Start Date', serverTitle: 'ACTIVITY_START_DATE', activeClass: 'font-semibold', status : true },
    { title: 'Actual Activity-Completion Date', serverTitle: 'ACTUAL_ACTIVITY_COMPLETION_DATE', activeClass: 'font-semibold', status : true },
    { title: 'Actual Activity-Start Date', serverTitle: 'ACTUAL_ACTIVITY_START_DATE', activeClass: 'font-semibold', status : true },
    { title: 'Award Date', serverTitle: 'AWARD_DATE', activeClass: 'font-semibold', status : true },
    { title: 'Completed-Road Length', serverTitle: 'COMPLETED_ROAD_LENGTH', activeClass: 'font-semibold', status : true },
    { title: 'Completion Date', serverTitle: 'COMPLETION_DATE', activeClass: 'font-semibold', status : true },
    { title: 'Executed Quantity', serverTitle: 'EXECUTED_QUANTITY', activeClass: 'font-semibold', status : true },
    { title: 'PMIS Finalize Date', serverTitle: 'PMIS_FINALIZE_DATE', activeClass: 'font-semibold', status : true },
]