import React from 'react'

function SelectWithRegister(props) {
  return (
    <div className='mb-3'>
        <label className="block">
            <h2 className="font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100 lg:text-base">{props.title}{props.important?<span className='text-error'>&nbsp;*</span>:''}</h2>
            <select
            className="form-select mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
            {...props.register(props.field)}
            onChange={props.onChange}
            >
            {props.defaultText?<option value={''}> {props.defaultText} </option>:null}
              {props.option!==null && props.option.length>0?props.option.map((element, i)=>element.status?<option key={i}>{element.title}</option>:'') : null}
            </select>
        </label>
        <span className="text-small tracking-wide text-error dark:text-error">{props.errors!==undefined?props.errors[props.field]?.message:null}</span>
    </div>
  )
}

export default SelectWithRegister