export const TH = [
    { id: 0, title: 'Sr. No', serverKey: 'index', className: '', isPdf: true, status: true },
    { id: 0, title: 'Username', serverKey: 'username', className: '', isPdf: true, status: true },
    { id: 0, title: 'Full Name', serverKey: 'name', className: '', isPdf: true, status: true },
    { id: 0, title: 'State Name', serverKey: 'stateName', className: '', isPdf: true, status: true },
    { id: 0, title: 'Email', serverKey: 'email', className: '', isPdf: true, status: true },
    { id: 2, title: 'User Type', serverKey: 'userType', className: '', isPdf: true, status: true },
    { id: 2, title: 'Creation Date', serverKey: 'created_at', className: '', isPdf: true, status: true },
    { id: 2, title: 'Status', serverKey: 'status', className: '', isPdf: true, status: true },
    
    { id: 10, title: 'Action', serverKey: 'Action', className: '', isPdf: false, status: true },
]

// userType