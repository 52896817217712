import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setApiJson, setError } from '../../../Store/Action/CommonApi/ApiAction'

export default function Input({ fieldName, parent, register, errors, disabled, className }) {
    const dispatch = useDispatch()
    const reduxApiReducer = useSelector(state => state.ApiReducer)
    
    const handleChange = (e) => {
        var json = reduxApiReducer?.json
        if (parent) {
            json[parent] = json[parent] || {};
            Object.assign(json[parent], { [fieldName]: e?.target?.value });
          } else {
            Object.assign(json, { [fieldName]: e?.target?.value });
          }
        dispatch(setApiJson(json))

        CheckError()

    }

    const CheckError = () => {
        // var error = reduxApiReducer?.error
        // var json = reduxApiReducer?.json
        // if(json?.fieldName){
        //     Object.assign(error,{[fieldName]:`${fieldName} is Required`})
        //     dispatch(setError(error))
        // }
    }



    return (
        <div className='flex flex-col'>
            <input type='text' value={reduxApiReducer?.json?.[fieldName]} className={className} onChange={(e) => handleChange(e)}
                //  {register?register(fieldName):null}
                // {...props?.register(fieldName)}

                disabled={disabled} />
            {disabled ? <span className='text-error text-xs'>This field cannot be edited</span> : null}
            <div><span className="text-sm text-error dark:text-navy-300">{reduxApiReducer?.error?.[fieldName] !== undefined ? reduxApiReducer?.error?.[fieldName]?.message : null}</span></div>

        </div>
    )
}
