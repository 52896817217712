export default [

    { id: 0, title: 'S.NO', serverKey: 'index', className: '', isPdf: true, status: true },
    { id: 0, title: 'Action', serverKey: 'Action', className: '', isPdf: false, status: true },
    { id: 1, title: 'District', serverKey: 'districtName', className: '', isPdf: true, status: true },
    { id: 1, title: 'PIU Name', serverKey: 'piuName', className: '', isPdf: true, status: true },
    { id: 1, title: 'Contractor-Name', serverKey: 'contractorName', className: '', isPdf: true, status: true },
    { id: 1, title: 'Road-Code', serverKey: 'roadCode', className: '', isPdf: true, status: true },
    { id: 1, title: 'Road-Name', serverKey: 'roadName', className: 'text-left', isPdf: true, status: true },
    { id: 1, title: 'Package-Number', serverKey: 'packageNo', className: '', isPdf: true, status: true },
    { id: 1, title: 'Sanctioned-Length (in KM)', serverKey: 'sanctionedLength', className: '', isPdf: true, status: true },
    { id: 1, title: 'Executed-Quantity (in KM)', serverKey: 'excutedQty', className: '', isPdf: true, status: true },
    { id: 1, title: 'Award Date', serverKey: 'awardDate', className: '', isPdf: true, status: true },
    { id: 1, title: 'Actual Activity-Complation Date', serverKey: 'actiualActivityCompletionDatetName', className: '', isPdf: true, status: true },
    { id: 1, title: 'Start Point', serverKey: 'startPoint', className: '', isPdf: true, status: false },
    { id: 1, title: 'End Point', serverKey: 'endPoint', className: '', isPdf: true, status: false },
    { id: 1, title: 'Start Time', serverKey: 'startTime', className: '', isPdf: true, status: false },
    { id: 1, title: 'End Time', serverKey: 'endTime', className: '', isPdf: true, status: false },
    { id: 1, title: 'Movement-Distance', serverKey: 'movementDistance', className: '', isPdf: true, status: false },
    { id: 1, title: 'Earthwork-Passes', serverKey: 'earthWorkPasses', className: '', isPdf: true, status: false },
    { id: 1, title: 'Earthwork-Quality', serverKey: 'earthWorkQuality', className: '', isPdf: true, status: false },
    { id: 1, title: 'Subgrade-Passes', serverKey: 'subgradePasses', className: '', isPdf: true, status: false },
    { id: 1, title: 'Subgrade-Quality', serverKey: 'subgradeQuality', className: '', isPdf: true, status: false },
    { id: 1, title: 'WBM Grading2-Passes', serverKey: 'wbmGrading2Passes', className: '', isPdf: true, status: false },
    { id: 1, title: 'WBM Grading2-Quality', serverKey: 'wbmGrading2Quality', className: '', isPdf: true, status: false },
    { id: 1, title: 'WBM Grading3-Passes', serverKey: 'wbmGrading3Passes', className: '', isPdf: true, status: false },
    { id: 1, title: 'WBM Grading3-Quality', serverKey: 'wbmGrading3Quality', className: '', isPdf: true, status: false },
    { id: 1, title: 'GSB Passes', serverKey: 'gsbPasses', className: '', isPdf: true, status: false },
    { id: 1, title: 'GSB Quality', serverKey: 'gsbQuality', className: '', isPdf: true, status: false },
    { id: 1, title: 'Total', serverKey: 'total', className: '', isPdf: true, status: false },
    { id: 1, title: 'Quality', serverKey: 'quality', className: '', isPdf: true, status: false },
    { id: 1, title: 'Vehicle-Number', serverKey: 'vehicleNo', className: '', isPdf: true, status: false },
    { id: 1, title: 'IMEI', serverKey: 'imei', className: '', isPdf: true, status: false },
    { id: 1, title: 'Vehicle-Type', serverKey: 'vehicleType', className: '', isPdf: true, status: false },
    { id: 1, title: 'Device-Name', serverKey: 'deviceName', className: '', isPdf: true, status: false },
    { id: 1, title: 'Device-Installation Date', serverKey: 'deviceInstallData', className: '', isPdf: true, status: false },
    { id: 1, title: 'Device-Uninstallation Date', serverKey: 'deviceUninstallDate', className: '', isPdf: true, status: false },
]