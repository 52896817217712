import { GetPageCount } from "../../../Utils/Utils";

export const CompileZoneData = (data, pagination, type) => {
    return new Promise((resolve, reject) => {
        var td = data
        
        td?.docs?.map((ele, index) => Object.assign(ele, {
            index: GetPageCount(pagination?.limit, pagination?.page, index),
            assignDistrict : GenerateDistrictList(ele?.value),
            Action: type!==null ?<label className="cursor-pointer hover:text-success">
                <button className="btn bg-primary/10 text-primary hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25 ">Show {type}</button>
            </label> : null
        }))
        if (td?.pages) {
            Object.assign(td, {
                totalPages: td?.pages,
                totalDocs: td?.total
            })
        }
        resolve(td)

    })
}

export const CompileDpiuData = (data, zoneData, pagination, type) => {
    return new Promise((resolve, reject) => {
        var td = data
        td?.docs?.map((ele, index) => Object.assign(ele, {
            index: GetPageCount(pagination?.limit, pagination?.page, index),
            zoneName:zoneData?.name,
            zoneId: zoneData?._id,
            assignDistrict : GenerateDistrictList(ele?.value),
            Action: type!==null ?<label className="cursor-pointer hover:text-success">
                <button className="btn bg-primary/10 text-primary hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25 ">Show {type}</button>
            </label> : null
        }))
        if (td?.pages) {
            Object.assign(td, {
                totalPages: td?.pages,
                totalDocs: td?.total
            })
        }
        resolve(td)

    })
}

export const CompilePiuData = (data, zoneData, dpiuData,  pagination, type) => {
    return new Promise((resolve, reject) => {
        var td = data
        td?.docs?.map((ele, index) => Object.assign(ele, {
            index: GetPageCount(pagination?.limit, pagination?.page, index),
            zoneName:zoneData?.name,
            zoneId: zoneData?._id,
            dpiuName:dpiuData?.name,
            dpiuId: dpiuData?._id,
            assignPackages : GeneratePackagesList(ele?.packages),
            Action: type!==null ?<label className="cursor-pointer hover:text-success">
                <button className="btn bg-primary/10 text-primary hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25 ">Show {type}</button>
            </label> : null
        }))
        if (td?.pages) {
            Object.assign(td, {
                totalPages: td?.pages,
                totalDocs: td?.total
            })
        }
        resolve(td)

    })
}

const GeneratePackagesList = (data) =>{
    data?.map((ele,index)=>Object.assign(ele,{value:ele?.Package_no,title:ele?.Package_no,status:true}))
}
const GenerateDistrictList = (data) =>{

    var tp = [{ title: 'All', status: true, value: 'All', label: 'All', serverKey: 'All' }]
    data?.map((ele,index)=>tp.push({value:ele,title:ele,label:ele,status:true}))
    return tp;
}