import { SET_COMPLETE_REPORTS, SET_COMPLETE_JSON_FOR_REPORTS, SET_DAILY_REPORTS, SET_DAILY_JSON_FOR_REPORTS } from "../../Action/Reports/ReportsAction";

const initialState = {
    complete: null,
    completeJson: { page:1, limit:10, search:{ },"lite": true,"select": "", "populate": [ { "path": "roadId", "select": "" },{ "path": "roadId", 'populate':"ommas devices", "select": "" }, { "path": "mapData", "select": "" },{ "path": "imeiData", "select": "" } ],  },
    daily: null,
    dailyJson: { page:1, limit:10, search:{ },"lite": true,"select": "", "populate": [ { "path": "roadId", "select": "" },{ "path": "roadId", 'populate':"ommas devices", "select": "" }, { "path": "mapData", "select": "" }, ],  },  
    timestamp: Date.now()
}

const ReportsReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_COMPLETE_REPORTS:
            return ({ ...state, complete: action.value, timestamp: Date.now() })
        case SET_COMPLETE_JSON_FOR_REPORTS:
            return ({ ...state, completeJson: action.value, timestamp: Date.now() })
        case SET_DAILY_REPORTS:
            return ({ ...state, daily: action.value, timestamp: Date.now() })
        case SET_DAILY_JSON_FOR_REPORTS:
            return ({ ...state, dailyJson: action.value, timestamp: Date.now() })
        default:
            return state;
    }
}

export default ReportsReducer;