import moment from "moment/moment"
import StateList from "../Constant/Common/StateList"

export const GetPageCount = (limit, page, index) => {
  var no = limit * page - limit + index + 1
  return no
}

export const getFormattedDate = (UNIX_timestamp, types, symbol = '-', timeFormat = '24', monthName = false, includeDay = true) => {
  if (!types || !Array.isArray(types) || types.length === 0) {
      return 'Invalid types';
  }

  UNIX_timestamp = parseInt(UNIX_timestamp);
  var a = new Date(UNIX_timestamp);
  var days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
  var day = includeDay ? days[a.getDay()] + ' ' : '';
  var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  var month = monthName ? months[a.getMonth()] : ('0' + (a.getMonth() + 1)).slice(-2);
  var year = a.getFullYear();
  var date = a.getDate() > 9 ? a.getDate() : '0' + a.getDate();
  var hour = timeFormat === '12' ? a.getHours() % 12 || 12 : a.getHours(); // Convert to 12-hour format if specified
  var minute = a.getMinutes() > 9 ? a.getMinutes() : '0' + a.getMinutes();
  var second = a.getSeconds();
  var period = timeFormat === '12' ? (a.getHours() < 12 ? 'am' : 'pm') : ''; // Determine AM or PM only in 12-hour format

  let result = '';
  types.forEach((type, index) => {
      if (type === 'day') {
          result += `${day}`;
      } else if (type === 'date') {
          result += `${date}`;
      } else if (type === 'month') {
          result += `${month}`;
      } else if (type === 'year') {
          result += `${year}`;
      } else if (type === 'hour') {
          result += ` ${hour}`; // Add a space before hour
      } else if (type === 'minute') {
          if (minute !== '00') {
              result += `${minute}`;
          } else {
              result += '00';
          }
      } else if (type === 'second') {
          result += `${second}`;
      } else if (type === 'period' && period) { // Add period only if it exists
          result += ` ${period}`; // Include space before period
      } else {
          // Handle invalid type
          result += 'Invalid type';
      }
      
      // Check if the current type is not 'year' or if the next type is 'period'
      if (type !== 'year' && (index === types.length - 1 || types[index + 1] !== 'period')) {
          result += symbol; // Add the symbol unless it's after the 'year' and before 'period'
      }
  });

  if (result.endsWith(symbol)) {
    result = result.slice(0, -symbol.length); // Remove the last character (which is the separator)
}

  return result;
}



export const GetFullYear = (timestamp) => {
  var t = parseInt(timestamp)
  var a = new Date(t);
  var months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  var sec = a.getSeconds();
  var time = date + '-' + month + '-' + year;
  return time;
}

export const GetMonthName = (timestamp) => {
  var t = parseInt(timestamp)
  var a = new Date(t);
  var months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEPT', 'OCT', 'NOV', 'DEC'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var time = month + year;
  return time;
}
export const GenerateFileName = (screen) => {
  var currentTime = GetFullYear(Date.now())
  var final = currentTime + ' - ' + screen.toUpperCase() + ' Data'
  return final

}

export const GetDistrictConditionData = (data, key, piuKey) => {
  let tempArr = []
  let userType = data?.userType
  let doc = data?.[userType]?.value
  var query = {}
  if (key) {
    if (userType !== 'SuperAdmin') {
      if (userType === 'piu') {
        data?.[userType]?.packages?.map((element) => tempArr.push({ [piuKey]: element?.Package_no }))
        Object.assign(query, { "$or": tempArr })
      } else {
        doc?.map((element) => tempArr.push({ [key]: element }))
        Object.assign(query, { "$or": tempArr })
      }
    }
  } else {
    return doc;
  }

  return query;
}

export const getIMZDateFormat = (value, type) => {

  var today = moment(value).format(`DD-MM-YYYY ${type === 'start' ? "00:00:00" : "23:59:59"}`)

  return today;
}

export const getIMZDateFormat2 = (value, type) => {

  var a = new Date(value);

  var months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate() > 9 ? a.getDate() : '0' + a.getDate();
  var time = date + '-' + month + '-' + year + (type === 'start' ? " 08:00:00" : " 23:59:59");
  return time;
}

export const GetTimestampBasedOnStartEnd = (value, type) => {
  if (value) {

    var today = moment(value).format(`YYYY-MM-DD ${type === 'start' ? "00:00:00" : "23:59:59"}`)
    var fd = new Date(today).getTime() / 1000
    return fd

  } else {
    var today = moment().format(`YYYY-MM-DD ${type === 'start' ? "00:00:00" : "23:59:59"}`)
    var fd = new Date(today).getTime() / 1000
    return fd
  }
}

export const GetSyncName = (data) => {
  var stateName = data?.stateName
  var findOne = StateList.find((ele) => ele.title === stateName)
  return findOne?.syncName || undefined;
}

export const GetMonthAndYear = (value) => {
  var a = new Date(value);
  var months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEPT', 'OCT', 'NOV', 'DEC'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var time = month + ' ' + year;
  return time;
}

export const GetMonth = (value) => {
  var a = new Date(value);
  var months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var time = month;
  return time;
}


export const GenerateMonths = (startDate, endDate) => {
  const months = [];
  let currentDate = moment(startDate);

  while (currentDate.isSameOrBefore(endDate)) {
    const monthName = currentDate.format('MMMYYYY');
    months.push(monthName);

    // Move to the next month
    currentDate.add(1, 'month');
  }

  return months;
}

export const GenerateAllDays = (value) => {
  const today = value ? value : new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1; // Months are zero-based, so we add 1

  const firstDayOfMonth = new Date(year, month - 1, 1);
  const lastDayOfMonth = new Date(year, month, 0); // 0 is the day before the 1st day of the next month

  const daysInMonth = [];

  for (let day = firstDayOfMonth.getDate(); day <= lastDayOfMonth.getDate(); day++) {
    daysInMonth.push(day);
  }

  return daysInMonth;
}

export const GenerateQueryForAnalytics = (Data, json, value) => {

  if (json?.type) {//Single Month
    var StartTime = Data?.find(ele => ele.value === value?.[0])?.label
    Object.assign(json, { StartMonth: StartTime })

    var currentDate = new Date(StartTime)



    var noOfDays = GenerateAllDays(currentDate)
    var StartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1, 0, 0, 0).getTime();



    var EndDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), noOfDays?.length, 23, 59, 59).getTime();
    Object.assign(json?.search, { created_at: { "$gte": StartDate, "$lte": EndDate } })


    delete json?.EndMonth
  } else {
    var StartTime = Data?.find(ele => ele.value === value?.[0])?.label
    var EndTime = Data?.find(ele => ele.value === value?.[1])?.label
    var currentDate = new Date(EndTime)
    var noOfDays = GenerateAllDays(currentDate)
    var EndDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), noOfDays?.length, 23, 59, 59).getTime();
    Object.assign(json, { StartMonth: StartTime, EndMonth: EndTime })
    Object.assign(json?.search, { created_at: { "$gte": new Date(StartTime).setDate('1'), "$lte": EndDate } })
  }


  return true
}

export const timeAgo = (normaldate) => {
  moment.defaultFormat = "DD.MM.YYYY HH:mm:ss";
  var value = moment(normaldate, moment.defaultFormat).toDate()
  
  var since = moment(value).fromNow(true)

  return since;

}


export const LastActiveFunction = (normaldate) => {
  moment.defaultFormat = "YYYY.MM.DD HH:mm:ss";
  var value = moment(normaldate, moment.defaultFormat).toDate()

  var since = moment(value).fromNow(true)

  return since;

}


export const toISOString = (normaldate) => {
  const inputDateString = normaldate;
  const inputFormat = "DD-MM-YYYY HH:mm:ss";
  const parsedDate = moment(inputDateString, inputFormat);

  // Format the date in the ISO 8601 format with UTC offset
  const formattedTimestamp = parsedDate.toISOString();
  return formattedTimestamp;

}

export const sortByTimestamp = (a, b) => {
  return new Date(a.sortBy) - new Date(b.sortBy);
}

export const ConvertDataUsingMoment = (normaldate) => {


  var [day, month, year, hours, minutes, seconds] = normaldate.split(/[- :]/).map(Number);

  var myDate = new Date(year, month - 1, day, hours, minutes, seconds);

  var timestamp = myDate.getTime();

  return timestamp;


}

export const ModifyDate = (startdate, noOfDays, type) => {

  if (type === 'add') {
    var new_date = moment(new Date(startdate), "DD-MM-YYYY").add(noOfDays, 'd').toDate();;
    return new_date
  } else if (type === 'minus') {
    var new_date = moment(new Date(startdate), "DD-MM-YYYY").subtract(noOfDays, 'd').toDate();
    return new_date
  }

}

export const timeDiffDay = (date1, date2) => {
  if (date1 !== '' && date2 !== '') {
    var date1 = new Date(date1)
    var date2 = new Date(date2)
    var difference = date1.getTime() - date2.getTime();

    var daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
    difference -= daysDifference * 1000 * 60 * 60 * 24

    var hoursDifference = Math.floor(difference / 1000 / 60 / 60);
    difference -= hoursDifference * 1000 * 60 * 60

    var minutesDifference = Math.floor(difference / 1000 / 60);
    difference -= minutesDifference * 1000 * 60

    var secondsDifference = Math.floor(difference / 1000);
    return daysDifference;
  } else {
    return 'data error'
  }
}