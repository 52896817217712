import React, { useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FieldTitle from '../../../../Component/Common/Header/FieldTitle';
import { useDispatch, useSelector } from 'react-redux';
import { setAddUserJson, setUserData } from '../../../../Store/Action/SuperAdmin/Users/UserAction';
import Select from '../../../../Component/Select/Select';
import { HitApi } from '../../../../Store/Action/CommonApi/ApiAction';
import { AddUserApi, getUsers } from '../../../../Constant/Api/Constant';
import { CompileUserData } from '../UserPromiss';
import { setPagination } from '../../../../Store/Action/Pagination/Pagination';

let inputClass = 'form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2'
let disabledClass = 'form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-slate-200 px-3 py-2'

export default function AddUser({ setShow }) {
  const dispatch = useDispatch()
  const reduxUser = useSelector(state => state.UserReducer)
  const reduxPagination = useSelector(state => state.PaginationReducer)
  const [loading, setLoading] = useState(false)


  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required('Username is required'),
    contact: Yup.string()
      .required('Phone Number is required')
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, 'Invalid Phone Number'),
    name: Yup.string()
      .required('Name is required'),
    password: Yup.string()
      .required('Password is required'),
    confirmPassword: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password')], 'Passwords does not match'),
    email: Yup.string()
      .required('Email ID is required')
      .email('Invalid Email ID'),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState, setError } = useForm(formOptions);
  const { errors } = formState;


  useEffect(() => {

  }, [reduxUser])

  const handleChange = (e, fieldName) => {
    var json = reduxUser?.addJson
    Object.assign(json, { [fieldName]: e?.target?.value });
    dispatch(setAddUserJson(json))

  }

  const onSubmit = (data) => {
    var json = reduxUser?.addJson
    var finalJson = {
      name: json?.name, //ok
      contact: json?.contact,//ok
      email: json?.email,//ok
      username: json?.username,//ok
      password: json?.password,//ok
      userType: json?.userType,//ok
      department_type: json?.department_type,
      stateName: json?.stateName,//ok
      imz_login: json?.imz_login,
      status: json?.status,
      created_by: json?.created_by,
      districtName: json?.districtName?.filter((o) => o.value !== "All")?.map((ele) => { return ele?.value }),
    }
    if (finalJson?.userType === 'piu') {
      Object.assign(finalJson, { zone: json?.zoneId, dpiu: json?.dpiuId, piu: json?.piuId })
    } else if (finalJson?.userType === 'dpiu') {
      Object.assign(finalJson, { zone: json?.zoneId, dpiu: json?.dpiuId })
    } else if (finalJson?.userType === 'zone') {
      Object.assign(finalJson, { zone: json?.zoneId })
    }

    var isAnyUndefined = Object.values(finalJson).includes(undefined); //Check for undefined

    if (!isAnyUndefined) {

      setLoading(true)

      HitApi(finalJson, AddUserApi).then((res) => {



        if (res?.doc?._id) {
          setLoading(false)
          alert('User Assign Successfully')
          setShow('')

        }else{
          setLoading(false)
        }
      })
    } else {
      alert('check for errors')
    }

  }

  setTimeout(() => {
    setLoading(false)
  }, 100000);


  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='grid grid-cols-4 gap-4 my-3'>
          <div >
            <div><FieldTitle text={'State'} /></div>
            <div><input type='text' value={reduxUser?.addJson?.stateName} {...register('stateName')} className={disabledClass} disabled /></div>
          </div>
          <div >
            <div><FieldTitle text={'ZONE'} /></div>
            <div><input type='text' value={reduxUser?.addJson?.zoneName} placeholder={`Enter Name`} {...register('zoneName')} className={disabledClass} disabled /></div>
            <div><span className="text-sm text-error dark:text-navy-300">{errors['zoneName'] !== undefined ? errors['zoneName'].message : null}</span></div>
          </div>
          <div >
            <div><FieldTitle text={'DPIU'} /></div>
            <div><input type='text' value={reduxUser?.addJson?.dpiuName} placeholder={`Enter Name`} {...register('dpiuName')} className={disabledClass} disabled /></div>
            <div><span className="text-sm text-error dark:text-navy-300">{errors['dpiuName'] !== undefined ? errors['dpiuName'].message : null}</span></div>
          </div>
          {
            reduxUser?.addJson?.userType === 'piu' ? <div>
              <div><FieldTitle text={'PIU'} /></div>
              <div><input type='text' value={reduxUser?.addJson?.piuName} placeholder={`Enter Name`} {...register('piuName')} className={disabledClass} disabled /></div>
              <div><span className="text-sm text-error dark:text-navy-300">{errors['piuName'] !== undefined ? errors['piuName'].message : null}</span></div>
            </div> : null
          }
          <div >
            <div><FieldTitle text={'User Type'} /></div>
            <div><input type='text' value={reduxUser?.addJson?.userType} placeholder={`Enter Name`} {...register('userType')} className={disabledClass} disabled /></div>
            <div><span className="text-sm text-error dark:text-navy-300">{errors['userType'] !== undefined ? errors['userType'].message : null}</span></div>
          </div>
          <div>
            <div><FieldTitle text={'Assign District'} /></div>
            <div>
              {reduxUser?.addJson?.userType === 'piu' ? <input type='text' value={reduxUser?.addJson?.districtName?.[0]} placeholder={`Enter Name`} {...register('districtName')} className={disabledClass} disabled />
                : <Select option={reduxUser?.addJson?.districtName} />}
            </div>
            <div><span className="text-sm text-error dark:text-navy-300">{errors['districtName'] !== undefined ? errors['districtName'].message : null}</span></div>
          </div>
          {
            reduxUser?.addJson?.userType === 'piu' ? <div>
              <div><FieldTitle text={'Assign Packages'} /></div>
              <div>
                <Select option={reduxUser?.addJson?.assignPackages} />
              </div>
            </div> : null
          }
        </div>

        <div className='grid grid-cols-3 gap-4 my-3'>
          <div >
            <div><FieldTitle text={'Name'} /></div>
            <div><input type='text' placeholder={`Enter Name`} {...register('name')} className={inputClass} onChange={(e) => handleChange(e, 'name')} /></div>
            <div><span className="text-sm text-error dark:text-navy-300">{errors['name'] !== undefined ? errors['name'].message : null}</span></div>
          </div>
          <div >
            <div><FieldTitle text={'Email'} /></div>
            <div><input type='text' placeholder={`Enter Name`} {...register('email')} className={inputClass} onChange={(e) => handleChange(e, 'email')} /></div>
            <div><span className="text-sm text-error dark:text-navy-300">{errors['email'] !== undefined ? errors['email'].message : null}</span></div>
          </div>
          <div >
            <div><FieldTitle text={'Contact'} /></div>
            <div><input type='text' placeholder={`Enter Name`} {...register('contact')} className={inputClass} onChange={(e) => handleChange(e, 'contact')} /></div>
            <div><span className="text-sm text-error dark:text-navy-300">{errors['contact'] !== undefined ? errors['contact'].message : null}</span></div>
          </div>
        </div>
        <div className='grid grid-cols-3 gap-4 my-3'>
          <div >
            <div><FieldTitle text={'Username'} /></div>
            <div><input type='text' placeholder={`Enter Name`} {...register('username')} className={inputClass} onChange={(e) => handleChange(e, 'username')} /></div>
            <div><span className="text-sm text-error dark:text-navy-300">{errors['username'] !== undefined ? errors['username'].message : null}</span></div>
          </div>
          <div >
            <div><FieldTitle text={'Password'} /></div>
            <div><input type='text' placeholder={`Enter Name`} {...register('password')} className={inputClass} onChange={(e) => handleChange(e, 'password')} /></div>
            <div><span className="text-sm text-error dark:text-navy-300">{errors['password'] !== undefined ? errors['password'].message : null}</span></div>
          </div>
          <div >
            <div><FieldTitle text={'Confirm Password'} /></div>
            <div><input type='text' placeholder={`Enter Name`} {...register('confirmPassword')} className={inputClass} onChange={(e) => handleChange(e, 'confirmPassword')} /></div>
            <div><span className="text-sm text-error dark:text-navy-300">{errors['confirmPassword'] !== undefined ? errors['confirmPassword'].message : null}</span></div>
          </div>
        </div>

        <div className='grid grid-cols-1 gap-4 my-3'>
          <div className='flex justify-end'>
            {
              loading ? <label className="btn space-x-2 bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90">
                <span>Loading...</span>
              </label> : <button className="btn space-x-2 bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90">
                <span>Add User</span>
              </button>
            }
          </div>
        </div>

      </form>
    </div>
  )
}
