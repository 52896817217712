import React, { useEffect, useState } from 'react'
import CustomTable from '../../../Component/Table/CustomTable'
import { TH } from './TH'
import { useSelector } from 'react-redux'
import CustomTh from '../SuperZone/CustomTH'
import LoadingTableData from '../../../Component/Common/Loading/LoadingTableData'
import RemoveButton from '../../../Component/Common/ActionButton/RemoveButton'
import { RemoveUser } from '../../../Constant/Api/Constant'

export default function Users() {
  const reduxZone = useSelector(state => state.ZoneReducer)
  const reduxUser = useSelector(state => state.UserReducer)
  const [loading, setLoading] = useState(false)

  useEffect(() => {

  }, [])

  let item;

  if (reduxUser?.doc !== null) {
    item = reduxUser?.doc?.docs?.map((element, i) => {
      return <tr key={i} className='cursor-pointer'>
        <td className={`text-center py-4`}>{element?.index}</td>
        <td className={` py-4 text-center`}>{element?.username}</td>
        <td className={` py-4 text-center`}>{element?.name}</td>
        <td className={` py-4 text-center`}>{element?.stateName}</td>
        <td className={` py-4 text-center`}>{element?.email}</td>
        <td className={` py-4 text-center`}>{element?.userType}</td>
        <td className={` py-4 text-center`}>{element?.created_at}</td>
        <td className={` py-4 text-center`}>{element?.status}</td>
        <td className={` py-4 text-center group flex gap-1`}>
          <RemoveButton _id={element?._id} apiUrl={RemoveUser} />
        </td>
      </tr>
    })
  }

  return (
    <div className='my-4'>
      {
        reduxUser?.doc ? <div>
          {/* <h2 class="rizzui-title-h2 font-light mb-2 text-[22px] lg:text-2xl 4xl:text-[26px] text-gray-900 custom-font"> List of  <span className='font-bold text-teal-600'>{reduxZone?.piu?.docs?.[0]?.name} </span> Assigned User </h2> */}
          <h2 class="rizzui-title-h2 font-semibold mb-2 text-[22px] lg:text-2xl 4xl:text-[26px] text-gray-900 custom-font">Assigned User List <span className='font-bold text-teal-600'>{reduxZone?.piu?.docs?.[0]?.name}</span></h2>
          <div className='my-2 card'>
            <table className={`is-hoverable w-full `}>
              <thead><CustomTh TH={TH} /></thead>
              <tbody style={{ maxHeight: 550 }} className=''>
                {loading ? <tr><td colSpan={TH?.length < 12 ? TH?.length : 12}><LoadingTableData /></td></tr> : item}
              </tbody>
            </table>
          </div>
        </div> : null
      }

    </div>
  )
}
