import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { LoginInput } from '../../../Constant/Style/StyleConstant';
import { EnvelopIcon, LockIcon } from '../../../Constant/Icon/Icon';
import Loading from '../../../Component/Common/Loading/Loading';
import MyText from '../../../Component/Common/Text/MyText';
import { HitApi } from '../../../Store/Action/CommonApi/ApiAction';
import { LoginApi, buildVersion } from '../../../Constant/Api/Constant';
import { setUserData } from '../../../Store/Action/AuthAction';
import { SaveUserData, SetBuildVersion } from '../../../Storage/Storage';

export default function Login() {
    const dispatch = useDispatch()
    const reduxUser = useSelector(state => state.AuthReducer)
    const reduxApi = useSelector(state => state.ApiReducer)

    const [loading, setLoading] = useState(false)
    const validationSchema = Yup.object().shape({
        username: Yup.string()
            .required('Username is required'),
        password: Yup.string()
            .required('Password is required')
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState, setError } = useForm(formOptions);
    const { errors } = formState;
    const onSubmit = (data) => {
        setLoading(true)
        HitApi(data, LoginApi).then((res)=>{

            if(res?.status === 200){
                dispatch(setUserData(res?.doc))
                SaveUserData(JSON.stringify(res?.doc))
                SetBuildVersion(buildVersion)
                setLoading(false)
                if(res?.doc?.userType === 'SuperAdmin'){
                    window.location.href = '/sudo/dashboard'
                }else{
                    window.location.href = '/dashboard'
                }
            }
        })
    }

    setTimeout(() => {
        setLoading(false)
    }, 10000);

    return (
        <div id="root" className="flex min-h-100vh grow bg-slate-50 dark:bg-navy-900">
            <div className="fixed top-0 hidden p-6 lg:block lg:px-12">
                <a href="#" className="flex items-center space-x-2">
                    <img className="w-12 h-12" src="https://lineone-html-es6.piniastudio.com/images/app-logo.svg" alt="logo" />
                    <p className="text-xl font-semibold uppercase text-slate-700 dark:text-navy-100"> PMGSY </p>
                </a>
            </div>
            <div className="hidden w-full place-items-center lg:grid">
                <div className="w-full max-w-lg p-6">
                    <img className="w-full" id="hero-image-light" src="https://lineone-html-es6.piniastudio.com/images/illustrations/dashboard-check.svg" alt="image" />
                </div>
            </div>
            <div className="flex flex-col items-center w-full bg-white dark:bg-navy-700 lg:max-w-md">
                <div className="flex flex-col justify-center w-full max-w-sm p-5 grow">
                    <div className="text-center">
                        <img className="w-16 h-16 mx-auto lg:hidden" src="https://lineone-html-es6.piniastudio.com/images/app-logo.svg" alt="logo" />
                        <div className="mt-4">
                            <h2 className="text-2xl font-semibold text-slate-600 dark:text-navy-100"> Welcome </h2>
                            <p className="text-slate-400 dark:text-navy-300"> Sign in to continue </p>
                        </div>
                    </div>
                    <div className="mt-16">
                        <form onSubmit={handleSubmit(onSubmit)} className="login-form">
                            <label className="relative flex">
                                <input className={`${LoginInput} ${errors.username ? 'is-invalid' : ''} `} placeholder="Username" type="text" {...register('username')} />
                                <span className="absolute flex items-center justify-center w-10 h-full pointer-events-none text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent"> {EnvelopIcon} </span>
                            </label>
                            <label className="relative flex mt-4">
                                <input className={` form-input peer w-full rounded-lg bg-slate-150 px-3 py-2 pl-9 ring-primary/50 placeholder:text-slate-400 hover:bg-slate-200 focus:ring dark:bg-navy-900/90 dark:ring-accent/50 dark:placeholder:text-navy-300 dark:hover:bg-navy-900 dark:focus:bg-navy-900 ${errors.password ? 'is-invalid' : ''} `} placeholder="Password" type="password" {...register('password')} />
                                <span className="absolute flex items-center justify-center w-10 h-full pointer-events-none text-slate-400 peer-focus:text-primary dark:text-navy-300 dark:peer-focus:text-accent"> {LockIcon} </span>
                            </label> {loading ?
                                <Loading /> : <button className="w-full h-10 mt-10 font-medium text-white btn bg-primary hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90" type="submit"> Sign In </button>} <div className="mt-2">
                                <MyText text={reduxApi?.error?.login} className={`error`} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
