import moment from "moment"
import { GenerateAllDays, GenerateMonths, GetMonthAndYear } from "../../../../Utils/Utils"


export const CompileSliderData = (json) => {
    const MyPromiss = new Promise((resolve, reject) => {
        let StartDate = new Date('09-01-2022')
        let EndDate = new Date(Date.now())
        var td = []



        if (json?.type === 'Day') {
            var days = GenerateAllDays()
            var part = Math.round(100 / days?.length)
            for (var i = 0; i <= days?.length; i++) {
                td.push({
                    value: part * i,
                    label: days[i],
                    data: [],
                    active: true
                })
            }

            
            
        } else {
            var months = GenerateMonths(StartDate, EndDate)
            var part = Math.round(100 / months?.length)
            for (var i = 0; i <= months?.length; i++) {
                td.push({
                    value: part * i,
                    label: months[i],
                    data: [],
                    active: true
                })
            }
            td.splice(td?.length, 1)
        }


        td.splice(td?.length-1, 1)

        resolve(td)
    })
    return MyPromiss;
}
