import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useSelector } from 'react-redux'
import { Colors } from '../../Constant/Style/StyleConstant'

export default function Efficiencychart() {

    const reduxData = useSelector(state => state.AnalyticsReducer)
    let daily = reduxData?.daily?.docs?.[0]

    const options = {
        chart: {
            type: 'column'
        },
        title: {
            text: 'Vehicle Working hour Chart'
        },
        xAxis: {
            title: {
                text: 'Working Date'
            },
            categories: daily?.chartData?.WorkTimeChartData?.ChartXAxis,
            crosshair: true,
            accessibility: {
                description: 'Countries'
            }
        },
        yAxis: {
            min: 0,
            title: {
                text: 'No. of Hours'
            },
            stackLabels: {
                enabled: true
            }
        },
        series: [{ name: 'Hours', data: daily?.chartData?.WorkTimeChartData?.ChartYAxis?.data }],
        plotOptions: {
            column: {
                dataLabels: {
                    stacking: 'normal',
                    enabled: true,
                }
            }
        },
    }

    return (
        <div>
            <div className='py-7 bg-white border-dotted border'>
                <label className='flex justify-center w-full pb-4'>Chart Legends</label>
                <div className='grid grid-cols-3 gap-4 text-center'>
                    {/* <div><label><span className='px-2 mr-2' style={{ backgroundColor: Colors?.good }}></span>Good</label></div> */}
                </div>
            </div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </div>
    )
}
