export const CommomFields = ["index","state","district","packageId","piuName","contractorName",'roadCode',
'roadName','roadLength','vehType','workName','exeDate'
]

export const FilterOptions = [
  { _id: 0, title: 'Rounds And Total Running (in KM)', serverKey: CommomFields.concat('round', 'distance'), status: true },
  { _id: 1, title: 'Active Vehicles (Per Month)', serverKey: CommomFields.concat('deviceCount'), status: true },
  { _id: 2, title: 'Efficiency in %', serverKey: CommomFields.concat('efficency'), status: true },
  { _id: 3, title: `Work Distribution (in  %)`, serverKey: CommomFields.concat('workName'), status: true },
  { _id: 4, title: `Running vs Stoppage (in  %)`, serverKey: CommomFields.concat('running', 'stopage'), status: true },
]
